export default {
    functionlist: {
        SUMIF: {
            d: "指定された条件に一致する範囲内の値の合計。",
            a: "指定された条件に一致する範囲内の値の合計。",
            p: [
                {
                    name: "範囲",
                    detail: "条件に基づいてチェックする範囲。",
                },
                {
                    name: "条件",
                    detail: "範囲に適用するパターンまたはテスト条件。\n\n範囲がテキストを含む場合、条件は文字列でなければなりません。条件には、単一文字に一致する?や、ゼロ個以上の連続する文字に一致する*などのワイルドカードを含めることができます。文字?や*自体に一致させたい場合は、その文字の前にチルダ(~)を付けてください（つまり~?や~*）。文字列条件は引用符で囲む必要があります。関数は範囲内の各セルが条件と等しいか、ワイルドカードを使用した場合は一致するかどうかをチェックします。\n\n範囲が数値を検査する場合、条件は文字列でも数値でもかまいません。条件が数値の場合、範囲内の各セルが条件と等しいかどうかをチェックします。また、条件が数字を含む文字列の場合も等しいかどうかをチェックします。または、次のプレフィックスを持つ数字です：=（等しいかチェック）、>（範囲セルの値が条件値より大きいかチェック）、<（範囲セルの値が条件値より小さいかチェック）",
                },
                {
                    name: "合計範囲",
                    detail: "合計する範囲（範囲と異なる場合）。",
                },
            ],
        },
        TAN: {
            d: "既知の角度の正接を返します。",
            a: "既知の角度の正接を返します。",
            p: [
                {
                    name: "角度",
                    detail: "正接を求める角度、ラジアン単位。",
                },
            ],
        },
        TANH: {
            d: "指定された実数の双曲線正接を返します。",
            a: "指定された実数の双曲線正接を返します。",
            p: [
                {
                    name: "値",
                    detail: "双曲線正接を計算する実数。",
                },
            ],
        },
        CEILING: {
            d: "数値を上に丸めて、最も近い指定された因数の倍数にします。",
            a: "数値を上に丸めて、最も近い指定された因数の倍数にします。",
            p: [
                {
                    name: "値",
                    detail: "上に丸める数値。",
                },
                {
                    name: "因数",
                    detail: "値をこの数の倍数に丸める。",
                },
            ],
        },
        ATAN: {
            d: "数値の逆正接を返し、ラジアン単位で表します。",
            a: "数値の逆正接を返します",
            p: [
                {
                    name: "値",
                    detail: "逆正接を計算する数値。",
                },
            ],
        },
        ASINH: {
            d: "数値の逆双曲線正弦を返します。",
            a: "数値の逆双曲線正弦を返します。",
            p: [
                {
                    name: "値",
                    detail: "逆双曲線正弦を計算する数値。",
                },
            ],
        },
        ABS: {
            d: "数値の絶対値を返します。",
            a: "数値の絶対値を返します。",
            p: [
                {
                    name: "value",
                    detail: "その絶対値を返す数。",
                },
            ],
        },
        ACOS: {
            d: "数値の逆余弦を返し、ラジアン単位で表します。",
            a: "数値の逆余弦を返します",
            p: [
                {
                    name: "値",
                    detail: "逆余弦を計算する数値。-1から1の間でなければなりません、両端を含みます。",
                },
            ],
        },
        ACOSH: {
            d: "数値の逆双曲線余弦を返します。",
            a: "数値の逆双曲線余弦を返します。",
            p: [
                {
                    name: "値",
                    detail: "逆双曲線余弦を計算する数値。1以上でなければなりません。",
                },
            ],
        },
        MULTINOMIAL: {
            d: "引数の合計の階乗を、各引数の階乗の積で割った後の値を返します。",
            a: "引数の合計の階乗を、各引数の階乗の積で割った後の値を返します。",
            p: [
                {
                    name: "値1",
                    detail: "計算で使用される最初の数値または範囲。",
                },
                {
                    name: "値2",
                    detail: "計算で使用される他の数値または範囲。",
                },
            ],
        },
        ATANH: {
            d: "数値の双曲線逆正接を返します。",
            a: "数値の双曲線逆正接を返します。",
            p: [
                {
                    name: "値",
                    detail: "双曲線逆正接を計算する数値。-1と1の間でなければならず（-1と1を含まない）、",
                },
            ],
        },
        ATAN2: {
            d: "原点 (0,0) から指定された座標点 (`x`,`y`) に向かう線の角度をラジアン単位で返します。",
            a: "原点 (0,0) から指定された座標点 (`x`,`y`) に向かう線の角度をラジアン単位で返します。",
            p: [
                {
                    name: "x",
                    detail: "線の終点のx座標。",
                },
                {
                    name: "y",
                    detail: "線の終点のy座標。",
                },
            ],
        },
        COUNTBLANK: {
            d: "指定された範囲内の空白セルの数を返します。",
            a: "指定された範囲内の空白セルの数を返します。",
            p: [
                {
                    name: "範囲",
                    detail: "空白セルの数をカウントする範囲。",
                },
            ],
        },
        COSH: {
            d: "与えられた実数の双曲線余弦値を返します。",
            a: "与えられた実数の双曲線余弦値を返します。",
            p: [
                {
                    name: "値",
                    detail: "その双曲線余弦値を計算する実数値。"
                },
            ],
        },
        INT: {
            d: "数値を切り捨てて、その数以下の最も近い整数を返します。",
            a: "数値を切り捨てて、その数以下の最も近い整数を返します。",
            p: [
                {
                    name: "値",
                    detail: "最も近い整数に切り捨てる数値。"
                }
            ]
        },
        ISEVEN: {
            d: "提供された数値が偶数かどうかを確認します。",
            a: "提供された数値が偶数かどうかを確認します。",
            p: [
                {
                    name: "値",
                    detail: "偶数かどうかを検証する数値。\n\n値が偶数または偶数を含むセルへの参照の場合、ISEVEN は TRUE を返し、それ以外の場合は FALSE を返します。"
                }
            ]
        },
        ISODD: {
            d: "提供された数値が奇数かどうかを確認します。",
            a: "提供された数値が奇数かどうかを確認します。",
            p: [
                {
                    name: "値",
                    detail: "奇数かどうかを検証する数値。\n\n値が奇数または奇数を含むセルを参照する場合、ISODD は TRUE を返し、それ以外の場合は FALSE を返します。"
                },
            ],
        },
        LCM: {
            d: "1 つまたは複数の整数の最小公倍数を返します。",
            a: "1 つまたは複数の整数の最小公倍数を返します。",
            p: [
                {
                    name: "値1",
                    detail: "最小公倍数の計算におけるその因数をチェックする最初の数値または範囲。"
                },
                {
                    name: "値2",
                    detail: "[オプション] - 最小公倍数の計算におけるその他の因数を考慮する場合。"
                },
            ],
        },
        LN: {
            d: "値を e（ネイピア数）を底とする対数で返します。",
            a: "値を e（ネイピア数）を底とする対数で返します。",
            p: [
                {
                    name: "値",
                    detail: "e を底とする対数の値を計算する値。\n\n値は正でなければなりません。"
                },
            ],
        },
        LOG: {
            d: "指定された底に基づいて数字の対数を返します。",
            a: "指定された底に基づいて数字の対数を返します。",
            p: [
                {
                    name: "値",
                    detail: "対数を計算したい正の実数。"
                },
                {
                    name: "底数",
                    detail: "[オプション] - 対数の底数。"
                }
            ]
        },
        LOG10: {
            d: "数値を底が 10 の対数で返します。",
            a: "数値を底が 10 の対数で返します。",
            p: [
                {
                    name: "値",
                    detail: "10 を底とする対数を計算する数値。\n\n値は正でなければなりません。"
                },
            ],
        },
        MOD: {
            d: "2 つの数の除算の余りを返します。結果の符号は除数と同じです。",
            a: "2 つの数の除算の余りを返します。",
            p: [
                {
                    name: "被除数",
                    detail: "余りを得るために割る数値。"
                },
                {
                    name: "除数",
                    detail: "他の数を除算するための数値。"
                },
            ],
        },
        MROUND: {
            d: "数値を他の整数の最も近い整数倍に丸めます。",
            a: "数値を他の整数の最も近い整数倍に丸めます。",
            p: [
                {
                    name: "値",
                    detail: "他の整数の最も近い整数倍に丸める数値。"
                },
                {
                    name: "因数",
                    detail: "値がこの因数の整数倍になります。"
                },
            ],
        },
        ODD: {
            d: "数値を最も近い奇数に切り上げます。",
            a: "数値を最も近い奇数に切り上げます。",
            p: [
                {
                    name: "値",
                    detail: "切り上げる数値。切り上げられる値はこの値より大きい最も近い奇数です。\n\n値が負の場合、その絶対値よりも大きい隣接する負の奇数に切り上げ"
                },
            ],
        },
        SUMSQ: {
            d: "一組の数値および/またはセルの平方の合計を返します。",
            a: "一組の数値および/またはセルの平方の合計を返します。",
            p: [
                {
                    name: "値1",
                    detail: "その平方を加算する最初の数値または範囲。",
                },
                {
                    name: "値2, ...",
                    detail: "[オプション] - 値1の平方に加算する他の数値または範囲。",
                },
            ],
        },
        COMBIN: {
            d: "与えられたセット内のオブジェクトの総数と選択するオブジェクトの数を指定すると、異なる選択方法がいくつあるかを返します。",
            a: "与えられたセット内のオブジェクトの総数と選択するオブジェクトの数",
            p: [
                {
                    name: "n",
                    detail: "選択されるオブジェクトの集合のサイズ。",
                },
                {
                    name: "k",
                    detail: "選択するオブジェクトの数。",
                },
            ],
        },
        SUM: {
            d: "一組の数値および/またはセルの合計を返します。",
            a: "一組の数値および/またはセルの合計を返します。",
            p: [
                {
                    name: "値1",
                    detail: "加算する最初の数値または範囲。",
                },
                {
                    name: "値2",
                    detail: "[オプション] - 他の数値または範囲を加算します。",
                },
            ],
        },
        SUBTOTAL: {
            d: "指定された集計関数を使用して、縦方向のセル範囲の分類合計を返します。",
            a: "指定された集計関数を使用して",
            p: [
                {
                    name: "関数コード",
                    detail:
                        "分類合計を計算するための関数。\n\n1は平均を表します。\n\n2はCOUNTを表します。\n\n3はCOUNTAを表します。\n\n4はMAXを表します。\n\n5はMINを表します。\n\n6はPRODUCTを表します。\n\n7はSTDEVを表します。\n\n8はSTDEVPを表します。\n\n9はSUMを表します。\n\n10はVARを表します。\n\n11はVARPを表します。\n\nこれらの2桁のコードの前に、非表示の値を無視するために10（1桁のコードの場合）または1（2桁のコードの場合）を付けることができます。例えば、102は非表示のセルを無視したCOUNT、110は非表示の値を無視したVARを示します。",
                },
                {
                    name: "範囲1",
                    detail: "分類合計を計算する最初の範囲。",
                },
                {
                    name: "範囲2",
                    detail: "[オプション] - 他の範囲の分類合計を計算します。",
                },
            ],
        },
        ASIN: {
            d: "数値の逆正弦値（ラジアンで表される）を返します。",
            a: "数値の逆正弦値",
            p: [
                {
                    name: "値",
                    detail: "逆正弦値を計算する数値。-1から1の間である必要があります（両端値を含む）。",
                },
            ],
        },
        COUNTIF: {
            d: "特定の条件を満たす範囲内のセルの数を返します。",
            a: "特定の条件を満たす範囲内のセルの数を返します。",
            p: [
                {
                    name: "範囲",
                    detail: "条件をチェックする範囲。",
                },
                {
                    name: "条件",
                    detail:
                        "範囲に適用されるパターンまたはテスト条件。\n\n範囲がテキストを含む場合、条件は文字列である必要があります。条件にはワイルドカードを含めることができます。?は1文字に一致し、*は0文字または連続した複数の文字に一致します。?または*自体に一致する場合は、その前にチルダ（〜）プレフィックスを付けます（つまり、〜?と〜*）。文字列条件は引用符で囲む必要があります。関数は、範囲内の各セルが条件と等しいかどうか（ワイルドカードを使用した場合は一致するか）をチェックします。\n\n範囲が数字を含む場合、条件は文字列でも数値でもかまいません。指定された条件が数字である場合、関数は範囲内の各セルが条件と等しいかどうかをチェックします。また、条件は数字を含む文字列（等しいチェックされます）または次のプレフィックスを付けた数字です：=、>、>=、<、または<=、これらの条件は、それぞれ範囲内のセルが条件値と等しい、より大きい、以上、未満、または以下であるかをチェックします。",
                },
            ],
        },
        RADIANS: {
            d: "度で表される角度をラジアンに変換します。",
            a: "度で表される角度をラジアンに変換します。",
            p: [
                {
                    name: "角度",
                    detail: "ラジアンに変換する度数法の角度。",
                },
            ],
        },
        RAND: {
            d: "Returns a random number between 0 inclusive and 1 exclusive.",
            a: "A random number between 0 inclusive and 1 exclusive.",
            p: [],
        },
        COUNTUNIQUE: {
            d: "Counts the number of unique values in a list of specified values and ranges.",
            a: "Counts number of unique values in a range.",
            p: [
                {
                    name: "value1",
                    detail: "The first value or range to consider for uniqueness.",
                },
                {
                    name: "value2",
                    detail: "Additional values or ranges to consider for uniqueness.",
                },
            ],
        },
        DEGREES: {
            d: "Converts an angle value in radians to degrees.",
            a: "Converts an angle value in radians to degrees.",
            p: [
                {
                    name: "angle",
                    detail: "The angle to convert from radians to degrees.",
                },
            ],
        },
        ERFC: {
            d: "Returns the complementary Gauss error function of a value.",
            a: "Complementary gauss error function of a value.",
            p: [
                {
                    name: "z",
                    detail: "The number for which to calculate the complementary Gauss error function.",
                },
            ],
        },
        EVEN: {
            d: "Rounds a number up to the nearest even integer.",
            a: "Rounds a number up to the nearest even integer.",
            p: [
                {
                    name: "value",
                    detail: "The value to round to the next greatest even number.",
                },
            ],
        },
        EXP: {
            d: "Returns Euler's number, e (~2.718) raised to a power.",
            a: "Euler's number, e (~2.718) raised to a power.",
            p: [
                {
                    name: "exponent",
                    detail: "The exponent to raise e to.",
                },
            ],
        },
        FACT: {
            d: "Returns the factorial of a number.",
            a: "Factorial of a number.",
            p: [
                {
                    name: "value",
                    detail: "The number or reference to a number whose factorial will be calculated and returned.",
                },
            ],
        },
        FACTDOUBLE: {
            d: 'Returns the "double factorial" of a number.',
            a: '"double factorial" of a number.',
            p: [
                {
                    name: "value",
                    detail:
                        "The number or reference to a number whose double factorial will be calculated and returned.",
                },
            ],
        },
        PI: {
            d: "Returns the value of Pi to 14 decimal places.",
            a: "The number pi.",
            p: [],
        },
        FLOOR: {
            d: "Rounds a number down to the nearest integer multiple of specified significance `factor`.",
            a: "Rounds number down to nearest multiple of a factor.",
            p: [
                {
                    name: "value",
                    detail: "The value to round down to the nearest integer multiple of `factor`.",
                },
                {
                    name: "factor",
                    detail: "The number to whose multiples `value` will be rounded.",
                },
            ],
        },
        GCD: {
            d: "Returns the greatest common divisor of one or more integers.",
            a: "Greatest common divisor of one or more integers.",
            p: [
                {
                    name: "value1",
                    detail:
                        "The first value or range whose factors to consider in a calculation to find the greatest common divisor.",
                },
                {
                    name: "value2",
                    detail:
                        "Additional values or ranges whose factors to consider to find the greatest common divisor.",
                },
            ],
        },
        RANDBETWEEN: {
            d: "Returns a uniformly random integer between two values, inclusive.",
            a: "Random integer between two values, inclusive.",
            p: [
                {
                    name: "low",
                    detail: "The low end of the random range.",
                },
                {
                    name: "high",
                    detail: "The high end of the random range.",
                },
            ],
        },
        ROUND: {
            d: "Rounds a number to a certain number of decimal places according to standard rules.",
            a: "Rounds a number according to standard rules.",
            p: [
                {
                    name: "value",
                    detail: "The value to round to `places` number of places.",
                },
                {
                    name: "places",
                    detail: "The number of decimal places to which to round.",
                },
            ],
        },
        ROUNDDOWN: {
            d:
                "Rounds a number to a certain number of decimal places, always rounding down to the next valid increment.",
            a: "Rounds down a number.",
            p: [
                {
                    name: "value",
                    detail: "The value to round to `places` number of places, always rounding down.",
                },
                {
                    name: "places",
                    detail: "The number of decimal places to which to round.",
                },
            ],
        },
        ROUNDUP: {
            d: "Rounds a number to a certain number of decimal places, always rounding up to the next valid increment.",
            a: "Rounds up a number.",
            p: [
                {
                    name: "value",
                    detail: "The value to round to `places` number of places, always rounding up.",
                },
                {
                    name: "places",
                    detail: "The number of decimal places to which to round.",
                },
            ],
        },
        SERIESSUM: {
            d: "Given parameters `x`, `n`, `m`, and `a`, returns the power series sum a",
            a: "Sum of a power series.",
            p: [
                {
                    name: "x",
                    detail:
                        "The input to the power series. Varies depending on the type of approximation, may be angle, exponent, or some other value.",
                },
                {
                    name: "n",
                    detail: "The initial power to which to raise `x` in the power series.",
                },
                {
                    name: "m",
                    detail: "The additive increment by which to increase `x`.",
                },
                {
                    name: "a",
                    detail: "The array or range containing the coefficients of the power series.",
                },
            ],
        },
        SIGN: {
            d: "Given an input number, returns `-1` if it is negative, `1` if positive, and `0` if it is zero.",
            a: "Sign of a provided number (+/-/0).",
            p: [
                {
                    name: "value",
                    detail: "The value whose sign will be evaluated.",
                },
            ],
        },
        SIN: {
            d: "Returns the sine of an angle provided in radians.",
            a: "Sine of an angle provided in radians.",
            p: [
                {
                    name: "angle",
                    detail: "The angle to find the sine of, in radians.",
                },
            ],
        },
        SINH: {
            d: "Returns the hyperbolic sine of any real number.",
            a: "Hyperbolic sine of any real number.",
            p: [
                {
                    name: "value",
                    detail: "Any real value to calculate the hyperbolic sine of.",
                },
            ],
        },
        SQRT: {
            d: "Returns the positive square root of a positive number.",
            a: "Positive square root of a positive number.",
            p: [
                {
                    name: "value",
                    detail: "The number for which to calculate the positive square root.",
                },
            ],
        },
        SQRTPI: {
            d: "Returns the positive square root of the product of Pi and the given positive number.",
            a: "Square root of the product of pi and number.",
            p: [
                {
                    name: "value",
                    detail: "The number which will be multiplied by Pi and have the product's square root returned",
                },
            ],
        },
        GAMMALN: {
            d: "Returns the logarithm of a specified Gamma function, base e (Euler's number).",
            a: "Logarithm of gamma function.",
            p: [
                {
                    name: "value",
                    detail:
                        "The input to the Gamma function. The natural logarithm of Gamma(`value`) will be returned.",
                },
            ],
        },
        COS: {
            d: "Returns the cosine of an angle provided in radians.",
            a: "Cosine of an angle provided in radians.",
            p: [
                {
                    name: "angle",
                    detail: "The angle to find the cosine of, in radians.",
                },
            ],
        },
        TRUNC: {
            d: "Truncates a number to a certain number of significant digits by omitting less significant digits.",
            a: "Truncates a number.",
            p: [
                {
                    name: "value",
                    detail: "The value to be truncated.",
                },
                {
                    name: "places",
                    detail: "The number of significant digits to the right of the decimal point to retain.",
                },
            ],
        },
        QUOTIENT: {
            d: "Returns one number divided by another.",
            a: "One number divided by another.",
            p: [
                {
                    name: "dividend",
                    detail: "The number to be divided.",
                },
                {
                    name: "divisor",
                    detail: "The number to divide by.",
                },
            ],
        },
        POWER: {
            d: "Returns a number raised to a power.",
            a: "A number raised to a power.",
            p: [
                {
                    name: "base",
                    detail: "The number to raise to the `exponent` power.",
                },
                {
                    name: "exponent",
                    detail: "The exponent to raise `base` to.",
                },
            ],
        },
        SUMIFS: {
            d: "Returns the sum of a range depending on multiple criteria.",
            a: "Sums a range depending on multiple criteria.",
            p: [
                {
                    name: "sum_range",
                    detail: "The range to sum.",
                },
                {
                    name: "criteria_range1",
                    detail: "The range to check against criterion1.",
                },
                {
                    name: "criterion1",
                    detail: "The pattern or test to apply to criteria_range1.",
                },
                {
                    name: "criteria_range2",
                    detail: "Additional ranges to check.",
                },
            ],
        },
        GET_TARGET: {
            d: "Query target data.",
            a: "Query target data.",
            p: [],
        },
        GET_AIRTABLE_DATA: {
            d: "Query AirTable data.",
            a: "Query AirTable data.",
            p: [
                {
                    name: "AirTable url",
                    detail: "AirTable url address.",
                },
                {
                    name: "sort_column",
                    detail: "The index of the column in current AirTable view containing the values by which to sort.",
                },
                {
                    name: "is_ascending",
                    detail:
                        "[Optional] - 1 or 0 indicating whether to sort `sort_column` in ascending order. 0 sorts in descending order.1 sorts in ascending order.",
                },
            ],
        },
        ASK_AI: {
            d: "Ask the AI questions.",
            a: "Ask the AI questions.",
            p: [
                {
                    name: "question",
                    detail: "Consult AI for the data you want.",
                },
                {
                    name: "criteria_range",
                    detail: "In which range to process the data.",
                },
            ],
        },
        COUNTIFS: {
            d: "Returns the count of a range depending on multiple criteria.",
            a: "Count values depending on multiple criteria.",
            p: [
                {
                    name: "criteria_range1",
                    detail: "The range to check against `criterion1`.",
                },
                {
                    name: "criterion1",
                    detail: "The pattern or test to apply to `criteria_range1`.",
                },
                {
                    name: "criteria_range2",
                    detail: "Additional ranges to check.",
                },
            ],
        },
        PRODUCT: {
            d: "Returns the result of multiplying a series of numbers together.",
            a: "Result of multiplying a series of numbers together.",
            p: [
                {
                    name: "factor1",
                    detail: "The first number or range to calculate for the product.",
                },
                {
                    name: "factor2",
                    detail: "More numbers or ranges to calculate for the product.",
                },
            ],
        },
        HARMEAN: {
            d: "Calculates the harmonic mean of a dataset.",
            a: "The harmonic mean of a dataset.",
            p: [
                {
                    name: "value1",
                    detail: "The first value or range of the population.",
                },
                {
                    name: "value2",
                    detail: "Additional values or ranges to include in the population.",
                },
            ],
        },
        HYPGEOMDIST: {
            d:
                "Calculates the probability of drawing a certain number of successes in a certain number of tries given a population of a certain size containing a certain number of successes, without replacement of draws.",
            a: "Hypergeometric distribution probability.",
            p: [
                {
                    name: "num_successes",
                    detail: "The desired number of successes.",
                },
                {
                    name: "num_draws",
                    detail: "The number of permitted draws.",
                },
                {
                    name: "successes_in_pop",
                    detail: "The total number of successes in the population.",
                },
                {
                    name: "pop_size",
                    detail: "The total size of the population",
                },
                {
                    name: "cumulative",
                    detail:
                        "Determine the logical value of the function form. \n\nIf cumulative is TRUE(), HYPGEOM.DIST returns the cumulative distribution function;\n\nif FALSE(), it returns the probability density function.",
                },
            ],
        },
        INTERCEPT: {
            d:
                "Calculates the y-value at which the line resulting from linear regression of a dataset will intersect the y-axis (x=0).",
            a: "Y-intercept of line derived via linear regression.",
            p: [
                {
                    name: "data_y",
                    detail: "The range representing the array or matrix of dependent data.",
                },
                {
                    name: "data_x",
                    detail: "The range representing the array or matrix of independent data.",
                },
            ],
        },
        KURT: {
            d:
                'Calculates the kurtosis of a dataset, which describes the shape, and in particular the "peakedness" of that dataset.',
            a: "Kurtosis of a dataset.",
            p: [
                {
                    name: "value1",
                    detail: "The first value or range of the dataset.",
                },
                {
                    name: "value2",
                    detail: "Additional values or ranges to include in the dataset.",
                },
            ],
        },
        LARGE: {
            d: "Returns the nth largest element from a data set, where n is user-defined.",
            a: "Nth largest element from a data set.",
            p: [
                {
                    name: "data",
                    detail: "Array or range containing the dataset to consider.",
                },
                {
                    name: "n",
                    detail: "The rank from largest to smallest of the element to return.",
                },
            ],
        },
        STDEVA: {
            d: "Calculates the standard deviation based on a sample, setting text to the value `0`.",
            a: "Standard deviation of sample (text as 0).",
            p: [
                {
                    name: "value1",
                    detail: "The first value or range of the sample.",
                },
                {
                    name: "value2",
                    detail: "Additional values or ranges to include in the sample.",
                },
            ],
        },
        STDEVP: {
            d: "Calculates the standard deviation based on an entire population.",
            a: "Standard deviation of an entire population.",
            p: [
                {
                    name: "value1",
                    detail: "The first value or range of the population.",
                },
                {
                    name: "value2",
                    detail: "Additional values or ranges to include in the population.",
                },
            ],
        },
        GEOMEAN: {
            d: "Calculates the geometric mean of a dataset.",
            a: "The geometric mean of a dataset.",
            p: [
                {
                    name: "value1",
                    detail: "The first value or range of the population.",
                },
                {
                    name: "value2",
                    detail: "Additional values or ranges to include in the population.",
                },
            ],
        },
        RANK_EQ: {
            d:
                "Returns the rank of a specified value in a dataset. If there is more than one entry of the same value in the dataset, the top rank of the entries will be returned.",
            a: "Top rank of a specified value in a dataset.",
            p: [
                {
                    name: "value",
                    detail: "The value whose rank will be determined.",
                },
                {
                    name: "data",
                    detail: "The array or range containing the dataset to consider.",
                },
                {
                    name: "is_ascending",
                    detail:
                        "Whether to consider the values in `data` in descending or ascending order. If omitted, the default is descending (FALSE).",
                },
            ],
        },
        RANK_AVG: {
            d:
                "Returns the rank of a specified value in a dataset. If there is more than one entry of the same value in the dataset, the average rank of the entries will be returned.",
            a: "Average rank of a specified value in a dataset.",
            p: [
                {
                    name: "value",
                    detail: "The value whose rank will be determined.",
                },
                {
                    name: "data",
                    detail: "The array or range containing the dataset to consider.",
                },
                {
                    name: "is_ascending",
                    detail:
                        "Whether to consider the values in `data` in descending or ascending order. If omitted, the default is descending (FALSE).",
                },
            ],
        },
        PERCENTRANK_EXC: {
            d: "Returns the percentage rank (percentile) from 0 to 1 exclusive of a specified value in a dataset.",
            a: "Percentage rank (percentile) from 0 to 1 exclusive.",
            p: [
                {
                    name: "data",
                    detail: "The array or range containing the dataset to consider.",
                },
                {
                    name: "value",
                    detail: "The value whose percentage rank will be determined.",
                },
                {
                    name: "significant_digits",
                    detail: "The number of significant figures to use in the calculation. Default is 3.",
                },
            ],
        },
        PERCENTRANK_INC: {
            d: "Returns the percentage rank (percentile) from 0 to 1 inclusive of a specified value in a dataset.",
            a: "Percentage rank (percentile) from 0 to 1 inclusive.",
            p: [
                {
                    name: "data",
                    detail: "The array or range containing the dataset to consider.",
                },
                {
                    name: "value",
                    detail: "The value whose percentage rank will be determined.",
                },
                {
                    name: "significant_digits",
                    detail: "The number of significant figures to use in the calculation. Default is 3.",
                },
            ],
        },
        FORECAST: {
            d: "Calculates the expected y-value for a specified x based on a linear regression of a dataset.",
            a: "Expected y-value based of linear regression.",
            p: [
                {
                    name: "x",
                    detail: "The value on the x-axis to forecast.",
                },
                {
                    name: "data_y",
                    detail: "The range representing the array or matrix of dependent data.",
                },
                {
                    name: "data_x",
                    detail: "The range representing the array or matrix of independent data.",
                },
            ],
        },
        FISHERINV: {
            d: "Returns the inverse Fisher transformation of a specified value.",
            a: "Inverse fisher transformation of a specified value.",
            p: [
                {
                    name: "value",
                    detail: "The value for which to calculate the inverse Fisher transformation.",
                },
            ],
        },
        FISHER: {
            d: "Returns the Fisher transformation of a specified value.",
            a: "Fisher transformation of a specified value.",
            p: [
                {
                    name: "value",
                    detail: "The value for which to calculate the Fisher transformation.",
                },
            ],
        },
        MODE_SNGL: {
            d: "Returns the most commonly occurring value in a dataset.",
            a: "Most commonly occurring value in a dataset.",
            p: [
                {
                    name: "value1",
                    detail: "The first value or range to consider when calculating mode.",
                },
                {
                    name: "value2",
                    detail: "Additional values or ranges to consider when calculating mode.",
                },
            ],
        },
        WEIBULL_DIST: {
            d:
                "Returns the value of the Weibull distribution function (or Weibull cumulative distribution function) for a specified shape and scale.",
            a: "Weibull distribution function.",
            p: [
                {
                    name: "x",
                    detail: "The input to the Weibull distribution function.",
                },
                {
                    name: "shape",
                    detail: "The shape parameter of the Weibull distribution function.",
                },
                {
                    name: "scale",
                    detail: "The scale parameter of the Weibull distribution function.",
                },
                {
                    name: "cumulative",
                    detail: "Whether to use the cumulative distribution function.",
                },
            ],
        },
        COUNT: {
            d: "Returns the number of numeric values in a dataset.",
            a: "The number of numeric values in dataset.",
            p: [
                {
                    name: "value1",
                    detail: "The first value or range to consider when counting.",
                },
                {
                    name: "value2",
                    detail: "Additional values or ranges to consider when counting.",
                },
            ],
        },
        COUNTA: {
            d: "Returns the number of values in a dataset.",
            a: "The number of values in a dataset.",
            p: [
                {
                    name: "value1",
                    detail: "The first value or range to consider when counting.",
                },
                {
                    name: "value2",
                    detail: "Additional values or ranges to consider when counting.",
                },
            ],
        },
        AVEDEV: {
            d: "Calculates the average of the magnitudes of deviations of data from a dataset's mean.",
            a: "Average magnitude of deviations from mean.",
            p: [
                {
                    name: "value1",
                    detail: "The first value or range of the sample.",
                },
                {
                    name: "value2",
                    detail: "Additional values or ranges to include in the sample.",
                },
            ],
        },
        AVERAGE: {
            d: "Returns the numerical average value in a dataset, ignoring text.",
            a: "Numerical average value in a dataset, ignoring text.",
            p: [
                {
                    name: "value1",
                    detail: "The first value or range to consider when calculating the average value.",
                },
                {
                    name: "value2",
                    detail: "Additional values or ranges to consider when calculating the average value.",
                },
            ],
        },
        AVERAGEA: {
            d: "Returns the numerical average value in a dataset.",
            a: "Numerical average value in a dataset.",
            p: [
                {
                    name: "value1",
                    detail: "The first value or range to consider when calculating the average value.",
                },
                {
                    name: "value2",
                    detail: "Additional values or ranges to consider when calculating the average value.",
                },
            ],
        },
        BINOM_DIST: {
            d:
                "Calculates the probability of drawing a certain number of successes (or a maximum number of successes) in a certain number of tries given a population of a certain size containing a certain number of successes, with replacement of draws.",
            a: "Binomial distribution probability.",
            p: [
                {
                    name: "num_successes",
                    detail: "The number of successes for which to calculate the probability in `num_trials` trials.",
                },
                {
                    name: "num_trials",
                    detail: "The number of independent trials.",
                },
                {
                    name: "prob_success",
                    detail: "The probability of success in any given trial.",
                },
                {
                    name: "cumulative",
                    detail: "Whether to use the binomial cumulative distribution.",
                },
            ],
        },
        BINOM_INV: {
            d:
                "Calculates the smallest value for which the cumulative binomial distribution is greater than or equal to a specified criteria.",
            a: "Inverse cumulative binomial distribution function.",
            p: [
                {
                    name: "num_trials",
                    detail: "The number of independent trials.",
                },
                {
                    name: "prob_success",
                    detail: "The probability of success in any given trial.",
                },
                {
                    name: "target_prob",
                    detail: "The desired threshold probability.",
                },
            ],
        },
        CONFIDENCE_NORM: {
            d: "Calculates the width of half the confidence interval for a normal distribution.",
            a: "Confidence interval for a normal distribution.",
            p: [
                {
                    name: "alpha",
                    detail: "One minus the desired confidence level. E.g. `0.1` for `0.9`, or 90%, confidence.",
                },
                {
                    name: "standard_deviation",
                    detail: "The standard deviation of the population.",
                },
                {
                    name: "pop_size",
                    detail: "The size of the population.",
                },
            ],
        },
        CORREL: {
            d: "Calculates r, the Pearson product-moment correlation coefficient of a dataset.",
            a: "Pearson Product-Moment Correlation Coefficient.",
            p: [
                {
                    name: "data_y",
                    detail: "The range representing the array or matrix of dependent data.",
                },
                {
                    name: "data_x",
                    detail: "The range representing the array or matrix of independent data.",
                },
            ],
        },
        COVARIANCE_P: {
            d: "Calculates the covariance of a dataset.",
            a: "The covariance of a dataset.",
            p: [
                {
                    name: "data_y",
                    detail: "The range representing the array or matrix of dependent data.",
                },
                {
                    name: "data_x",
                    detail: "The range representing the array or matrix of independent data.",
                },
            ],
        },
        COVARIANCE_S: {
            d: "Calculates the sample covariance of a dataset.",
            a: "The sample covariance of a dataset.",
            p: [
                {
                    name: "data_y",
                    detail: "The range representing the array or matrix of dependent data.",
                },
                {
                    name: "data_x",
                    detail: "The range representing the array or matrix of independent data.",
                },
            ],
        },
        DEVSQ: {
            d: "Calculates the sum of squares of deviations based on a sample.",
            a: "The sum of squares of deviations based on a sample.",
            p: [
                {
                    name: "value1",
                    detail: "The first value or range of the sample.",
                },
                {
                    name: "value2",
                    detail: "Additional values or ranges to include in the sample.",
                },
            ],
        },
        EXPON_DIST: {
            d:
                "Returns the value of the exponential distribution function with a specified lambda at a specified value.",
            a: "Exponential distribution function.",
            p: [
                {
                    name: "x",
                    detail: "The input to the exponential distribution function.",
                },
                {
                    name: "lambda",
                    detail: "The lambda to specify the exponential distribution function.",
                },
                {
                    name: "cumulative",
                    detail: "Whether to use the exponential cumulative distribution.",
                },
            ],
        },
        AVERAGEIF: {
            d: "Returns the average of a range depending on criteria.",
            a: "Average of values depending on criteria.",
            p: [
                {
                    name: "criteria_range",
                    detail: "The range to check against `criterion`.",
                },
                {
                    name: "criterion",
                    detail: "The pattern or test to apply to `criteria_range`.",
                },
                {
                    name: "average_range",
                    detail: "The range to average. If not included, `criteria_range` is used for the average instead.",
                },
            ],
        },
        AVERAGEIFS: {
            d: "Returns the average of a range depending on multiple criteria.",
            a: "Average of values depending on multiple criteria.",
            p: [
                {
                    name: "average_range",
                    detail: "The range to average.",
                },
                {
                    name: "criteria_range1",
                    detail: "The range to check against `criterion1`.",
                },
                {
                    name: "criterion1",
                    detail: "The pattern or test to apply to `criteria_range1`.",
                },
                {
                    name: "criteria_range2",
                    detail: "Additional ranges to check.",
                },
            ],
        },
        PERMUT: {
            d:
                "Returns the number of ways to choose some number of objects from a pool of a given size of objects, considering order.",
            a: "Number of permutations from a number of objects.",
            p: [
                {
                    name: "n",
                    detail: "The size of the pool of objects to choose from.",
                },
                {
                    name: "k",
                    detail: "The number of objects to choose.",
                },
            ],
        },
        TRIMMEAN: {
            d:
                "Calculates the mean of a dataset excluding some proportion of data from the high and low ends of the dataset.",
            a: "Mean of a dataset excluding high/low ends.",
            p: [
                {
                    name: "data",
                    detail: "Array or range containing the dataset to consider.",
                },
                {
                    name: "exclude_proportion",
                    detail: "The proportion of the dataset to exclude, from the extremities of the set.",
                },
            ],
        },
        PERCENTILE_EXC: {
            d: "Returns the value at a given percentile of a dataset exclusive of 0 and 1.",
            a: "Value at a given percentile of a dataset exclusive of 0 and 1.",
            p: [
                {
                    name: "data",
                    detail: "The array or range containing the dataset to consider.",
                },
                {
                    name: "percentile",
                    detail:
                        "The percentile, exclusive of 0 and 1, whose value within 'data' will be calculated and returned.",
                },
            ],
        },
        PERCENTILE_INC: {
            d: "Returns the value at a given percentile of a dataset.",
            a: "Value at a given percentile of a dataset.",
            p: [
                {
                    name: "data",
                    detail: "The array or range containing the dataset to consider.",
                },
                {
                    name: "percentile",
                    detail: "The percentile whose value within `data` will be calculated and returned.`",
                },
            ],
        },
        PEARSON: {
            d: "Calculates r, the Pearson product-moment correlation coefficient of a dataset.",
            a: "Pearson Product-Moment Correlation Coefficient.",
            p: [
                {
                    name: "data_y",
                    detail: "The range representing the array or matrix of dependent data.",
                },
                {
                    name: "data_x",
                    detail: "The range representing the array or matrix of independent data.",
                },
            ],
        },
        NORM_S_INV: {
            d: "Returns the value of the inverse standard normal distribution function for a specified value.",
            a: "Inverse standard normal distribution function.",
            p: [
                {
                    name: "x",
                    detail: "The input to the inverse standard normal distribution function.",
                },
            ],
        },
        NORM_S_DIST: {
            d: "Returns the value of the standard normal cumulative distribution function for a specified value.",
            a: "Standard normal cumulative distribution function.",
            p: [
                {
                    name: "x",
                    detail: "The input to the standard normal cumulative distribution function.",
                },
                {
                    name: "cumulative",
                    detail:
                        "Determine the logical value of the function form. \n\nIf TRUE(), it returns the cumulative distribution function;\n\nIf it is FALSE(), it returns the probability density function.",
                },
            ],
        },
        NORM_INV: {
            d:
                "Returns the value of the inverse normal distribution function for a specified value, mean, and standard deviation.",
            a: "Inverse normal distribution function.",
            p: [
                {
                    name: "x",
                    detail: "The input to the inverse normal distribution function.",
                },
                {
                    name: "mean",
                    detail: "The mean (mu) of the normal distribution function.",
                },
                {
                    name: "standard_deviation",
                    detail: "The standard deviation (sigma) of the normal distribution function.",
                },
            ],
        },
        NORM_DIST: {
            d:
                "Returns the value of the normal distribution function (or normal cumulative distribution function) for a specified value, mean, and standard deviation.",
            a: "Normal distribution function.",
            p: [
                {
                    name: "x",
                    detail: "The input to the normal distribution function.",
                },
                {
                    name: "mean",
                    detail: "The mean (mu) of the normal distribution function.",
                },
                {
                    name: "standard_deviation",
                    detail: "The standard deviation (sigma) of the normal distribution function.",
                },
                {
                    name: "cumulative",
                    detail:
                        "Whether to use the normal cumulative distribution function rather than the distribution function.",
                },
            ],
        },
        NEGBINOM_DIST: {
            d:
                "Calculates the probability of drawing a certain number of failures before a certain number of successes given a probability of success in independent trials.",
            a: "Negative binomial distribution probability.",
            p: [
                {
                    name: "num_failures",
                    detail: "The number of failures to model.",
                },
                {
                    name: "num_successes",
                    detail: "The number of successes to model.",
                },
                {
                    name: "prob_success",
                    detail: "The probability of success in any given trial.",
                },
                {
                    name: "cumulative",
                    detail:
                        "Determine the logical value of the function form. \n\nIf TRUE(), it returns the cumulative distribution function;\n\nIf it is FALSE(), it returns the probability density function.",
                },
            ],
        },
        MINA: {
            d: "Returns the minimum numeric value in a dataset.",
            a: "Minimum numeric value in a dataset.",
            p: [
                {
                    name: "value1",
                    detail: "The first value or range to consider when calculating the minimum value.",
                },
                {
                    name: "value2",
                    detail: "Additional values or ranges to consider when calculating the minimum value.",
                },
            ],
        },
        MIN: {
            d: "Returns the minimum value in a numeric dataset.",
            a: "Minimum value in a numeric dataset.",
            p: [
                {
                    name: "value1",
                    detail: "The first value or range to consider when calculating the minimum value.",
                },
                {
                    name: "value2",
                    detail: "Additional values or ranges to consider when calculating the minimum value.",
                },
            ],
        },
        MEDIAN: {
            d: "Returns the median value in a numeric dataset.",
            a: "Median value in a numeric dataset.",
            p: [
                {
                    name: "value1",
                    detail: "The first value or range to consider when calculating the median value.",
                },
                {
                    name: "value2",
                    detail: "Additional values or ranges to consider when calculating the median value.",
                },
            ],
        },
        MAXA: {
            d: "Returns the maximum numeric value in a dataset.",
            a: "Maximum numeric value in a dataset.",
            p: [
                {
                    name: "value1",
                    detail: "The first value or range to consider when calculating the maximum value.",
                },
                {
                    name: "value2",
                    detail: "Additional values or ranges to consider when calculating the maximum value.",
                },
            ],
        },
        MAX: {
            d: "Returns the maximum value in a numeric dataset.",
            a: "Maximum value in a numeric dataset.",
            p: [
                {
                    name: "value1",
                    detail: "The first value or range to consider when calculating the maximum value.",
                },
                {
                    name: "value2",
                    detail: "Additional values or ranges to consider when calculating the maximum value.",
                },
            ],
        },
        LOGNORM_INV: {
            d:
                "Returns the value of the inverse log-normal cumulative distribution with given mean and standard deviation at a specified value.",
            a: "Inverse log-normal cumulative distribution function.",
            p: [
                {
                    name: "x",
                    detail: "The input to the inverse log-normal cumulative distribution function.",
                },
                {
                    name: "mean",
                    detail: "The mean (mu) of the inverse log-normal cumulative distribution function.",
                },
                {
                    name: "standard_deviation",
                    detail:
                        "The standard deviation (sigma) of the inverse log-normal cumulative distribution function.",
                },
            ],
        },
        LOGNORM_DIST: {
            d:
                "Returns the value of the log-normal cumulative distribution with given mean and standard deviation at a specified value.",
            a: "Log-normal cumulative distribution probability.",
            p: [
                {
                    name: "x",
                    detail: "The input to the log-normal cumulative distribution function.",
                },
                {
                    name: "mean",
                    detail: "The mean (mu) of the log-normal cumulative distribution function.",
                },
                {
                    name: "standard_deviation",
                    detail: "The standard deviation (sigma) of the log-normal cumulative distribution function.",
                },
                {
                    name: "cumulative",
                    detail:
                        "Determine the logical value of the function form. \n\nIf TRUE(), it returns the cumulative distribution function;\n\nIf it is FALSE(), it returns the probability density function.",
                },
            ],
        },
        Z_TEST: {
            d: "Returns the one-tailed p-value of a Z-test with standard distribution.",
            a: "One-tailed p-value of a z-test.",
            p: [
                {
                    name: "data",
                    detail: "The array or range containing the dataset to consider.",
                },
                {
                    name: "value",
                    detail: "The test statistic to use in the Z-test.",
                },
                {
                    name: "standard_deviation",
                    detail:
                        "The standard deviation to assume for the Z-test. If this is not provided, the standard deviation of the data will be used.",
                },
            ],
        },
        PROB: {
            d:
                "Given a set of values and corresponding probabilities, calculates the probability that a value chosen at random falls between two limits.",
            a: "Probability values lie in a range.",
            p: [
                {
                    name: "data",
                    detail: "Array or range containing the dataset to consider.",
                },
                {
                    name: "probabilities",
                    detail: "Array or range containing probabilities corresponding to `data`.",
                },
                {
                    name: "low_limit",
                    detail: "The lower bound on the value range for which to calculate the probability.",
                },
                {
                    name: "high_limit",
                    detail: "The upper bound on the value range for which to calculate the probability.",
                },
            ],
        },
        QUARTILE_EXC: {
            d: "Returns a value nearest to a specified quartile of a dataset exclusive of 0 and 4.",
            a: "Value nearest to a specific quartile of a dataset exclusive of 0 and 4.",
            p: [
                {
                    name: "data",
                    detail: "The array or range containing the dataset to consider.",
                },
                {
                    name: "quartile_number",
                    detail: "Which quartile to return.",
                },
            ],
        },
        QUARTILE_INC: {
            d: "Returns a value nearest to a specified quartile of a dataset.",
            a: "Value nearest to a specific quartile of a dataset.",
            p: [
                {
                    name: "data",
                    detail: "The array or range containing the dataset to consider.",
                },
                {
                    name: "quartile_number",
                    detail: "Which quartile value to return.",
                },
            ],
        },
        POISSON_DIST: {
            d:
                "Returns the value of the Poisson distribution function (or Poisson cumulative distribution function) for a specified value and mean.",
            a: "Poisson distribution function.",
            p: [
                {
                    name: "x",
                    detail: "The input to the Poisson distribution function.",
                },
                {
                    name: "mean",
                    detail: "The mean (mu) of the Poisson distribution function.",
                },
                {
                    name: "cumulative",
                    detail:
                        "Whether to use the Poisson cumulative distribution function rather than the distribution function.",
                },
            ],
        },
        RSQ: {
            d: "Calculates the square of r, the Pearson product-moment correlation coefficient of a dataset.",
            a: "Square of the correlation coefficient.",
            p: [
                {
                    name: "data_y",
                    detail: "The range representing the array or matrix of dependent data.",
                },
                {
                    name: "data_x",
                    detail: "The range representing the array or matrix of independent data.",
                },
            ],
        },
        T_DIST: {
            d: "Calculates the left tail probability for a Student's t-distribution with a given input (x).",
            a: "The left-tailed Student's t-distribution",
            p: [
                {
                    name: "x",
                    detail: "The input to the t-distribution function.",
                },
                {
                    name: "degrees_freedom",
                    detail: "The number of degrees of freedom.",
                },
                {
                    name: "cumulative",
                    detail:
                        "If cumulative is TRUE, T.DIST returns the cumulative distribution function; if FALSE, it returns the probability density function.",
                },
            ],
        },
        T_DIST_2T: {
            d: "Calculates the probability for two tailed Student's t-distribution with a given input (x).",
            a: "The two tailed Student's t-distribution",
            p: [
                {
                    name: "x",
                    detail: "The input to the t-distribution function.",
                },
                {
                    name: "degrees_freedom",
                    detail: "The number of degrees of freedom.",
                },
            ],
        },
        T_DIST_RT: {
            d: "Calculates the right tail probability for a Student's t-distribution with a given input (x).",
            a: "The right-tailed Student's t-distribution",
            p: [
                {
                    name: "x",
                    detail: "The input to the t-distribution function.",
                },
                {
                    name: "degrees_freedom",
                    detail: "The number of degrees of freedom.",
                },
            ],
        },
        T_INV: {
            d: "Calculates the negative inverse of the one-tailed TDIST function.",
            a: "T.INV",
            p: [
                {
                    name: "probability",
                    detail: "The probability associated with the two-tailed t-distribution.",
                },
                {
                    name: "degrees_freedom",
                    detail: "The number of degrees of freedom.",
                },
            ],
        },
        T_INV_2T: {
            d: "Calculates the inverse of the two-tailed TDIST function.",
            a: "T.INV.2T",
            p: [
                {
                    name: "probability",
                    detail: "The probability associated with the two-tailed t-distribution.",
                },
                {
                    name: "degrees_freedom",
                    detail: "The number of degrees of freedom.",
                },
            ],
        },
        T_TEST: {
            d:
                "t-test. Returns the probability associated with Student's t-test. Determines whether two samples are likely to have come from the same two underlying populations that have the same mean.",
            a: "Returns the probability associated with t-test.",
            p: [
                {
                    name: "range1",
                    detail: "The first sample of data or group of cells to consider for the t-test.",
                },
                {
                    name: "range2",
                    detail: "The second sample of data or group of cells to consider for the t-test.",
                },
                {
                    name: "tails",
                    detail: "Specifies the number of distribution tails.",
                },
                {
                    name: "type",
                    detail: "Specifies the type of t-test.",
                },
            ],
        },
        F_DIST: {
            d:
                "Calculates the left-tailed F probability distribution (degree of diversity) for two data sets with given input x. Alternately called Fisher-Snedecor distribution or Snedecor's F distribution.",
            a: "F probability distribution (left-tailed).",
            p: [
                {
                    name: "x",
                    detail:
                        "The input to the F probability distribution function. The value at which to evaluate the function.",
                },
                {
                    name: "degrees_freedom1",
                    detail: "The numerator of the number of degrees of freedom.",
                },
                {
                    name: "degrees_freedom2",
                    detail: "The denominator of the number of degrees of freedom.",
                },
                {
                    name: "cumulative",
                    detail: "Logical value that determines the form of the function.",
                },
            ],
        },
        F_DIST_RT: {
            d:
                "Calculates the right-tailed F probability distribution (degree of diversity) for two data sets with given input x. Alternately called Fisher-Snedecor distribution or Snedecor's F distribution.",
            a: "F probability distribution.",
            p: [
                {
                    name: "x",
                    detail:
                        "The input to the F probability distribution function. The value at which to evaluate the function.",
                },
                {
                    name: "degrees_freedom1",
                    detail: "The numerator of the number of degrees of freedom.",
                },
                {
                    name: "degrees_freedom2",
                    detail: "The denominator of the number of degrees of freedom.",
                },
            ],
        },
        VAR_P: {
            d: "Calculates the variance based on an entire population.",
            a: "Variance of entire population.",
            p: [
                {
                    name: "value1",
                    detail: "The first value or range of the population.",
                },
                {
                    name: "value2",
                    detail: "Additional values or ranges to include in the population.",
                },
            ],
        },
        VAR_S: {
            d: "Calculates the variance based on a sample.",
            a: "Variance.",
            p: [
                {
                    name: "value1",
                    detail: "The first value or range of the sample.",
                },
                {
                    name: "value2",
                    detail: "Additional values or ranges to include in the sample.",
                },
            ],
        },
        VARA: {
            d: "Calculates the variance based on a sample, setting text to the value `0`.",
            a: "Variance of sample (text as 0).",
            p: [
                {
                    name: "value1",
                    detail: "The first value or range of the sample.",
                },
                {
                    name: "value2",
                    detail: "Additional values or ranges to include in the sample.",
                },
            ],
        },
        VARPA: {
            d: "Calculates the variance based on an entire population, setting text to the value `0`.",
            a: "Variance of entire population (text as 0).",
            p: [
                {
                    name: "value1",
                    detail: "The first value or range of the population.",
                },
                {
                    name: "value2",
                    detail: "Additional values or ranges to include in the population.",
                },
            ],
        },
        STEYX: {
            d: "Calculates the standard error of the predicted y-value for each x in the regression of a dataset.",
            a: "Standard error of predicted y-values in regression.",
            p: [
                {
                    name: "data_y",
                    detail: "The range representing the array or matrix of dependent data.",
                },
                {
                    name: "data_x",
                    detail: "The range representing the array or matrix of independent data.",
                },
            ],
        },
        STANDARDIZE: {
            d:
                "Calculates the normalized equivalent of a random variable given mean and standard deviation of the distribution.",
            a: "Normalized equivalent of a random variable.",
            p: [
                {
                    name: "value",
                    detail: "The value of the random variable to normalize.",
                },
                {
                    name: "mean",
                    detail: "The mean of the distribution.",
                },
                {
                    name: "standard_deviation",
                    detail: "The standard deviation of the distribution.",
                },
            ],
        },
        SMALL: {
            d: "Returns the nth smallest element from a data set, where n is user-defined.",
            a: "Nth smallest element in a data set.",
            p: [
                {
                    name: "data",
                    detail: "The array or range containing the dataset to consider.",
                },
                {
                    name: "n",
                    detail: "The rank from smallest to largest of the element to return.",
                },
            ],
        },
        SLOPE: {
            d: "Calculates the slope of the line resulting from linear regression of a dataset.",
            a: "Slope of line from linear regression of data.",
            p: [
                {
                    name: "data_y",
                    detail: "The range representing the array or matrix of dependent data.",
                },
                {
                    name: "data_x",
                    detail: "The range representing the array or matrix of independent data.",
                },
            ],
        },
        SKEW: {
            d: "Calculates the skewness of a dataset, which describes the symmetry of that dataset about the mean.",
            a: "Skewness of a dataset.",
            p: [
                {
                    name: "value1",
                    detail: "The first value or range of the dataset.",
                },
                {
                    name: "value2",
                    detail: "Additional values or ranges to include in the dataset.",
                },
            ],
        },
        SKEW_P: {
            d:
                "Calculates the skewness of a dataset, which describes the symmetry of that dataset about the mean. This assumes the dataset is for the population.",
            a: "Skewness of a population's dataset.",
            p: [
                {
                    name: "value1",
                    detail: "The first value or range of the dataset.",
                },
                {
                    name: "value2",
                    detail: "Additional values or ranges to include in the dataset.",
                },
            ],
        },
        VLOOKUP: {
            d:
                "Vertical lookup. Searches down the first column of a range for a key and returns the value of a specified cell in the row found.",
            a: "Vertical lookup.",
            p: [
                {
                    name: "search_key",
                    detail: 'The value to search for. For example, `42`, `"Cats"`, or `I24`.',
                },
                {
                    name: "range",
                    detail:
                        "The range to consider for the search. The first column in the range is searched for the key specified in `search_key`.",
                },
                {
                    name: "index",
                    detail:
                        "The column index of the value to be returned, where the first column in `range` is numbered 1.",
                },
                {
                    name: "is_sorted",
                    detail:
                        "Indicates whether the column to be searched (the first column of the specified range) is sorted, in which case the closest match for `search_key` will be returned.",
                },
            ],
        },
        HLOOKUP: {
            d:
                "Horizontal lookup. Searches across the first row of a range for a key and returns the value of a specified cell in the column found.",
            a: "Horizontal lookup",
            p: [
                {
                    name: "search_key",
                    detail: 'The value to search for. For example, `42`, `"Cats"`, or `I24`.',
                },
                {
                    name: "range",
                    detail:
                        "The range to consider for the search. The first row in the range is searched for the key specified in `search_key`.",
                },
                {
                    name: "index",
                    detail: "The row index of the value to be returned, where the first row in `range` is numbered 1.",
                },
                {
                    name: "is_sorted",
                    detail:
                        "Indicates whether the row to be searched (the first row of the specified range) is sorted.",
                },
            ],
        },
        LOOKUP: {
            d:
                "Looks through a sorted row or column for a key and returns the value of the cell in a result range located in the same position as the search row or column.",
            a: "Look up a value.",
            p: [
                {
                    name: "search_key",
                    detail: 'The value to search for in the row or column. For example, `42`, `"Cats"`, or `I24`.',
                },
                {
                    name: "search_range|search_result_array",
                    detail:
                        "One method of using this function is to provide a single sorted row or column `search_range` to look through for the `search_key` with a second argument `result_range`. The other way is to combine these two arguments into one `search_result_array` where the first row or column is searched and a value is returned from the last row or column in the array. If `search_key` is not found, a non-exact match may be returned.",
                },
                {
                    name: "result_range",
                    detail:
                        "The range from which to return a result. The value returned corresponds to the location where `search_key` is found in `search_range`. This range must be only a single row or column and should not be used if using the `search_result_array` method.",
                },
            ],
        },
        ADDRESS: {
            d: "Returns a cell reference as a string.",
            a: "Cell reference as a string.",
            p: [
                {
                    name: "row",
                    detail: "The row number of the cell reference",
                },
                {
                    name: "column",
                    detail: "The column number (not name) of the cell reference. `A` is column number `1`.",
                },
                {
                    name: "absolute_relative_mode",
                    detail:
                        "An indicator of whether the reference is row/column absolute. `1` is row and column absolute (e.g. $A$1), `2` is row absolute and column relative (e.g. A$1), `3` is row relative and column absolute (e.g. $A1), and `4` is row and column relative (e.g. A1).",
                },
                {
                    name: "use_a1_notation",
                    detail:
                        "A boolean indicating whether to use `A1` style notation (TRUE) or `R1C1` style notation (FALSE).",
                },
                {
                    name: "sheet",
                    detail: "Text indicating the name of the sheet into which the address points.",
                },
            ],
        },
        INDIRECT: {
            d: "Returns a cell reference specified by a string.",
            a: "A cell reference specified by a string.",
            p: [
                {
                    name: "cell_reference_as_string",
                    detail: "A cell reference, written as a string with surrounding quotation marks.",
                },
                {
                    name: "is_A1_notation",
                    detail: "Indicates if the cell reference is in A1 notation (TRUE) or R1C1 notation (FALSE).",
                },
            ],
        },
        ROW: {
            d: "Returns the row number of a specified cell.",
            a: "Row number of a specified cell.",
            p: [
                {
                    name: "cell_reference",
                    detail: "The cell whose row number will be returned.",
                },
            ],
        },
        ROWS: {
            d: "Returns the number of rows in a specified array or range.",
            a: "Number of rows in a specified array or range.",
            p: [
                {
                    name: "range",
                    detail: "The range whose row count will be returned.",
                },
            ],
        },
        COLUMN: {
            d: "Returns the column number of a specified cell, with `A=1`.",
            a: "Column number of a specified cell.",
            p: [
                {
                    name: "cell_reference",
                    detail: "The cell whose column number will be returned. Column `A` corresponds to `1`.",
                },
            ],
        },
        COLUMNS: {
            d: "Returns the number of columns in a specified array or range.",
            a: "Number of columns in a specified array or range.",
            p: [
                {
                    name: "range",
                    detail: "The range whose column count will be returned.",
                },
            ],
        },
        OFFSET: {
            d:
                "Returns a range reference shifted a specified number of rows and columns from a starting cell reference.",
            a: "A range reference offset relative to a cell.",
            p: [
                {
                    name: "cell_reference",
                    detail: "The starting point from which to count the offset rows and columns.",
                },
                {
                    name: "offset_rows",
                    detail: "The number of rows to offset by.",
                },
                {
                    name: "offset_columns",
                    detail: "The number of columns to offset by.",
                },
                {
                    name: "height",
                    detail: "The height of the range to return starting at the offset target.",
                },
                {
                    name: "width",
                    detail: "The width of the range to return starting at the offset target.",
                },
            ],
        },
        MATCH: {
            d: "Returns the relative position of an item in a range that matches a specified value.",
            a: "Position of item in range that matches value.",
            p: [
                {
                    name: "search_key",
                    detail: 'The value to search for. For example, `42`, `"Cats"`, or `I24`.',
                },
                {
                    name: "range",
                    detail: "The one-dimensional array to be searched.",
                },
                {
                    name: "search_type",
                    detail:
                        "The search method. `1` (default) finds the largest value less than or equal to `search_key` when `range` is sorted in ascending order. `0` finds the exact value when `range` is unsorted. `-1` finds the smallest value greater than or equal to `search_key` when `range` is sorted in descending order.",
                },
            ],
        },
        INDEX: {
            d: "Returns the content of a cell, specified by row and column offset.",
            a: "Content of cell specified by row and column offset.",
            p: [
                {
                    name: "reference",
                    detail: "The array of cells to be offset into.",
                },
                {
                    name: "row",
                    detail: "The number of offset rows.",
                },
                {
                    name: "column",
                    detail: "The number of offset columns.",
                },
            ],
        },
        GETPIVOTDATA: {
            d:
                "Extracts an aggregated value from a pivot table that corresponds to the specified row and column headings.",
            a:
                "Extracts an aggregated value from a pivot table that corresponds to the specified row and column headings.",
            p: [
                {
                    name: "value_name",
                    detail: "The name of the value in the pivot table for which you want to get data.",
                },
                {
                    name: "any_pivot_table_cell",
                    detail: "Any reference to a cell in the desired pivot table (top corner recommended).",
                },
                {
                    name: "original_column",
                    detail: "The name of the column in the original data set (not the pivot table).",
                },
                {
                    name: "pivot_item",
                    detail:
                        "The name of the row or column shown in the pivot table corresponding to *original_column* that you want to retrieve.",
                },
            ],
        },
        CHOOSE: {
            d: "Returns an element from a list of choices based on index.",
            a: "An element from a list of choices based on index.",
            p: [
                {
                    name: "index",
                    detail: "Which choice (of the up to 30 provided) to return.",
                },
                {
                    name: "choice1",
                    detail:
                        "A potential value to return. Required. May be a reference to a cell or an individual value.",
                },
                {
                    name: "choice2",
                    detail: "Additional values among which to choose.",
                },
            ],
        },
        HYPERLINK: {
            d: "Creates a hyperlink inside a cell.",
            a: "Creates a hyperlink inside a cell.",
            p: [
                {
                    name: "url",
                    detail:
                        "The full URL of the link location enclosed in quotation marks, or a reference to a cell containing such a URL.",
                },
                {
                    name: "link_label",
                    detail:
                        "The text to display in the cell as the link, enclosed in quotation marks, or a reference to a cell containing such a label.",
                },
            ],
        },
        TIME: {
            d: "Converts a provided hour, minute, and second into a time.",
            a: "Converts hour/minute/second into a time.",
            p: [
                {
                    name: "hour",
                    detail: "The hour component of the time.",
                },
                {
                    name: "minute",
                    detail: "The minute component of the time.",
                },
                {
                    name: "second",
                    detail: "The second component of the time.",
                },
            ],
        },
        TIMEVALUE: {
            d: "Returns the fraction of a 24-hour day the time represents.",
            a: "Converts a time string into its serial number representation.",
            p: [
                {
                    name: "time_string",
                    detail: "The string that holds the time representation.",
                },
            ],
        },
        EOMONTH: {
            d:
                "Returns a date on the last day of a month that falls a specified number of months before or after another date.",
            a: "Last day of a month before or after a date.",
            p: [
                {
                    name: "start_date",
                    detail: "The date from which to calculate the result.",
                },
                {
                    name: "months",
                    detail: "The number of months before (negative) or after (positive) 'start_date' to consider.",
                },
            ],
        },
        EDATE: {
            d: "Returns a date a specified number of months before or after another date.",
            a: "Date a number of months before/after another date.",
            p: [
                {
                    name: "start_date",
                    detail: "The date from which to calculate the result.",
                },
                {
                    name: "months",
                    detail: "The number of months before (negative) or after (positive) 'start_date' to calculate.",
                },
            ],
        },
        SECOND: {
            d: "Returns the second component of a specific time, in numeric format.",
            a: "Second component of a specific time.",
            p: [
                {
                    name: "time",
                    detail: "The time from which to calculate the second component",
                },
            ],
        },
        MINUTE: {
            d: "Returns the minute component of a specific time, in numeric format.",
            a: "Minute component of a specific time.",
            p: [
                {
                    name: "time",
                    detail: "The time from which to calculate the minute component.",
                },
            ],
        },
        HOUR: {
            d: "Returns the hour component of a specific time, in numeric format.",
            a: "Hour component of a specific time.",
            p: [
                {
                    name: "time",
                    detail: "The time from which to calculate the hour component.",
                },
            ],
        },
        NOW: {
            d: "Returns the current date and time as a date value.",
            a: "Current date and time as a date value.",
            p: [],
        },
        NETWORKDAYS: {
            d: "Returns the number of net working days between two provided days.",
            a: "Net working days between two provided days.",
            p: [
                {
                    name: "start_date",
                    detail: "The start date of the period from which to calculate the number of net working days.",
                },
                {
                    name: "end_date",
                    detail: "The end date of the period from which to calculate the number of net working days.",
                },
                {
                    name: "holidays",
                    detail: "A range or array constant containing the date serial numbers to consider holidays.",
                },
            ],
        },
        NETWORKDAYS_INTL: {
            d:
                "Returns the number of net working days between two provided days excluding specified weekend days and holidays.",
            a: "Net working days between two dates (specifying weekends).",
            p: [
                {
                    name: "start_date",
                    detail: "The start date of the period from which to calculate the number of net working days.",
                },
                {
                    name: "end_date",
                    detail: "The end date of the period from which to calculate the number of net working days.",
                },
                {
                    name: "weekend",
                    detail: "A number or string representing which days of the week are considered weekends.",
                },
                {
                    name: "holidays",
                    detail: "A range or array constant containing the dates to consider as holidays.",
                },
            ],
        },
        ISOWEEKNUM: {
            d: "Returns a number representing the ISO week of the year where the provided date falls.",
            a: "ISO week number of the year.",
            p: [
                {
                    name: "date",
                    detail:
                        "The date for which to determine the ISO week number. Must be a reference to a cell containing a date, a function returning a date type, or a number.",
                },
            ],
        },
        WEEKNUM: {
            d: "Returns a number representing the week of the year where the provided date falls.",
            a: "Week number of the year.",
            p: [
                {
                    name: "date",
                    detail:
                        "The date for which to determine the week number. Must be a reference to a cell containing a date, a function returning a date type, or a number.",
                },
                {
                    name: "type",
                    detail: "A number representing the day that a week starts on. Sunday = 1.",
                },
            ],
        },
        WEEKDAY: {
            d: "Returns a number representing the day of the week of the date provided.",
            a: "Day of the week of the date provided (as number).",
            p: [
                {
                    name: "date",
                    detail:
                        "The date for which to determine the day of the week. Must be a reference to a cell containing a date, a function returning a date type, or a number.",
                },
                {
                    name: "type",
                    detail:
                        "A number indicating which numbering system to use to represent weekdays. By default, counts starting with Sunday = 1.",
                },
            ],
        },
        DAY: {
            d: "Returns the day of the month that a specific date falls on, in numeric format.",
            a: "Day of the month that a specific date falls on.",
            p: [
                {
                    name: "date",
                    detail: "The date from which to extract the day.",
                },
            ],
        },
        DAYS: {
            d: "Returns the number of days between two dates.",
            a: "Number of days between two dates.",
            p: [
                {
                    name: "end_date",
                    detail: "The end of the date range.",
                },
                {
                    name: "start_date",
                    detail: "The start of the date range.",
                },
            ],
        },
        DAYS360: {
            d:
                "Returns the difference between two days based on the 360 day year used in some financial interest calculations.",
            a: "Days between two dates on a 360-day year.",
            p: [
                {
                    name: "start_date",
                    detail:
                        "The start date to consider in the calculation. Must be a reference to a cell containing a date, a function returning a date type, or a number.",
                },
                {
                    name: "end_date",
                    detail:
                        "The end date to consider in the calculation. Must be a reference to a cell containing a date, a function returning a date type, or a number.",
                },
                {
                    name: "method",
                    detail: "An indicator of what day count method to use.",
                },
            ],
        },
        DATE: {
            d: "Converts a provided year, month, and day into a date.",
            a: "Converts year/month/day into a date.",
            p: [
                {
                    name: "year",
                    detail: "The year component of the date.",
                },
                {
                    name: "month",
                    detail: "The month component of the date.",
                },
                {
                    name: "day",
                    detail: "The day component of the date.",
                },
            ],
        },
        DATEVALUE: {
            d: "Converts a provided date string in a known format to a date value.",
            a: "Converts a date string to a date value.",
            p: [
                {
                    name: "date_string",
                    detail: "The string representing the date.",
                },
            ],
        },
        DATEDIF: {
            d: "Calculates the number of days, months, or years between two dates.",
            a: "Date Difference.",
            p: [
                {
                    name: "start_date",
                    detail:
                        "The start date to consider in the calculation. Must be a reference to a cell containing a date, a function returning a date type, or a number.",
                },
                {
                    name: "end_date",
                    detail:
                        "The end date to consider in the calculation. Must be a reference to a cell containing a date, a function returning a date type, or a number.",
                },
                {
                    name: "unit",
                    detail:
                        'A string abbreviation for unit of time. For example, "M" for month. Accepted values are "Y","M","D","MD","YM","YD".',
                },
            ],
        },
        WORKDAY: {
            d: "Calculates the date after a number of working days from a specified start date.",
            a: "Number of working days from start date.",
            p: [
                {
                    name: "start_date",
                    detail: "The date from which to begin counting.",
                },
                {
                    name: "num_days",
                    detail: "The number of working days to advance from `start_date`. If negative, counts backwards.",
                },
                {
                    name: "holidays",
                    detail: "A range or array constant containing the dates to consider holidays.",
                },
            ],
        },
        WORKDAY_INTL: {
            d:
                "Calculates the date after a specified number of workdays excluding specified weekend days and holidays.",
            a: "Date after a number of workdays (specifying weekends).",
            p: [
                {
                    name: "start_date",
                    detail: "The date from which to begin counting.",
                },
                {
                    name: "num_days",
                    detail: "The number of working days to advance from `start_date`. If negative, counts backwards.",
                },
                {
                    name: "weekend",
                    detail: "A number or string representing which days of the week are considered weekends.",
                },
                {
                    name: "holidays",
                    detail: "A range or array constant containing the dates to consider holidays.",
                },
            ],
        },
        YEAR: {
            d: "Returns the year specified by a given date.",
            a: "Year specified by a given date.",
            p: [
                {
                    name: "date",
                    detail: "The date from which to extract the year.",
                },
            ],
        },
        YEARFRAC: {
            d:
                "Returns the number of years, including fractional years, between two dates using a specified day count convention.",
            a: "Exact number of years between two dates.",
            p: [
                {
                    name: "start_date",
                    detail:
                        "The start date to consider in the calculation. Must be a reference to a cell containing a date, a function returning a date type, or a number.",
                },
                {
                    name: "end_date",
                    detail:
                        "The end date to consider in the calculation. Must be a reference to a cell containing a date, a function returning a date type, or a number.",
                },
                {
                    name: "day_count_convention",
                    detail: "An indicator of what day count method to use.",
                },
            ],
        },
        TODAY: {
            d: "Returns the current date as a date value.",
            a: "Current date as a date value.",
            p: [],
        },
        MONTH: {
            d: "Returns the month of the year a specific date falls in, in numeric format.",
            a: "Month of the year a specific date falls in.",
            p: [
                {
                    name: "date",
                    detail: "The date from which to extract the month.",
                },
            ],
        },
        EFFECT: {
            d:
                "Calculates the annual effective interest rate given the nominal rate and number of compounding periods per year.",
            a: "Annual effective interest rate.",
            p: [
                {
                    name: "nominal_rate",
                    detail: "The nominal interest rate per year.",
                },
                {
                    name: "periods_per_year",
                    detail: "The number of compounding periods per year.",
                },
            ],
        },
        DOLLAR: {
            d: "Formats a number into the currency specific to your spreadsheet locale.",
            a: "Formats a number as currency specific to your spreadsheet locale.",
            p: [
                {
                    name: "number",
                    detail: "The value to be formatted.",
                },
                {
                    name: "number_of_places",
                    detail: "The number of decimal places to display.",
                },
            ],
        },
        DOLLARDE: {
            d: "Converts a price quotation given as a decimal fraction into a decimal value.",
            a: "Converts a decimal fraction to decimal value.",
            p: [
                {
                    name: "fractional_price",
                    detail: "The price quotation given using fractional decimal conventions.",
                },
                {
                    name: "unit",
                    detail: "The units of the fraction, e.g. `8` for 1/8ths or `32` for 1/32nds.",
                },
            ],
        },
        DOLLARFR: {
            d: "Converts a price quotation given as a decimal value into a decimal fraction.",
            a: "Converts a decimal value to decimal fraction.",
            p: [
                {
                    name: "decimal_price",
                    detail: "The price quotation given as a decimal value.",
                },
                {
                    name: "unit",
                    detail: "The units of the desired fraction, e.g. `8` for 1/8ths or `32` for 1/32nds.",
                },
            ],
        },
        DB: {
            d:
                "Calculates the depreciation of an asset for a specified period using the arithmetic declining balance method.",
            a: "Depreciation via declining balance method.",
            p: [
                {
                    name: "cost",
                    detail: "The initial cost of the asset.",
                },
                {
                    name: "salvage",
                    detail: "The value of the asset at the end of depreciation.",
                },
                {
                    name: "life",
                    detail: "The number of periods over which the asset is depreciated.",
                },
                {
                    name: "period",
                    detail: "The single period within `life` for which to calculate depreciation.",
                },
                {
                    name: "month",
                    detail: "The number of months in the first year of depreciation.",
                },
            ],
        },
        DDB: {
            d:
                "Calculates the depreciation of an asset for a specified period using the double-declining balance method.",
            a: "Depreciation via double-declining balance method.",
            p: [
                {
                    name: "cost",
                    detail: "The initial cost of the asset.",
                },
                {
                    name: "salvage",
                    detail: "The value of the asset at the end of depreciation.",
                },
                {
                    name: "life",
                    detail: "The number of periods over which the asset is depreciated.",
                },
                {
                    name: "period",
                    detail: "The single period within `life` for which to calculate depreciation.",
                },
                {
                    name: "factor",
                    detail: "The factor by which depreciation decreases.",
                },
            ],
        },
        RATE: {
            d:
                "Calculates the interest rate of an annuity investment based on constant-amount periodic payments and the assumption of a constant interest rate.",
            a: "Interest rate of an annuity investment.",
            p: [
                {
                    name: "number_of_periods",
                    detail: "The number of payments to be made.",
                },
                {
                    name: "payment_per_period",
                    detail: "The amount per period to be paid.",
                },
                {
                    name: "present_value",
                    detail: "The current value of the annuity.",
                },
                {
                    name: "future_value",
                    detail: "The future value remaining after the final payment has been made.",
                },
                {
                    name: "end_or_beginning",
                    detail: "Whether payments are due at the end (`0`) or beginning (`1`) of each period.",
                },
                {
                    name: "rate_guess",
                    detail: "An estimate for what the interest rate will be.",
                },
            ],
        },
        CUMPRINC: {
            d:
                "Calculates the cumulative principal paid over a range of payment periods for an investment based on constant-amount periodic payments and a constant interest rate.",
            a: "Cumulative principal paid over a set of periods.",
            p: [
                {
                    name: "rate",
                    detail: "The interest rate.",
                },
                {
                    name: "number_of_periods",
                    detail: "The number of payments to be made.",
                },
                {
                    name: "present_value",
                    detail: "The current value of the annuity.",
                },
                {
                    name: "first_period",
                    detail: "The number of the payment period to begin the cumulative calculation.",
                },
                {
                    name: "last_period",
                    detail: "The number of the payment period to end the cumulative calculation.",
                },
                {
                    name: "end_or_beginning",
                    detail: "Whether payments are due at the end (`0`) or beginning (`1`) of each period.",
                },
            ],
        },
        COUPNUM: {
            d:
                "Calculates the number of coupons, or interest payments, between the settlement date and the maturity date of the investment.",
            a: "Number of coupons between settlement and maturity.",
            p: [
                {
                    name: "settlement",
                    detail:
                        "The settlement date of the security, the date after issuance when the security is delivered to the buyer.",
                },
                {
                    name: "maturity",
                    detail: "The maturity or end date of the security, when it can be redeemed at face, or par value.",
                },
                {
                    name: "frequency",
                    detail: "The number of interest or coupon payments per year (1, 2, or 4).",
                },
                {
                    name: "day_count_convention",
                    detail: "An indicator of what day count method to use.",
                },
            ],
        },
        SYD: {
            d: "Calculates the depreciation of an asset for a specified period using the sum of years digits method.",
            a: "Depreciation via sum of years digits method.",
            p: [
                {
                    name: "cost",
                    detail: "The initial cost of the asset.",
                },
                {
                    name: "salvage",
                    detail: "The value of the asset at the end of depreciation.",
                },
                {
                    name: "life",
                    detail: "The number of periods over which the asset is depreciated.",
                },
                {
                    name: "period",
                    detail: "The single period within `life` for which to calculate depreciation.",
                },
            ],
        },
        TBILLEQ: {
            d: "Calculates the equivalent annualized rate of return of a US Treasury Bill based on discount rate.",
            a: "Equivalent rate of return for a Treasury bill.",
            p: [
                {
                    name: "settlement",
                    detail:
                        "The settlement date of the security, the date after issuance when the security is delivered to the buyer.",
                },
                {
                    name: "maturity",
                    detail: "The maturity or end date of the security, when it can be redeemed at face, or par value.",
                },
                {
                    name: "discount",
                    detail: "The discount rate of the bill at time of purchase.",
                },
            ],
        },
        TBILLYIELD: {
            d: "Calculates the yield of a US Treasury Bill based on price.",
            a: "The yield of a us treasury bill based on price.",
            p: [
                {
                    name: "settlement",
                    detail:
                        "The settlement date of the security, the date after issuance when the security is delivered to the buyer.",
                },
                {
                    name: "maturity",
                    detail: "The maturity or end date of the security, when it can be redeemed at face, or par value.",
                },
                {
                    name: "price",
                    detail: "The price at which the security is bought per 100 face value.",
                },
            ],
        },
        TBILLPRICE: {
            d: "Calculates the price of a US Treasury Bill based on discount rate.",
            a: "Price of US treasury bill.",
            p: [
                {
                    name: "settlement",
                    detail:
                        "The settlement date of the security, the date after issuance when the security is delivered to the buyer.",
                },
                {
                    name: "maturity",
                    detail: "The maturity or end date of the security, when it can be redeemed at face, or par value.",
                },
                {
                    name: "discount",
                    detail: "The discount rate of the bill at time of purchase.",
                },
            ],
        },
        PV: {
            d:
                "Calculates the present value of an annuity investment based on constant-amount periodic payments and a constant interest rate.",
            a: "Present value of an annuity investment.",
            p: [
                {
                    name: "rate",
                    detail: "The interest rate.",
                },
                {
                    name: "number_of_periods",
                    detail: "The number of payments to be made.",
                },
                {
                    name: "payment_amount",
                    detail: "The amount per period to be paid.",
                },
                {
                    name: "future_value",
                    detail: "The future value remaining after the final payment has been made.",
                },
                {
                    name: "end_or_beginning",
                    detail: "Whether payments are due at the end (`0`) or beginning (`1`) of each period.",
                },
            ],
        },
        ACCRINT: {
            d: "Calculates the accrued interest of a security that has periodic payments.",
            a: "Accrued interest of security with periodic payments.",
            p: [
                {
                    name: "issue",
                    detail: "The date the security was initially issued.",
                },
                {
                    name: "first_payment",
                    detail: "The first date interest will be paid.",
                },
                {
                    name: "settlement",
                    detail:
                        "The settlement date of the security, the date after issuance when the security is delivered to the buyer.",
                },
                {
                    name: "rate",
                    detail: "The annualized rate of interest.",
                },
                {
                    name: "redemption",
                    detail: "The redemption amount per 100 face value, or par.",
                },
                {
                    name: "frequency",
                    detail: "The number of interest or coupon payments per year (1, 2, or 4).",
                },
                {
                    name: "day_count_convention",
                    detail: "An indicator of what day count method to use.",
                },
                {
                    name: "calc_method",
                    detail:
                        "[Optional-defaults to TRUE()] - A logical value that specifies the method used to calculate the total accrued interest when the settlement date is later than the first interest accrual date. \n\nIf the value is TRUE, the total accrued interest from the issue date to the settlement date is returned. \n\nIf the value is FALSE, return the accrued interest from the first interest accrual date to the settlement date.",
                },
            ],
        },
        ACCRINTM: {
            d: "Calculates the accrued interest of a security that pays interest at maturity.",
            a: "Accrued interest of security paying at maturity.",
            p: [
                {
                    name: "issue",
                    detail: "The date the security was initially issued.",
                },
                {
                    name: "maturity",
                    detail: "The maturity date of the security.",
                },
                {
                    name: "rate",
                    detail: "The annualized rate of interest.",
                },
                {
                    name: "redemption",
                    detail: "The redemption amount per 100 face value, or par.",
                },
                {
                    name: "day_count_convention",
                    detail: "An indicator of what day count method to use.",
                },
            ],
        },
        COUPDAYBS: {
            d: "Calculates the number of days from the first coupon, or interest payment, until settlement.",
            a: "Number of days from first coupon to settlement.",
            p: [
                {
                    name: "settlement",
                    detail:
                        "The settlement date of the security, the date after issuance when the security is delivered to the buyer.",
                },
                {
                    name: "maturity",
                    detail: "The maturity or end date of the security, when it can be redeemed at face, or par value.",
                },
                {
                    name: "frequency",
                    detail: "The number of interest or coupon payments per year (1, 2, or 4).",
                },
                {
                    name: "day_count_convention",
                    detail: "An indicator of what day count method to use.",
                },
            ],
        },
        COUPDAYS: {
            d:
                "Calculates the number of days in the coupon, or interest payment, period that contains the specified settlement date.",
            a: "Days in coupon period containing settlement date.",
            p: [
                {
                    name: "settlement",
                    detail:
                        "The settlement date of the security, the date after issuance when the security is delivered to the buyer.",
                },
                {
                    name: "maturity",
                    detail: "The maturity or end date of the security, when it can be redeemed at face, or par value.",
                },
                {
                    name: "frequency",
                    detail: "The number of interest or coupon payments per year (1, 2, or 4).",
                },
                {
                    name: "day_count_convention",
                    detail: "An indicator of what day count method to use.",
                },
            ],
        },
        COUPDAYSNC: {
            d: "Calculates the number of days from the settlement date until the next coupon, or interest payment.",
            a: "Days from settlement until next coupon.",
            p: [
                {
                    name: "settlement",
                    detail:
                        "The settlement date of the security, the date after issuance when the security is delivered to the buyer.",
                },
                {
                    name: "maturity",
                    detail: "The maturity or end date of the security, when it can be redeemed at face, or par value.",
                },
                {
                    name: "frequency",
                    detail: "The number of interest or coupon payments per year (1, 2, or 4).",
                },
                {
                    name: "day_count_convention",
                    detail: "An indicator of what day count method to use.",
                },
            ],
        },
        COUPNCD: {
            d: "Calculates next coupon, or interest payment, date after the settlement date.",
            a: "Next coupon date after the settlement date.",
            p: [
                {
                    name: "settlement",
                    detail:
                        "The settlement date of the security, the date after issuance when the security is delivered to the buyer.",
                },
                {
                    name: "maturity",
                    detail: "The maturity or end date of the security, when it can be redeemed at face, or par value.",
                },
                {
                    name: "frequency",
                    detail: "The number of interest or coupon payments per year (1, 2, or 4).",
                },
                {
                    name: "day_count_convention",
                    detail: "An indicator of what day count method to use.",
                },
            ],
        },
        COUPPCD: {
            d: "Calculates last coupon, or interest payment, date before the settlement date.",
            a: "Last coupon date before settlement date.",
            p: [
                {
                    name: "settlement",
                    detail:
                        "The settlement date of the security, the date after issuance when the security is delivered to the buyer.",
                },
                {
                    name: "maturity",
                    detail: "The maturity or end date of the security, when it can be redeemed at face, or par value.",
                },
                {
                    name: "frequency",
                    detail: "The number of interest or coupon payments per year (1, 2, or 4).",
                },
                {
                    name: "day_count_convention",
                    detail: "An indicator of what day count method to use.",
                },
            ],
        },
        FV: {
            d:
                "Calculates the future value of an annuity investment based on constant-amount periodic payments and a constant interest rate.",
            a: "Future value of an annuity investment.",
            p: [
                {
                    name: "rate",
                    detail: "The interest rate.",
                },
                {
                    name: "number_of_periods",
                    detail: "The number of payments to be made.",
                },
                {
                    name: "payment_amount",
                    detail: "The amount per period to be paid.",
                },
                {
                    name: "present_value",
                    detail: "The current value of the annuity.",
                },
                {
                    name: "end_or_beginning",
                    detail: "Whether payments are due at the end (`0`) or beginning (`1`) of each period.",
                },
            ],
        },
        FVSCHEDULE: {
            d:
                "Calculates the future value of some principal based on a specified series of potentially varying interest rates.",
            a: "Future value of principal from series of rates.",
            p: [
                {
                    name: "principal",
                    detail: "The amount of initial capital or value to compound against.",
                },
                {
                    name: "rate_schedule",
                    detail: "A series of interest rates to compound against the `principal`.",
                },
            ],
        },
        YIELD: {
            d:
                "Calculates the annual yield of a security paying periodic interest, such as a US Treasury Bond, based on price.",
            a: "Annual yield of a security paying periodic interest.",
            p: [
                {
                    name: "settlement",
                    detail:
                        "The settlement date of the security, the date after issuance when the security is delivered to the buyer.",
                },
                {
                    name: "maturity",
                    detail: "The maturity or end date of the security, when it can be redeemed at face, or par value.",
                },
                {
                    name: "rate",
                    detail: "The annualized rate of interest.",
                },
                {
                    name: "price",
                    detail: "The price at which the security is bought per 100 face value.",
                },
                {
                    name: "redemption",
                    detail: "The redemption amount per 100 face value, or par.",
                },
                {
                    name: "frequency",
                    detail: "The number of interest or coupon payments per year (1, 2, or 4).",
                },
                {
                    name: "day_count_convention",
                    detail: "An indicator of what day count method to use.",
                },
            ],
        },
        YIELDDISC: {
            d: "Calculates the annual yield of a discount (non-interest-bearing) security, based on price.",
            a: "Annual yield of a discount security.",
            p: [
                {
                    name: "settlement",
                    detail:
                        "The settlement date of the security, the date after issuance when the security is delivered to the buyer.",
                },
                {
                    name: "maturity",
                    detail: "The maturity or end date of the security, when it can be redeemed at face, or par value.",
                },
                {
                    name: "price",
                    detail: "The price at which the security is bought per 100 face value.",
                },
                {
                    name: "redemption",
                    detail: "The redemption amount per 100 face value, or par.",
                },
                {
                    name: "day_count_convention",
                    detail: "An indicator of what day count method to use.",
                },
            ],
        },
        NOMINAL: {
            d:
                "Calculates the annual nominal interest rate given the effective rate and number of compounding periods per year.",
            a: "Annual nominal interest rate.",
            p: [
                {
                    name: "effective_rate",
                    detail: "The effective interest rate per year.",
                },
                {
                    name: "periods_per_year",
                    detail: "The number of compounding periods per year.",
                },
            ],
        },
        XIRR: {
            d:
                "Calculates the internal rate of return of an investment based on a specified series of potentially irregularly spaced cash flows.",
            a: "Internal rate of return given non-periodic cashflows.",
            p: [
                {
                    name: "cashflow_amounts",
                    detail: "An array or range containing the income or payments associated with the investment.",
                },
                {
                    name: "cashflow_dates",
                    detail: "An array or range with dates corresponding to the cash flows in `cashflow_amounts`.",
                },
                {
                    name: "rate_guess",
                    detail: "An estimate for what the internal rate of return will be.",
                },
            ],
        },
        MIRR: {
            d:
                "Calculates the modified internal rate of return on an investment based on a series of periodic cash flows and the difference between the interest rate paid on financing versus the return received on reinvested income.",
            a: "Modified internal rate of return.",
            p: [
                {
                    name: "cashflow_amounts",
                    detail: "An array or range containing the income or payments associated with the investment.",
                },
                {
                    name: "financing_rate",
                    detail: "The interest rate paid on funds invested.",
                },
                {
                    name: "reinvestment_return_rate",
                    detail:
                        "The return (as a percentage) earned on reinvestment of income received from the investment.",
                },
            ],
        },
        IRR: {
            d: "Calculates the internal rate of return on an investment based on a series of periodic cash flows.",
            a: "Internal rate of return given periodic cashflows.",
            p: [
                {
                    name: "cashflow_amounts",
                    detail: "An array or range containing the income or payments associated with the investment.",
                },
                {
                    name: "rate_guess",
                    detail: "An estimate for what the internal rate of return will be.",
                },
            ],
        },
        NPV: {
            d:
                "Calculates the net present value of an investment based on a series of periodic cash flows and a discount rate.",
            a: "The net present value of an investment based on a series of periodic cash flows and a discount rate.",
            p: [
                {
                    name: "discount",
                    detail: "The discount rate of the investment over one period.",
                },
                {
                    name: "cashflow1",
                    detail: "The first future cash flow.",
                },
                {
                    name: "cashflow2",
                    detail: "Additional future cash flows.",
                },
            ],
        },
        XNPV: {
            d:
                "Calculates the net present value of an investment based on a specified series of potentially irregularly spaced cash flows and a discount rate.",
            a: "Net present value given non-periodic cashflows.",
            p: [
                {
                    name: "discount",
                    detail: "The discount rate of the investment over one period.",
                },
                {
                    name: "cashflow_amounts",
                    detail: "A range of cells containing the income or payments associated with the investment.",
                },
                {
                    name: "cashflow_dates",
                    detail: "A range of cells with dates corresponding to the cash flows in `cashflow_amounts`.",
                },
            ],
        },
        CUMIPMT: {
            d:
                "Calculates the cumulative interest over a range of payment periods for an investment based on constant-amount periodic payments and a constant interest rate.",
            a: "Cumulative interest paid over a set of periods.",
            p: [
                {
                    name: "rate",
                    detail: "The interest rate.",
                },
                {
                    name: "number_of_periods",
                    detail: "The number of payments to be made.",
                },
                {
                    name: "present_value",
                    detail: "The current value of the annuity.",
                },
                {
                    name: "first_period",
                    detail: "The number of the payment period to begin the cumulative calculation.",
                },
                {
                    name: "last_period",
                    detail: "The number of the payment period to end the cumulative calculation.",
                },
                {
                    name: "end_or_beginning",
                    detail: "Whether payments are due at the end (`0`) or beginning (`1`) of each period.",
                },
            ],
        },
        PMT: {
            d:
                "Calculates the periodic payment for an annuity investment based on constant-amount periodic payments and a constant interest rate.",
            a: "Periodic payment for an annuity investment.",
            p: [
                {
                    name: "rate",
                    detail: "The interest rate.",
                },
                {
                    name: "number_of_periods",
                    detail: "The number of payments to be made.",
                },
                {
                    name: "present_value",
                    detail: "The current value of the annuity.",
                },
                {
                    name: "future_value",
                    detail: "The future value remaining after the final payment has been made.",
                },
                {
                    name: "end_or_beginning",
                    detail: "Whether payments are due at the end (`0`) or beginning (`1`) of each period.",
                },
            ],
        },
        IPMT: {
            d:
                "Calculates the payment on interest for an investment based on constant-amount periodic payments and a constant interest rate.",
            a: "Payment on interest for an investment.",
            p: [
                {
                    name: "rate",
                    detail: "The interest rate.",
                },
                {
                    name: "period",
                    detail: "The amortization period, in terms of number of periods.",
                },
                {
                    name: "number_of_periods",
                    detail: "The number of payments to be made.",
                },
                {
                    name: "present_value",
                    detail: "The current value of the annuity.",
                },
                {
                    name: "future_value",
                    detail: "The future value remaining after the final payment has been made.",
                },
                {
                    name: "end_or_beginning",
                    detail: "Whether payments are due at the end (`0`) or beginning (`1`) of each period.",
                },
            ],
        },
        PPMT: {
            d:
                "Calculates the payment on the principal of an investment based on constant-amount periodic payments and a constant interest rate.",
            a: "Payment on the principal of an investment.",
            p: [
                {
                    name: "rate",
                    detail: "The interest rate.",
                },
                {
                    name: "period",
                    detail: "The amortization period, in terms of number of periods.",
                },
                {
                    name: "number_of_periods",
                    detail: "The number of payments to be made.",
                },
                {
                    name: "present_value",
                    detail: "The current value of the annuity.",
                },
                {
                    name: "future_value",
                    detail: "The future value remaining after the final payment has been made.",
                },
                {
                    name: "end_or_beginning",
                    detail: "Whether payments are due at the end (`0`) or beginning (`1`) of each period.",
                },
            ],
        },
        INTRATE: {
            d:
                "Calculates the effective interest rate generated when an investment is purchased at one price and sold at another with no interest or dividends generated by the investment itself.",
            a: "Calculates effective interest rate.",
            p: [
                {
                    name: "buy_date",
                    detail: "The date of purchase of the investment.",
                },
                {
                    name: "sell_date",
                    detail: "The date of sale of the investment.",
                },
                {
                    name: "buy_price",
                    detail: "The price at which the investment was purchased.",
                },
                {
                    name: "sell_price",
                    detail: "The price at which the investment was sold.",
                },
                {
                    name: "day_count_convention",
                    detail: "An indicator of what day count method to use.",
                },
            ],
        },
        PRICE: {
            d:
                "Calculates the price of a security paying periodic interest, such as a US Treasury Bond, based on expected yield.",
            a: "Price of a security paying periodic interest.",
            p: [
                {
                    name: "settlement",
                    detail:
                        "The settlement date of the security, the date after issuance when the security is delivered to the buyer.",
                },
                {
                    name: "maturity",
                    detail: "The maturity or end date of the security, when it can be redeemed at face, or par value.",
                },
                {
                    name: "rate",
                    detail: "The annualized rate of interest.",
                },
                {
                    name: "yield",
                    detail: "The expected annual yield of the security.",
                },
                {
                    name: "redemption",
                    detail: "The redemption amount per 100 face value, or par.",
                },
                {
                    name: "frequency",
                    detail: "The number of interest or coupon payments per year (1, 2, or 4).",
                },
                {
                    name: "day_count_convention",
                    detail: "An indicator of what day count method to use.",
                },
            ],
        },
        PRICEDISC: {
            d: "Calculates the price of a discount (non-interest-bearing) security, based on expected yield.",
            a: "Price of a discount security.",
            p: [
                {
                    name: "settlement",
                    detail:
                        "The settlement date of the security, the date after issuance when the security is delivered to the buyer.",
                },
                {
                    name: "maturity",
                    detail: "The maturity or end date of the security, when it can be redeemed at face, or par value.",
                },
                {
                    name: "discount",
                    detail: "The discount rate of the security at time of purchase.",
                },
                {
                    name: "redemption",
                    detail: "The redemption amount per 100 face value, or par.",
                },
                {
                    name: "day_count_convention",
                    detail: "An indicator of what day count method to use.",
                },
            ],
        },
        PRICEMAT: {
            d: "Calculates the price of a security paying interest at maturity, based on expected yield.",
            a: "Price of security paying interest at maturity.",
            p: [
                {
                    name: "settlement",
                    detail:
                        "The settlement date of the security, the date after issuance when the security is delivered to the buyer.",
                },
                {
                    name: "maturity",
                    detail: "The maturity or end date of the security, when it can be redeemed at face, or par value.",
                },
                {
                    name: "issue",
                    detail: "The date the security was initially issued.",
                },
                {
                    name: "rate",
                    detail: "The annualized rate of interest.",
                },
                {
                    name: "yield",
                    detail: "The expected annual yield of the security.",
                },
                {
                    name: "day_count_convention",
                    detail: "An indicator of what day count method to use.",
                },
            ],
        },
        RECEIVED: {
            d:
                "Calculates the amount received at maturity for an investment in fixed-income securities purchased on a given date.",
            a: "Amount received at maturity for a security.",
            p: [
                {
                    name: "settlement",
                    detail:
                        "The settlement date of the security, the date after issuance when the security is delivered to the buyer.",
                },
                {
                    name: "maturity",
                    detail: "The maturity or end date of the security, when it can be redeemed at face, or par value.",
                },
                {
                    name: "investment",
                    detail: "The amount invested (irrespective of face value of each security).",
                },
                {
                    name: "discount",
                    detail: "The discount rate of the security invested in.",
                },
                {
                    name: "day_count_convention",
                    detail: "An indicator of what day count method to use.",
                },
            ],
        },
        DISC: {
            d: "Calculates the discount rate of a security based on price.",
            a: "The discount rate of a security based on price.",
            p: [
                {
                    name: "settlement",
                    detail:
                        "The settlement date of the security, the date after issuance when the security is delivered to the buyer.",
                },
                {
                    name: "maturity",
                    detail: "The maturity or end date of the security, when it can be redeemed at face, or par value.",
                },
                {
                    name: "price",
                    detail: "The price at which the security is bought per 100 face value.",
                },
                {
                    name: "redemption",
                    detail: "The redemption amount per 100 face value, or par.",
                },
                {
                    name: "day_count_convention",
                    detail: "An indicator of what day count method to use.",
                },
            ],
        },
        NPER: {
            d:
                "Calculates the number of payment periods for an investment based on constant-amount periodic payments and a constant interest rate.",
            a: "Number of payment periods for an investment.",
            p: [
                {
                    name: "rate",
                    detail: "The interest rate.",
                },
                {
                    name: "payment_amount",
                    detail: "The amount of each payment made.",
                },
                {
                    name: "present_value",
                    detail: "The current value of the annuity.",
                },
                {
                    name: "future_value",
                    detail: "The future value remaining after the final payment has been made.",
                },
                {
                    name: "end_or_beginning",
                    detail: "Whether payments are due at the end (`0`) or beginning (`1`) of each period.",
                },
            ],
        },
        SLN: {
            d: "Calculates the depreciation of an asset for one period using the straight-line method.",
            a: "Depreciation of asset using the straight-line method.",
            p: [
                {
                    name: "cost",
                    detail: "The initial cost of the asset.",
                },
                {
                    name: "salvage",
                    detail: "The value of the asset at the end of depreciation.",
                },
                {
                    name: "life",
                    detail: "The number of periods over which the asset is depreciated.",
                },
            ],
        },
        DURATION: {
            d:
                "Calculates the number of compounding periods required for an investment of a specified present value appreciating at a given rate to reach a target value.",
            a: "Number of periods for an investment to reach a value.",
            p: [
                {
                    name: "settlement",
                    detail:
                        "The settlement date of the security, the date after issuance when the security is delivered to the buyer.",
                },
                {
                    name: "maturity",
                    detail: "The maturity or end date of the security, when it can be redeemed at face, or par value.",
                },
                {
                    name: "rate",
                    detail: "The annualized rate of interest.",
                },
                {
                    name: "yield",
                    detail: "The expected annual yield of the security.",
                },
                {
                    name: "frequency",
                    detail: "The number of interest or coupon payments per year (1, 2, or 4).",
                },
                {
                    name: "day_count_convention",
                    detail: "An indicator of what day count method to use.",
                },
            ],
        },
        MDURATION: {
            d:
                "Calculates the modified Macaulay duration of a security paying periodic interest, such as a US Treasury Bond, based on expected yield.",
            a: "Modified Macaulay duration.",
            p: [
                {
                    name: "settlement",
                    detail:
                        "The settlement date of the security, the date after issuance when the security is delivered to the buyer.",
                },
                {
                    name: "maturity",
                    detail: "The maturity or end date of the security, when it can be redeemed at face, or par value.",
                },
                {
                    name: "rate",
                    detail: "The annualized rate of interest.",
                },
                {
                    name: "yield",
                    detail: "The expected annual yield of the security.",
                },
                {
                    name: "frequency",
                    detail: "The number of interest or coupon payments per year (1, 2, or 4).",
                },
                {
                    name: "day_count_convention",
                    detail: "An indicator of what day count method to use.",
                },
            ],
        },
        BIN2DEC: {
            d: "Converts a signed binary number to decimal format.",
            a: "Converts a signed binary number to decimal format.",
            p: [
                {
                    name: "signed_binary_number",
                    detail: "The signed 10-bit binary value to be converted to decimal, provided as a string.",
                },
            ],
        },
        BIN2HEX: {
            d: "Converts a signed binary number to signed hexadecimal format.",
            a: "Converts a binary number to hexadecimal.",
            p: [
                {
                    name: "signed_binary_number",
                    detail:
                        "The signed 10-bit binary value to be converted to signed hexademical, provided as a string.",
                },
                {
                    name: "significant_digits",
                    detail: "The number of significant digits to ensure in the result.",
                },
            ],
        },
        BIN2OCT: {
            d: "Converts a signed binary number to signed octal format.",
            a: "Converts a binary number to octal.",
            p: [
                {
                    name: "signed_binary_number",
                    detail: "The signed 10-bit binary value to be converted to signed octal, provided as a string.",
                },
                {
                    name: "significant_digits",
                    detail: "The number of significant digits to ensure in the result.",
                },
            ],
        },
        DEC2BIN: {
            d: "Converts a decimal number to signed binary format.",
            a: "Converts a decimal number to signed binary format.",
            p: [
                {
                    name: "decimal_number",
                    detail: "The decimal value to be converted to signed binary, provided as a string.",
                },
                {
                    name: "significant_digits",
                    detail: "The number of significant digits to ensure in the result.",
                },
            ],
        },
        DEC2HEX: {
            d: "Converts a decimal number to signed hexadecimal format.",
            a: "Converts a decimal number to hexadecimal.",
            p: [
                {
                    name: "decimal_number",
                    detail: "The decimal value to be converted to signed hexadecimal, provided as a string.",
                },
                {
                    name: "significant_digits",
                    detail: "The number of significant digits to ensure in the result.",
                },
            ],
        },
        DEC2OCT: {
            d: "Converts a decimal number to signed octal format.",
            a: "Converts a decimal number to signed octal format.",
            p: [
                {
                    name: "decimal_number",
                    detail: "The decimal value to be converted to signed octal, provided as a string.",
                },
                {
                    name: "significant_digits",
                    detail: "The number of significant digits to ensure in the result.",
                },
            ],
        },
        HEX2BIN: {
            d: "Converts a signed hexadecimal number to signed binary format.",
            a: "Converts a hexadecimal number to binary.",
            p: [
                {
                    name: "signed_hexadecimal_number",
                    detail:
                        "The signed 40-bit hexadecimal value to be converted to signed binary, provided as a string.",
                },
                {
                    name: "significant_digits",
                    detail: "The number of significant digits to ensure in the result.",
                },
            ],
        },
        HEX2DEC: {
            d: "Converts a signed hexadecimal number to decimal format.",
            a: "Converts a hexadecimal number to decimal.",
            p: [
                {
                    name: "signed_hexadecimal_number",
                    detail: "The signed 40-bit hexadecimal value to be converted to decimal, provided as a string.",
                },
            ],
        },
        HEX2OCT: {
            d: "Converts a signed hexadecimal number to signed octal format.",
            a: "Converts a hexadecimal number to octal.",
            p: [
                {
                    name: "signed_hexadecimal_number",
                    detail:
                        "The signed 40-bit hexadecimal value to be converted to signed octal, provided as a string.",
                },
                {
                    name: "significant_digits",
                    detail: "The number of significant digits to ensure in the result.",
                },
            ],
        },
        OCT2BIN: {
            d: "Converts a signed octal number to signed binary format.",
            a: "Converts an octal number to binary.",
            p: [
                {
                    name: "signed_octal_number",
                    detail: "The signed 30-bit octal value to be converted to signed binary, provided as a string.",
                },
                {
                    name: "significant_digits",
                    detail: "The number of significant digits to ensure in the result.",
                },
            ],
        },
        OCT2DEC: {
            d: "Converts a signed octal number to decimal format.",
            a: "Converts a signed octal number to decimal format.",
            p: [
                {
                    name: "signed_octal_number",
                    detail: "The signed 30-bit octal value to be converted to decimal, provided as a string.",
                },
            ],
        },
        OCT2HEX: {
            d: "Converts a signed octal number to signed hexadecimal format.",
            a: "Converts an octal number to hexadecimal.",
            p: [
                {
                    name: "signed_octal_number",
                    detail:
                        "The signed 30-bit octal value to be converted to signed hexadecimal, provided as a string.",
                },
                {
                    name: "significant_digits",
                    detail: "The number of significant digits to ensure in the result.",
                },
            ],
        },
        COMPLEX: {
            d: "Creates a complex number given real and imaginary coefficients.",
            a: "Creates a complex number.",
            p: [
                {
                    name: "real_part",
                    detail: "The real coefficient.",
                },
                {
                    name: "imaginary_part",
                    detail: "The imaginary coefficient.",
                },
                {
                    name: "suffix",
                    detail:
                        "The suffix for the imaginary coefficient, can only be 'i' or 'j'. If omitted, 'i' will be used.",
                },
            ],
        },
        IMREAL: {
            d: "Returns the real coefficient of a complex number.",
            a: "The real coefficient of a complex number.",
            p: [
                {
                    name: "complex_number",
                    detail: "The complex number, in the a+bi or a+bj format.",
                },
            ],
        },
        IMAGINARY: {
            d: "Returns the imaginary coefficient of a complex number.",
            a: "The imaginary coefficient of a complex number.",
            p: [
                {
                    name: "complex_number",
                    detail: "The complex number, in the a+bi or a+bj format.",
                },
            ],
        },
        IMCONJUGATE: {
            d: "Returns the complex conjugate of a number.",
            a: "The complex conjugate of a number.",
            p: [
                {
                    name: "number",
                    detail: "The complex number to calculate the conjugate for.",
                },
            ],
        },
        IMABS: {
            d: "Returns absolute value (or modulus) of a complex number.",
            a: "The absolute value of a complex number.",
            p: [
                {
                    name: "number",
                    detail: "The complex number to calculate the absolute value of.",
                },
            ],
        },
        DELTA: {
            d: "Compare two numeric values, returning 1 if they're equal.",
            a: "Compare two numeric values.",
            p: [
                {
                    name: "number1",
                    detail: "The first number to compare.",
                },
                {
                    name: "number2",
                    detail: "The second number to compare.",
                },
            ],
        },
        IMSUM: {
            d: "Returns the sum of a series of complex numbers.",
            a: "Sum of a series of complex numbers.",
            p: [
                {
                    name: "value1",
                    detail: "The first complex number or range to add together.",
                },
                {
                    name: "value2",
                    detail: "Additional complex numbers or ranges to add to `value1`.",
                },
            ],
        },
        IMSUB: {
            d: "Returns the difference between two complex numbers.",
            a: "The difference between two complex numbers.",
            p: [
                {
                    name: "first_number",
                    detail: "The complex number to subtract second_number from.",
                },
                {
                    name: "second_number",
                    detail: "The complex number to subtract from first_number.",
                },
            ],
        },
        IMPRODUCT: {
            d: "Returns the result of multiplying a series of complex numbers together.",
            a: "Result of multiplying a series of complex numbers together.",
            p: [
                {
                    name: "factor1",
                    detail: "The first number or range to calculate for the product.",
                },
                {
                    name: "factor2",
                    detail: "Additional complex numbers or ranges to calculate for the product.",
                },
            ],
        },
        IMDIV: {
            d: "Returns one complex number divided by another.",
            a: "One complex number divided by another.",
            p: [
                {
                    name: "dividend",
                    detail: "The complex number to be divided.",
                },
                {
                    name: "divisor",
                    detail: "The complex number to divide by.",
                },
            ],
        },
        NOT: {
            d: "Returns the opposite of a logical value - `NOT(TRUE)` returns `FALSE`; `NOT(FALSE)` returns `TRUE`.",
            a: "Returns opposite of provided logical value.",
            p: [
                {
                    name: "logical_expression",
                    detail:
                        "An expression or reference to a cell holding an expression that represents some logical value.",
                },
            ],
        },
        TRUE: {
            d: "Returns the logical value `TRUE`.",
            a: "Logical value `true`.",
            p: [],
        },
        FALSE: {
            d: "Returns the logical value `FALSE`.",
            a: "Logical value `false`.",
            p: [],
        },
        AND: {
            d:
                "Returns true if all of the provided arguments are logically true, and false if any of the provided arguments are logically false.",
            a: "Logical `and` operator.",
            p: [
                {
                    name: "logical_expression1",
                    detail:
                        "An expression or reference to a cell containing an expression that represents some logical value, i.e. `TRUE` or `FALSE`, or an expression that can be coerced to a logical value.",
                },
                {
                    name: "logical_expression2",
                    detail: "More expressions that represent logical values.",
                },
            ],
        },
        IFERROR: {
            d:
                "Returns the first argument if it is not an error value, otherwise returns the second argument if present, or a blank if the second argument is absent.",
            a: "Value if it is not an error, otherwise 2nd argument.",
            p: [
                {
                    name: "value",
                    detail: "The value to return if `value` itself is not an error.",
                },
                {
                    name: "value_if_error",
                    detail: "The value the function returns if `value` is an error.",
                },
            ],
        },
        IF: {
            d: "Returns one value if a logical expression is `TRUE` and another if it is `FALSE`.",
            a: "Returns value depending on logical expression.",
            p: [
                {
                    name: "logical_expression",
                    detail:
                        "An expression or reference to a cell containing an expression that represents some logical value, i.e. `TRUE` or `FALSE`.",
                },
                {
                    name: "value_if_true",
                    detail: "The value the function returns if `logical_expression` is `TRUE`.",
                },
                {
                    name: "value_if_false",
                    detail: "The value the function returns if `logical_expression` is `FALSE`.",
                },
            ],
        },
        OR: {
            d:
                "Returns true if any of the provided arguments are logically true, and false if all of the provided arguments are logically false.",
            a: "Logical `or` operator.",
            p: [
                {
                    name: "logical_expression1",
                    detail:
                        "An expression or reference to a cell containing an expression that represents some logical value, i.e. `TRUE` or `FALSE`, or an expression that can be coerced to a logical value.",
                },
                {
                    name: "logical_expression2",
                    detail: "More expressions that evaluate to logical values.",
                },
            ],
        },
        NE: {
            d:
                "Returns `TRUE` if two specified values are not equal and `FALSE` otherwise. Equivalent to the `!=` operator.",
            a: "Not equal.",
            p: [
                {
                    name: "value1",
                    detail: "The first value.",
                },
                {
                    name: "value2",
                    detail: "The value to test against `value1` for inequality.",
                },
            ],
        },
        EQ: {
            d:
                "Returns `TRUE` if two specified values are equal and `FALSE` otherwise. Equivalent to the `==` operator.",
            a: "Equal.",
            p: [
                {
                    name: "value1",
                    detail: "The first value.",
                },
                {
                    name: "value2",
                    detail: "The value to test against `value1` for equality.",
                },
            ],
        },
        GT: {
            d:
                "Returns `TRUE` if the first argument is strictly greater than the second, and `FALSE` otherwise. Equivalent to the `>` operator.",
            a: "Strictly greater than.",
            p: [
                {
                    name: "value1",
                    detail: "The value to test as being greater than `value2`.",
                },
                {
                    name: "value2",
                    detail: "The second value.",
                },
            ],
        },
        GTE: {
            d:
                "Returns `TRUE` if the first argument is greater than or equal to the second, and `FALSE` otherwise. Equivalent to the `>=` operator.",
            a: "Greater than or equal to.",
            p: [
                {
                    name: "value1",
                    detail: "The value to test as being greater than or equal to `value2`.",
                },
                {
                    name: "value2",
                    detail: "The second value.",
                },
            ],
        },
        LT: {
            d:
                "Returns `TRUE` if the first argument is strictly less than the second, and `FALSE` otherwise. Equivalent to the `<` operator.",
            a: "Less than.",
            p: [
                {
                    name: "value1",
                    detail: "The value to test as being less than `value2`.",
                },
                {
                    name: "value2",
                    detail: "The second value.",
                },
            ],
        },
        LTE: {
            d:
                "Returns `TRUE` if the first argument is less than or equal to the second, and `FALSE` otherwise. Equivalent to the `<=` operator.",
            a: "Less than or equal to.",
            p: [
                {
                    name: "value1",
                    detail: "The value to test as being less than or equal to `value2`.",
                },
                {
                    name: "value2",
                    detail: "The second value.",
                },
            ],
        },
        ADD: {
            d: "Returns the sum of two numbers. Equivalent to the `+` operator.",
            a: "Sum of two numbers",
            p: [
                {
                    name: "value1",
                    detail: "The first addend.",
                },
                {
                    name: "value2",
                    detail: "The second addend.",
                },
            ],
        },
        MINUS: {
            d: "Returns the difference of two numbers. Equivalent to the `-` operator.",
            a: "Difference of two numbers",
            p: [
                {
                    name: "value1",
                    detail: "The minuend, or number to be subtracted from.",
                },
                {
                    name: "value2",
                    detail: "The subtrahend, or number to subtract from `value1`.",
                },
            ],
        },
        MULTIPLY: {
            d: "Returns the product of two numbers. Equivalent to the `*` operator.",
            a: "Product of two numbers",
            p: [
                {
                    name: "factor1",
                    detail: "The first multiplicand.",
                },
                {
                    name: "factor2",
                    detail: "The second multiplicand.",
                },
            ],
        },
        DIVIDE: {
            d: "Returns one number divided by another. Equivalent to the `/` operator.",
            a: "One number divided by another",
            p: [
                {
                    name: "dividend",
                    detail: "The number to be divided.",
                },
                {
                    name: "divisor",
                    detail: "The number to divide by.",
                },
            ],
        },
        CONCAT: {
            d: "Returns the concatenation of two values. Equivalent to the `&` operator.",
            a: "Concatenation of two values",
            p: [
                {
                    name: "value1",
                    detail: "The value to which `value2` will be appended.",
                },
                {
                    name: "value2",
                    detail: "The value to append to `value1`.",
                },
            ],
        },
        UNARY_PERCENT: {
            d: "Returns a value interpreted as a percentage; that is, `UNARY_PERCENT(100)` equals `1`.",
            a: "Value interpreted as a percentage.",
            p: [
                {
                    name: "percentage",
                    detail: "The value to interpret as a percentage.",
                },
            ],
        },
        CONCATENATE: {
            d: "Appends strings to one another.",
            a: "Appends strings to one another.",
            p: [
                {
                    name: "string1",
                    detail: "The initial string.",
                },
                {
                    name: "string2",
                    detail: "More strings to append in sequence.",
                },
            ],
        },
        CODE: {
            d: "Returns the numeric Unicode map value of the first character in the string provided.",
            a: "Numeric unicode map value of character.",
            p: [
                {
                    name: "string",
                    detail: "The string whose first character's Unicode map value will be returned.",
                },
            ],
        },
        CHAR: {
            d: "Convert a number into a character according to the current Unicode table.",
            a: "Gets character associated with number.",
            p: [
                {
                    name: "table_number",
                    detail: "The number of the character to look up from the current Unicode table in decimal format.",
                },
            ],
        },
        ARABIC: {
            d: "Computes the value of a Roman numeral.",
            a: "Computes the value of a roman numeral.",
            p: [
                {
                    name: "roman_numeral",
                    detail: "The Roman numeral to format, whose value must be between 1 and 3999, inclusive.",
                },
            ],
        },
        ROMAN: {
            d: "Formats a number in Roman numerals.",
            a: "Formats a number in Roman numerals.",
            p: [
                {
                    name: "number",
                    detail: "The number to format, between 1 and 3999, inclusive.",
                },
            ],
        },
        REGEXEXTRACT: {
            d: "Extracts matching substrings according to a regular expression.",
            a: "Extracts matching substrings with regular expression.",
            p: [
                {
                    name: "text",
                    detail: "The input text.",
                },
                {
                    name: "regular_expression",
                    detail: "The first part of `text` that matches this expression will be returned.",
                },
            ],
        },
        REGEXMATCH: {
            d: "Whether a piece of text matches a regular expression.",
            a: "Whether a piece of text matches regular expression.",
            p: [
                {
                    name: "text",
                    detail: "The text to be tested against the regular expression.",
                },
                {
                    name: "regular_expression",
                    detail: "The regular expression to test the text against.",
                },
            ],
        },
        REGEXREPLACE: {
            d: "Replaces part of a text string with a different text string using regular expressions.",
            a: "Replaces text with regular expressions.",
            p: [
                {
                    name: "text",
                    detail: "The text, a part of which will be replaced.",
                },
                {
                    name: "regular_expression",
                    detail: "The regular expression.  All matching instances in `text` will be replaced.",
                },
                {
                    name: "replacement",
                    detail: "The text which will be inserted into the original text.",
                },
            ],
        },
        T: {
            d: "Returns string arguments as text, or the empty string if the value is not text.",
            a: "String arguments as text.",
            p: [
                {
                    name: "value",
                    detail: "The argument to be converted to text.",
                },
            ],
        },
        FIXED: {
            d: "Formats a number with a fixed number of decimal places.",
            a: "Formats number with fixed number of decimal places.",
            p: [
                {
                    name: "number",
                    detail: "The number to format.",
                },
                {
                    name: "number_of_places",
                    detail: "The number of decimal places to display in the result.",
                },
                {
                    name: "suppress_separator",
                    detail:
                        "Whether or not to suppress the thousands separator used in some locales (e.g. `1,000` becomes `1000`). Separators will be present if this value is 0 or omitted, and absent otherwise.",
                },
            ],
        },
        FIND: {
            d:
                "Returns the position at which a string is first found within text where the capitalization of letters matters. Returns `#VALUE!` if the string is not found.",
            a: "First position of string found in text, case-sensitive.",
            p: [
                {
                    name: "search_for",
                    detail: "The string to look for within `text_to_search`.",
                },
                {
                    name: "text_to_search",
                    detail: "The text to search for the first occurrence of `search_for`.",
                },
                {
                    name: "starting_at",
                    detail: "The character within `text_to_search` at which to start the search.",
                },
            ],
        },
        FINDB: {
            d: "Returns the position at which a string is first found within text counting each double-character as 2.",
            a: "Position at which a string is first found within text (binary).",
            p: [
                {
                    name: "search_for",
                    detail: "The string to look for within `text_to_search`.",
                },
                {
                    name: "text_to_search",
                    detail: "The text to search for the first occurrence of `search_for`.",
                },
                {
                    name: "starting_at",
                    detail: "The character within `text_to_search` at which to start the search.",
                },
            ],
        },
        JOIN: {
            d: "Concatenates the elements of one or more one-dimensional arrays using a specified delimiter.",
            a: "Concatenates elements of arrays with delimiter.",
            p: [
                {
                    name: "delimiter",
                    detail: "The character or string to place between each concatenated value.",
                },
                {
                    name: "value_or_array1",
                    detail: "The value or values to be appended using `delimiter`.",
                },
                {
                    name: "value_or_array2",
                    detail: "More values to be appended using `delimiter`.",
                },
            ],
        },
        LEFT: {
            d: "Returns a substring from the beginning of a specified string.",
            a: "Substring from beginning of specified string.",
            p: [
                {
                    name: "string",
                    detail: "The string from which the left portion will be returned.",
                },
                {
                    name: "number_of_characters",
                    detail: "The number of characters to return from the left side of `string`.",
                },
            ],
        },
        RIGHT: {
            d: "Returns a substring from the end of a specified string.",
            a: "A substring from the end of a specified string.",
            p: [
                {
                    name: "string",
                    detail: "The string from which the right portion will be returned.",
                },
                {
                    name: "number_of_characters",
                    detail: "The number of characters to return from the right side of `string`.",
                },
            ],
        },
        MID: {
            d: "Returns a segment of a string.",
            a: "A segment of a string.",
            p: [
                {
                    name: "string",
                    detail: "The string to extract a segment from.",
                },
                {
                    name: "starting_at",
                    detail:
                        "The index from the left of `string` from which to begin extracting. The first character in `string` has the index 1.",
                },
                {
                    name: "extract_length",
                    detail: "The length of the segment to extract.",
                },
            ],
        },
        LEN: {
            d: "Returns the length of a string.",
            a: "Length of a string.",
            p: [
                {
                    name: "text",
                    detail: "The string whose length will be returned.",
                },
            ],
        },
        LENB: {
            d: "Returns the length of a string in bytes.",
            a: "Length of a string in bytes.",
            p: [
                {
                    name: "text",
                    detail: "The string whose length will be returned.",
                },
            ],
        },
        LOWER: {
            d: "Converts a specified string to lowercase.",
            a: "Converts a specified string to lowercase.",
            p: [
                {
                    name: "text",
                    detail: "The string to convert to lowercase.",
                },
            ],
        },
        UPPER: {
            d: "Converts a specified string to uppercase.",
            a: "Converts a specified string to uppercase.",
            p: [
                {
                    name: "text",
                    detail: "The string to convert to uppercase.",
                },
            ],
        },
        EXACT: {
            d: "Tests whether two strings are identical.",
            a: "Tests whether two strings are identical.",
            p: [
                {
                    name: "string1",
                    detail: "The first string to compare",
                },
                {
                    name: "string2",
                    detail: "The second string to compare",
                },
            ],
        },
        REPLACE: {
            d: "Replaces part of a text string with a different text string.",
            a: "Replaces part of a text string with different text.",
            p: [
                {
                    name: "text",
                    detail: "The text, a part of which will be replaced.",
                },
                {
                    name: "position",
                    detail: "The position where the replacement will begin (starting from 1).",
                },
                {
                    name: "length",
                    detail: "The number of characters in the text to be replaced.",
                },
                {
                    name: "new_text",
                    detail: "The text which will be inserted into the original text.",
                },
            ],
        },
        REPT: {
            d: "Returns specified text repeated a number of times.",
            a: "Specified text repeated a number of times.",
            p: [
                {
                    name: "text_to_repeat",
                    detail: "The character or string to repeat.",
                },
                {
                    name: "number_of_repetitions",
                    detail: "The number of times `text_to_repeat` should appear in the value returned.",
                },
            ],
        },
        SEARCH: {
            d:
                "Returns the position at which a string is first found within text and ignores capitalization of letters. Returns `#VALUE!` if the string is not found.",
            a: "First position of string found in text, ignoring case.",
            p: [
                {
                    name: "search_for",
                    detail: "The string to look for within `text_to_search`.",
                },
                {
                    name: "text_to_search",
                    detail: "The text to search for the first occurrence of `search_for`.",
                },
                {
                    name: "starting_at",
                    detail: "The character within `text_to_search` at which to start the search.",
                },
            ],
        },
        SUBSTITUTE: {
            d: "Replaces existing text with new text in a string.",
            a: "Replaces existing text with new text in a string.",
            p: [
                {
                    name: "text_to_search",
                    detail: "The text within which to search and replace.",
                },
                {
                    name: "search_for",
                    detail: "The string to search for within `text_to_search`.",
                },
                {
                    name: "replace_with",
                    detail: "The string that will replace `search_for`.",
                },
                {
                    name: "occurrence_number",
                    detail:
                        "The instance of `search_for` within `text_to_search` to replace with `replace_with`. By default, all occurrences of `search_for` are replaced; however, if `occurrence_number` is specified, only the indicated instance of `search_for` is replaced.",
                },
            ],
        },
        CLEAN: {
            d: "Returns the text with the non-printable ASCII characters removed.",
            a: "Removes non-printable characters from a piece of text.",
            p: [
                {
                    name: "text",
                    detail: "The text whose non-printable characters are to be removed.",
                },
            ],
        },
        TEXT: {
            d: "Converts a number into text according to a specified format.",
            a: "Formats a number into text.",
            p: [
                {
                    name: "number",
                    detail: "The number, date, or time to format.",
                },
                {
                    name: "format",
                    detail: "The pattern by which to format the number, enclosed in quotation marks.",
                },
            ],
        },
        TRIM: {
            d: "Removes leading, trailing, and repeated spaces in text.",
            a: "Removes space characters.",
            p: [
                {
                    name: "text",
                    detail: "The text or reference to a cell containing text to be trimmed.",
                },
            ],
        },
        VALUE: {
            d:
                "Converts a string in any of the date, time or number formats that Google Sheets understands into a number.",
            a: "Converts a date/time/number string into a number.",
            p: [
                {
                    name: "text",
                    detail: "The string containing the value to be converted.",
                },
            ],
        },
        PROPER: {
            d: "Capitalizes each word in a specified string.",
            a: "Capitalizes each word in a specified string.",
            p: [
                {
                    name: "text_to_capitalize",
                    detail:
                        "The text which will be returned with the first letter of each word in uppercase and all other letters in lowercase.",
                },
            ],
        },
        CONVERT: {
            d: "Converts a numeric value to a different unit of measure.",
            a: "Unit conversion for numbers.",
            p: [
                {
                    name: "value",
                    detail: "The numeric value in `start_unit` to convert to `end_unit`.",
                },
                {
                    name: "start_unit",
                    detail: "The starting unit, the unit currently assigned to `value`.",
                },
                {
                    name: "end_unit",
                    detail: "The unit of measure into which to convert the argument, `value`.",
                },
            ],
        },
        SUMX2MY2: {
            d: "Calculates the sum of the differences of the squares of values in two arrays.",
            a: "Sum of the differences of squares.",
            p: [
                {
                    name: "array_x",
                    detail:
                        "The array or range of values whose squares will be reduced by the squares of corresponding entries in `array_y` and added together.",
                },
                {
                    name: "array_y",
                    detail:
                        "The array or range of values whose squares will be subtracted from the squares of corresponding entries in `array_x` and added together.",
                },
            ],
        },
        SUMX2PY2: {
            d: "Calculates the sum of the sums of the squares of values in two arrays.",
            a: "Sum of the sums of squares.",
            p: [
                {
                    name: "array_x",
                    detail:
                        "The array or range of values whose squares will be added to the squares of corresponding entries in `array_y` and added together.",
                },
                {
                    name: "array_y",
                    detail:
                        "The array or range of values whose squares will be added to the squares of corresponding entries in `array_x` and added together.",
                },
            ],
        },
        SUMXMY2: {
            d: "Calculates the sum of the squares of differences of values in two arrays.",
            a: "Sum of the squares of differences.",
            p: [
                {
                    name: "array_x",
                    detail:
                        "The array or range of values that will be reduced by corresponding entries in `array_y`, squared, and added together.",
                },
                {
                    name: "array_y",
                    detail:
                        "The array or range of values that will be subtracted from corresponding entries in `array_x`, the result squared, and all such results added together.",
                },
            ],
        },
        TRANSPOSE: {
            d: "Transposes the rows and columns of an array or range of cells.",
            a: "Transposes the rows and columns of an array.",
            p: [
                {
                    name: "array_or_range",
                    detail: "The array or range whose rows and columns will be swapped.",
                },
            ],
        },
        TREND: {
            d:
                "Given partial data about a linear trend, fits an ideal linear trend using the least squares method and/or predicts further values.",
            a: "Fits points to linear trend derived via least-squares.",
            p: [
                {
                    name: "known_data_y",
                    detail:
                        "The array or range containing dependent (y) values that are already known, used to curve fit an ideal linear trend.",
                },
                {
                    name: "known_data_x",
                    detail: "The values of the independent variable(s) corresponding with `known_data_y`.",
                },
                {
                    name: "new_data_x",
                    detail: "The data points to return the `y` values for on the ideal curve fit.",
                },
                {
                    name: "b",
                    detail:
                        "Given a general linear form of `y = m*x+b` for a curve fit, calculates `b` if `TRUE` or forces `b` to be `0` and only calculates the `m` values if `FALSE`, i.e. forces the curve fit to pass through the origin.",
                },
            ],
        },
        FREQUENCY: {
            d: "Calculates the frequency distribution of a one-column array into specified classes.",
            a: "The frequency distribution of array.",
            p: [
                {
                    name: "data",
                    detail: "The array or range containing the values to be counted.",
                },
                {
                    name: "classes",
                    detail: "The array or range containing the set of classes.",
                },
            ],
        },
        GROWTH: {
            d:
                "Given partial data about an exponential growth trend, fits an ideal exponential growth trend and/or predicts further values.",
            a: "Fits points to exponential growth trend.",
            p: [
                {
                    name: "known_data_y",
                    detail:
                        "The array or range containing dependent (y) values that are already known, used to curve fit an ideal exponential growth curve.",
                },
                {
                    name: "known_data_x",
                    detail: "The values of the independent variable(s) corresponding with `known_data_y`.",
                },
                {
                    name: "new_data_x",
                    detail: "The data points to return the `y` values for on the ideal curve fit.",
                },
                {
                    name: "b",
                    detail:
                        "Given a general exponential form of `y = b*m^x` for a curve fit, calculates `b` if `TRUE` or forces `b` to be `1` and only calculates the `m` values if `FALSE`.",
                },
            ],
        },
        LINEST: {
            d:
                "Given partial data about a linear trend, calculates various parameters about the ideal linear trend using the least-squares method.",
            a: "Best-fit linear trend via least-squares.",
            p: [
                {
                    name: "known_data_y",
                    detail:
                        "The array or range containing dependent (y) values that are already known, used to curve fit an ideal linear trend.",
                },
                {
                    name: "known_data_x",
                    detail: "The values of the independent variable(s) corresponding with `known_data_y`.",
                },
                {
                    name: "calculate_b",
                    detail:
                        "Given a linear form of `y = m*x+b`, calculates the y-intercept (`b`) if `TRUE`. Otherwise, forces `b` to be `0` and only calculates the `m` values if `FALSE`, i.e. forces the curve fit to pass through the origin.",
                },
                {
                    name: "verbose",
                    detail:
                        "A flag specifying whether to return additional regression statistics or only the linear coefficients and the y-intercept (default).",
                },
            ],
        },
        LOGEST: {
            d:
                "Given partial data about an exponential growth curve, calculates various parameters about the best fit ideal exponential growth curve.",
            a: "Best-fit exponential growth curve.",
            p: [
                {
                    name: "known_data_y",
                    detail:
                        "The array or range containing dependent (y) values that are already known, used to curve fit an ideal exponential growth curve.",
                },
                {
                    name: "known_data_x",
                    detail: "The values of the independent variable(s) corresponding with `known_data_y`.",
                },
                {
                    name: "b",
                    detail:
                        "Given a general exponential form of `y = b*m^x` for a curve fit, calculates `b` if `TRUE` or forces `b` to be `1` and only calculates the `m` values if `FALSE`.",
                },
                {
                    name: "verbose",
                    detail:
                        "A flag specifying whether to return additional regression statistics or only the calculated coefficient and exponents.",
                },
            ],
        },
        MDETERM: {
            d: "Returns the matrix determinant of a square matrix specified as an array or range.",
            a: "Matrix determinant of a square matrix.",
            p: [
                {
                    name: "square_matrix",
                    detail:
                        "An array or range with an equal number of rows and columns representing a matrix whose determinant will be calculated.",
                },
            ],
        },
        MINVERSE: {
            d: "Returns the multiplicative inverse of a square matrix specified as an array or range.",
            a: "Multiplicative inverse of square matrix.",
            p: [
                {
                    name: "square_matrix",
                    detail:
                        "An array or range with an equal number of rows and columns representing a matrix whose multiplicative inverse will be calculated.",
                },
            ],
        },
        MMULT: {
            d: "Calculates the matrix product of two matrices specified as arrays or ranges.",
            a: "The matrix product of two matrices.",
            p: [
                {
                    name: "matrix1",
                    detail:
                        "The first matrix in the matrix multiplication operation, represented as an array or range.",
                },
                {
                    name: "matrix2",
                    detail:
                        "The second matrix in the matrix multiplication operation, represented as an array or range.",
                },
            ],
        },
        SUMPRODUCT: {
            d: "Calculates the sum of the products of corresponding entries in two equal-sized arrays or ranges.",
            a: "Sum of products of elements in two arrays.",
            p: [
                {
                    name: "array1",
                    detail:
                        "The first array or range whose entries will be multiplied with corresponding entries in the second such array or range.",
                },
                {
                    name: "array2",
                    detail:
                        "The second array or range whose entries will be multiplied with corresponding entries in the first such array or range.",
                },
            ],
        },
        ISFORMULA: {
            d: "Checks whether a value is a formula.",
            a: "Whether a value is a formula.",
            p: [
                {
                    name: "cell",
                    detail: "The cell to be verified as containing a formula.",
                },
            ],
        },
        CELL: {
            d: "Returns the requested information about the specified cell.",
            a: "Gets information about a cell.",
            p: [
                {
                    name: "info_type",
                    detail: "The type of information requested (see article for available types)",
                },
                {
                    name: "reference",
                    detail: "The reference to the cell.",
                },
            ],
        },
        NA: {
            d: 'Returns the "value not available" error, `#N/A`.',
            a: "The `#N/A` error.",
            p: [],
        },
        ERROR_TYPE: {
            d: "Returns a number corresponding to the error value in a different cell.",
            a: "Error value of cell (as number).",
            p: [
                {
                    name: "reference",
                    detail:
                        "The cell to find the error number for although you can also provide the error value directly.",
                },
            ],
        },
        ISBLANK: {
            d: "Checks whether the referenced cell is empty.",
            a: "Whether the referenced cell is empty.",
            p: [
                {
                    name: "value",
                    detail: "Reference to the cell that will be checked for emptiness.",
                },
            ],
        },
        ISERR: {
            d: "Checks whether a value is an error other than `#N/A`.",
            a: "Whether a value is an error other than `#n/a`.",
            p: [
                {
                    name: "value",
                    detail: "The value to be verified as an error type other than `#N/A`.",
                },
            ],
        },
        ISERROR: {
            d: "Checks whether a value is an error.",
            a: "Whether a value is an error.",
            p: [
                {
                    name: "value",
                    detail: "The value to be verified as an error type.",
                },
            ],
        },
        ISLOGICAL: {
            d: "Checks whether a value is `TRUE` or `FALSE`.",
            a: "Whether a value is `true` or `false`.",
            p: [
                {
                    name: "value",
                    detail: "The value to be verified as a logical `TRUE` or `FALSE`.",
                },
            ],
        },
        ISNA: {
            d: "Checks whether a value is the error `#N/A`.",
            a: "Whether a value is the error `#n/a`.",
            p: [
                {
                    name: "value",
                    detail: "The value to be compared with the error value `#N/A`.",
                },
            ],
        },
        ISNONTEXT: {
            d: "Checks whether a value is non-textual.",
            a: "Whether a value is non-textual.",
            p: [
                {
                    name: "value",
                    detail: "The value to be checked.",
                },
            ],
        },
        ISNUMBER: {
            d: "Checks whether a value is a number.",
            a: "Whether a value is a number.",
            p: [
                {
                    name: "value",
                    detail: "The value to be verified as a number.",
                },
            ],
        },
        ISREF: {
            d: "Checks whether a value is a valid cell reference.",
            a: "Whether a value is a valid cell reference.",
            p: [
                {
                    name: "value",
                    detail: "The value to be verified as a cell reference.",
                },
            ],
        },
        ISTEXT: {
            d: "Checks whether a value is text.",
            a: "Whether a value is text.",
            p: [
                {
                    name: "value",
                    detail: "The value to be verified as text.",
                },
            ],
        },
        TYPE: {
            d: "Returns a number associated with the type of data passed into the function.",
            a: "Get the type of a value.",
            p: [
                {
                    name: "value",
                    detail: "The value whose type is to be determined.",
                },
            ],
        },
        N: {
            d: "Returns the argument provided as a number. Text is converted to 0 and errors are returned as-is.",
            a: "Argument provided as a number.",
            p: [
                {
                    name: "value",
                    detail: "The argument to be converted to a number.",
                },
            ],
        },
        TO_DATE: {
            d: "Converts a provided number to a date.",
            a: "Converts a provided number to a date.",
            p: [
                {
                    name: "value",
                    detail: "The argument or reference to a cell to be converted to a date.",
                },
            ],
        },
        TO_PURE_NUMBER: {
            d:
                "Converts a provided date/time, percentage, currency or other formatted numeric value to a pure number without formatting.",
            a: "Converts any numeric value to a pure number.",
            p: [
                {
                    name: "value",
                    detail: "The argument or reference to a cell to be converted to a pure number.",
                },
            ],
        },
        TO_TEXT: {
            d: "Converts a provided numeric value to a text value.",
            a: "Converts a provided numeric value to a text value.",
            p: [
                {
                    name: "value",
                    detail: "The argument or reference to a cell to be converted to text.",
                },
            ],
        },
        TO_DOLLARS: {
            d: "Converts a provided number to a dollar value.",
            a: "Converts a provided number to a dollar value.",
            p: [
                {
                    name: "value",
                    detail: "The argument or reference to a cell to be converted to a dollar value.",
                },
            ],
        },
        TO_PERCENT: {
            d: "Converts a provided number to a percentage.",
            a: "Converts a provided number to a percentage.",
            p: [
                {
                    name: "value",
                    detail: "The argument or reference to a cell to be converted to a percentage.",
                },
            ],
        },
        DGET: {
            d: "Returns a single value from a database table-like array or range using a SQL-like query.",
            a: "Single value from a table-like range.",
            p: [
                {
                    name: "database",
                    detail:
                        "The array or range containing the data to consider, structured in such a way that the first row contains the labels for each column's values.",
                },
                {
                    name: "field",
                    detail: "Indicates which column in `database` contains the values to be extracted and operated on.",
                },
                {
                    name: "criteria",
                    detail:
                        "An array or range containing zero or more criteria to filter the `database` values by before operating.",
                },
            ],
        },
        DMAX: {
            d: "Returns the maximum value selected from a database table-like array or range using a SQL-like query.",
            a: "Maximum of values from a table-like range.",
            p: [
                {
                    name: "database",
                    detail:
                        "The array or range containing the data to consider, structured in such a way that the first row contains the labels for each column's values.",
                },
                {
                    name: "field",
                    detail: "Indicates which column in `database` contains the values to be extracted and operated on.",
                },
                {
                    name: "criteria",
                    detail:
                        "An array or range containing zero or more criteria to filter the `database` values by before operating.",
                },
            ],
        },
        DMIN: {
            d: "Returns the minimum value selected from a database table-like array or range using a SQL-like query.",
            a: "Minimum of values from a table-like range.",
            p: [
                {
                    name: "database",
                    detail:
                        "The array or range containing the data to consider, structured in such a way that the first row contains the labels for each column's values.",
                },
                {
                    name: "field",
                    detail: "Indicates which column in `database` contains the values to be extracted and operated on.",
                },
                {
                    name: "criteria",
                    detail:
                        "An array or range containing zero or more criteria to filter the `database` values by before operating.",
                },
            ],
        },
        DAVERAGE: {
            d:
                "Returns the average of a set of values selected from a database table-like array or range using a SQL-like query.",
            a: "Average of a set of values from a table-like range.",
            p: [
                {
                    name: "database",
                    detail:
                        "The array or range containing the data to consider, structured in such a way that the first row contains the labels for each column's values.",
                },
                {
                    name: "field",
                    detail: "Indicates which column in `database` contains the values to be extracted and operated on.",
                },
                {
                    name: "criteria",
                    detail:
                        "An array or range containing zero or more criteria to filter the `database` values by before operating.",
                },
            ],
        },
        DCOUNT: {
            d: "Counts numeric values selected from a database table-like array or range using a SQL-like query.",
            a: "Counts values from a table-like range.",
            p: [
                {
                    name: "database",
                    detail:
                        "The array or range containing the data to consider, structured in such a way that the first row contains the labels for each column's values.",
                },
                {
                    name: "field",
                    detail: "Indicates which column in `database` contains the values to be extracted and operated on.",
                },
                {
                    name: "criteria",
                    detail:
                        "An array or range containing zero or more criteria to filter the `database` values by before operating.",
                },
            ],
        },
        DCOUNTA: {
            d:
                "Counts values, including text, selected from a database table-like array or range using a SQL-like query.",
            a: "Counts values and text from a table-like range.",
            p: [
                {
                    name: "database",
                    detail:
                        "The array or range containing the data to consider, structured in such a way that the first row contains the labels for each column's values.",
                },
                {
                    name: "field",
                    detail: "Indicates which column in `database` contains the values to be extracted and operated on.",
                },
                {
                    name: "criteria",
                    detail:
                        "An array or range containing zero or more criteria to filter the `database` values by before operating.",
                },
            ],
        },
        DPRODUCT: {
            d:
                "Returns the product of values selected from a database table-like array or range using a SQL-like query.",
            a: "Product of values from a table-like range.",
            p: [
                {
                    name: "database",
                    detail:
                        "The array or range containing the data to consider, structured in such a way that the first row contains the labels for each column's values.",
                },
                {
                    name: "field",
                    detail: "Indicates which column in `database` contains the values to be extracted and operated on.",
                },
                {
                    name: "criteria",
                    detail:
                        "An array or range containing zero or more criteria to filter the `database` values by before operating.",
                },
            ],
        },
        DSTDEV: {
            d:
                "Returns the standard deviation of a population sample selected from a database table-like array or range using a SQL-like query.",
            a: "Standard deviation of population sample from table.",
            p: [
                {
                    name: "database",
                    detail:
                        "The array or range containing the data to consider, structured in such a way that the first row contains the labels for each column's values.",
                },
                {
                    name: "field",
                    detail: "Indicates which column in `database` contains the values to be extracted and operated on.",
                },
                {
                    name: "criteria",
                    detail:
                        "An array or range containing zero or more criteria to filter the `database` values by before operating.",
                },
            ],
        },
        DSTDEVP: {
            d:
                "Returns the standard deviation of an entire population selected from a database table-like array or range using a SQL-like query.",
            a: "Standard deviation of entire population from table.",
            p: [
                {
                    name: "database",
                    detail:
                        "The array or range containing the data to consider, structured in such a way that the first row contains the labels for each column's values.",
                },
                {
                    name: "field",
                    detail: "Indicates which column in `database` contains the values to be extracted and operated on.",
                },
                {
                    name: "criteria",
                    detail:
                        "An array or range containing zero or more criteria to filter the `database` values by before operating.",
                },
            ],
        },
        DSUM: {
            d: "Returns the sum of values selected from a database table-like array or range using a SQL-like query.",
            a: "Sum of values from a table-like range.",
            p: [
                {
                    name: "database",
                    detail:
                        "The array or range containing the data to consider, structured in such a way that the first row contains the labels for each column's values.",
                },
                {
                    name: "field",
                    detail: "Indicates which column in `database` contains the values to be extracted and operated on.",
                },
                {
                    name: "criteria",
                    detail:
                        "An array or range containing zero or more criteria to filter the `database` values by before operating.",
                },
            ],
        },
        DVAR: {
            d:
                "Returns the variance of a population sample selected from a database table-like array or range using a SQL-like query.",
            a: "Variance of population sample from table-like range.",
            p: [
                {
                    name: "database",
                    detail:
                        "The array or range containing the data to consider, structured in such a way that the first row contains the labels for each column's values.",
                },
                {
                    name: "field",
                    detail: "Indicates which column in `database` contains the values to be extracted and operated on.",
                },
                {
                    name: "criteria",
                    detail:
                        "An array or range containing zero or more criteria to filter the `database` values by before operating.",
                },
            ],
        },
        DVARP: {
            d:
                "Returns the variance of an entire population selected from a database table-like array or range using a SQL-like query.",
            a: "Variance of a population from a table-like range.",
            p: [
                {
                    name: "database",
                    detail:
                        "The array or range containing the data to consider, structured in such a way that the first row contains the labels for each column's values.",
                },
                {
                    name: "field",
                    detail: "Indicates which column in `database` contains the values to be extracted and operated on.",
                },
                {
                    name: "criteria",
                    detail:
                        "An array or range containing zero or more criteria to filter the `database` values by before operating.",
                },
            ],
        },
        AGE_BY_IDCARD: {
            d: "Calculate the age based on the Chinese ID number. Support 15 or 18",
            a: "Get age based on ID number.",
            p: [
                {
                    name: "ID number",
                    detail: "15-digit or 18-digit ID number or range.",
                },
                {
                    name: "Deadline",
                    detail: "The deadline or range of age calculation. The default is the current day.",
                },
            ],
        },
        SEX_BY_IDCARD: {
            d: "Calculate gender based on Chinese ID number. Support 15 or 18",
            a: "Get gender based on ID number.",
            p: [
                {
                    name: "ID number",
                    detail: "15-digit or 18-digit ID number or range.",
                },
            ],
        },
        BIRTHDAY_BY_IDCARD: {
            d: "Calculate the birthday based on the Chinese ID number. Support 15 or 18",
            a: "Get the birthday based on the ID number.",
            p: [
                {
                    name: "ID number",
                    detail: "15-digit or 18-digit ID number or range.",
                },
                {
                    name: "Birthday format",
                    detail: "Date type, default:0:[1900/01/01], 1:[1900-01-01], 2:[1900年1月1日]",
                },
            ],
        },
        PROVINCE_BY_IDCARD: {
            d: "Calculate the province of birthplace based on the Chinese ID number. Support 15 or 18",
            a: "Get the province of birthplace based on the ID number.",
            p: [
                {
                    name: "ID number",
                    detail: "15-digit or 18-digit ID number or range.",
                },
            ],
        },
        CITY_BY_IDCARD: {
            d: "Calculate the city of birthplace based on the Chinese ID number. Support 15 or 18",
            a: "Get the city of birthplace based on the ID number.",
            p: [
                {
                    name: "ID number",
                    detail: "15-digit or 18-digit ID number or range.",
                },
            ],
        },
        STAR_BY_IDCARD: {
            d: "Calculate the constellation based on the Chinese ID number. Support 15 or 18",
            a: "Get the constellation based on the ID number.",
            p: [
                {
                    name: "ID number",
                    detail: "15-digit or 18-digit ID number or range.",
                },
            ],
        },
        ANIMAL_BY_IDCARD: {
            d: "Calculate the zodiac (rat, ox, tiger, rabbit...) based on the Chinese ID number. Support 15 or 18",
            a: "Get the zodiac according to the ID number.",
            p: [
                {
                    name: "ID number",
                    detail: "15-digit or 18-digit ID number or range.",
                },
            ],
        },
        ISIDCARD: {
            d: "Verify that the format of the ID card is correct. Support 15 or 18",
            a: "Verify the correctness of the ID card format.",
            p: [
                {
                    name: "ID number",
                    detail: "15-digit or 18-digit ID number or range.",
                },
            ],
        },
        DM_TEXT_CUTWORD: {
            d: "Text segmentation. Split a series of words into a series of individual words",
            a: "Chinese text segmentation.",
            p: [
                {
                    name: "Text",
                    detail: "Any text that needs word segmentation.",
                },
                {
                    name: "Word segmentation mode",
                    detail: "The default is 0[precision mode], 1[full mode], 2[search engine mode].",
                },
            ],
        },
        DM_TEXT_TFIDF: {
            d: "Use tf-idf algorithm for keyword extraction. Identify keywords from a series of text",
            a: "tf-idf keyword recognition.",
            p: [
                {
                    name: "Text",
                    detail: "Any text that needs word segmentation.",
                },
                {
                    name: "Number of keywords",
                    detail: "The number of keywords returned by the algorithm, the default is 20",
                },
                {
                    name: "Corpus",
                    detail: "Select a corpus in a specific field, the default is 0[General], 1[Finance], 2[Medical]",
                },
            ],
        },
        DM_TEXT_TEXTRANK: {
            d: "Use TextRank algorithm to extract keywords. Identify keywords from a series of text",
            a: "TextRank keyword recognition.",
            p: [
                {
                    name: "Text",
                    detail: "Any text that needs word segmentation.",
                },
                {
                    name: "Number of keywords",
                    detail: "The number of keywords returned by the algorithm, the default is 20",
                },
                {
                    name: "Corpus",
                    detail: "Select a corpus in a specific field, the default is 0[General], 1[Finance], 2[Medical]",
                },
            ],
        },
        DATA_CN_STOCK_CLOSE: {
            d: "According to the stock code and date, return the corresponding stock closing price of A shares.",
            a: "Returns the closing price of stock.",
            p: [
                {
                    name: "Stock code",
                    detail: "6-digit stock code, required.",
                },
                {
                    name: "Date",
                    detail: "The trading day of the stock, the default is the latest trading day",
                },
                {
                    name: "Reversion and exclusion",
                    detail:
                        "Select the ex right restoration type of the stock, default to 0 [former reversion], 1 [original price], 2 [post reversion]",
                },
            ],
        },
        DATA_CN_STOCK_OPEN: {
            d: "According to the stock code and date, return the opening price of stock.",
            a: "Return the opening price of a shares.",
            p: [
                {
                    name: "Stock code",
                    detail: "6-digit stock code, required.",
                },
                {
                    name: "Date",
                    detail: "The trading day of the stock, the default is the latest trading day",
                },
                {
                    name: "Reversion and exclusion",
                    detail:
                        "Select the ex right restoration type of the stock, default to 0 [former reversion], 1 [original price], 2 [post reversion]",
                },
            ],
        },
        DATA_CN_STOCK_MAX: {
            d: "According to the stock code and date, return the highest price of stock.",
            a: "Return the highest price of stock.",
            p: [
                {
                    name: "Stock code",
                    detail: "6-digit stock code, required.",
                },
                {
                    name: "Date",
                    detail: "The trading day of the stock, the default is the latest trading day",
                },
                {
                    name: "Reversion and exclusion",
                    detail:
                        "Select the ex right restoration type of the stock, default to 0 [former reversion], 1 [original price], 2 [post reversion]",
                },
            ],
        },
        DATA_CN_STOCK_MIN: {
            d: "According to the stock code and date, return the lowest price of stock.",
            a: "Returns the lowest price of stock.",
            p: [
                {
                    name: "Stock code",
                    detail: "6-digit stock code, required.",
                },
                {
                    name: "Date",
                    detail: "The trading day of the stock, the default is the latest trading day",
                },
                {
                    name: "Reversion and exclusion",
                    detail:
                        "Select the ex right restoration type of the stock, default to 0 [former reversion], 1 [original price], 2 [post reversion]",
                },
            ],
        },
        DATA_CN_STOCK_VOLUMN: {
            d: "According to the stock code and date, return the corresponding stock trading volume of A shares.",
            a: "Returns the corresponding stock trading volume of A shares.",
            p: [
                {
                    name: "Stock code",
                    detail: "6-digit stock code, required.",
                },
                {
                    name: "Date",
                    detail: "The trading day of the stock, the default is the latest trading day",
                },
                {
                    name: "Reversion and exclusion",
                    detail:
                        "Select the ex right restoration type of the stock, default to 0 [former reversion], 1 [original price], 2 [post reversion]",
                },
            ],
        },
        DATA_CN_STOCK_AMOUNT: {
            d: "According to the stock code and date, return the corresponding stock turnover of A shares.",
            a: "Returns the corresponding stock turnover of A shares.",
            p: [
                {
                    name: "Stock code",
                    detail: "6-digit stock code, required.",
                },
                {
                    name: "Date",
                    detail: "The trading day of the stock, the default is the latest trading day",
                },
                {
                    name: "Reversion and exclusion",
                    detail:
                        "Select the ex right restoration type of the stock, default to 0 [former reversion], 1 [original price], 2 [post reversion]",
                },
            ],
        },
        ISDATE: {
            d: "Returns whether a value is a date.",
            a: "Whether a value is a date.",
            p: [
                {
                    name: "value",
                    detail: "The value to be verified as a date.",
                },
            ],
        },
        LINESPLINES: {
            d: "Generate sparklines embedded in the cell to describe the continuous trend of data",
            a: "Generate sparklines line chart",
            p: [
                {
                    name: "Range",
                    detail: "Range，Values can be calculated effectively, such as A1:A20, {1,2,3,4,5}, etc.",
                },
                {
                    name: "Line color",
                    detail:
                        "The line color of the line graph can be range A1, color table index value or specific color value. Set it to 0 or false to not display it. It supports regx, rgb, rgba, etc. Default #2ec7c9",
                },
                {
                    name: "Line thickness",
                    detail: "Line thickness of the line graph, the default is 1px",
                },
                {
                    name: "Auxiliary line",
                    detail:
                        "A horizontal line, which can be min, max, avg, median, range or custom value, default 0 none",
                },
                {
                    name: "Auxiliary line color",
                    detail: "Color setting of auxiliary line, same as line color configuration, default #000",
                },
                {
                    name: "Maximum mark",
                    detail:
                        "Identifies the maximum value of the line graph, the same line color configuration, default 0 does not display",
                },
                {
                    name: "Minimum mark",
                    detail:
                        "Identify the minimum value of the line graph, the same line color configuration, default 0 does not display",
                },
                {
                    name: "Mark size",
                    detail: "The maximum and minimum mark size settings, the default is 1.5",
                },
            ],
        },
        AREASPLINES: {
            d:
                "Generate sparklines embedded in the cell area chart, generally used to describe the continuous cumulative value trend of the data",
            a: "Generate sparklines area chart",
            p: [
                {
                    name: "Range",
                    detail: "Range，Values can be calculated effectively, such as A1:A20, {1,2,3,4,5}, etc.",
                },
                {
                    name: "Line color",
                    detail:
                        "The line color of the line graph can be range A1, color table index value or specific color value. Set it to 0 or false to not display it. It supports regx, rgb, rgba, etc. Default #2ec7c9",
                },
                {
                    name: "Fill color",
                    detail: "Form an area chart, the same line color configuration, default 0 does not display",
                },
                {
                    name: "Line thickness",
                    detail: "Line thickness of the line graph, the default is 1px",
                },
                {
                    name: "Auxiliary line",
                    detail:
                        "A horizontal line, which can be min, max, avg, median, range or custom value, default 0 none",
                },
                {
                    name: "Auxiliary line color",
                    detail: "Color setting of auxiliary line, same as line color configuration, default #000",
                },
            ],
        },
        COLUMNSPLINES: {
            d:
                "Generate sparklines embedded in the vertical histogram of cells, generally used to describe the size of discrete data",
            a: "Generate sparklines vertical histogram",
            p: [
                {
                    name: "Range",
                    detail: "Range，Values can be calculated effectively, such as A1:A20, {1,2,3,4,5}, etc.",
                },
                {
                    name: "Bar interval",
                    detail: "The distance between bars, the default is 1",
                },
                {
                    name: "Bar color",
                    detail:
                        "The line color of the line graph can be range A1, color table index value or specific color value. Set it to 0 or false to not display it. It supports regx, rgb, rgba, etc. Default #fc5c5c",
                },
                {
                    name: "Negative bar color",
                    detail:
                        "Negative bar color setting, representing the color of negative value, same as the bar color configuration, default #97b552",
                },
                {
                    name: "Max",
                    detail:
                        "The maximum value of the bar chart, used to standardize the length of the bar chart, the default is to automatically calculate false, auto, null",
                },
                {
                    name: "Color palette",
                    detail:
                        "The color palette can set the color of each bar individually, multiple settings can be set, and two formats are supported: 1 color such as #000, which means that the color of the first bar is black; 2 value range: color, such as -2:# 000 indicates that the bar with a value of -2 is black, 0:5:#000 indicates that the bar with a value of 0-5 is black, and the default is empty",
                },
            ],
        },
        STACKCOLUMNSPLINES: {
            d:
                "Generate sparklines, a cumulative vertical histogram embedded in a cell, generally used to describe the numerical size of multiple dimensions of discrete data",
            a: "Generate sparklines cumulative vertical histogram",
            p: [
                {
                    name: "Range",
                    detail: "Range，Values can be calculated effectively, such as A1:A20, {1,2,3,4,5}, etc.",
                },
                {
                    name: "Stack by column",
                    detail: "If you need to stack by row, set this item to false or 0, the default is 1",
                },
                {
                    name: "Bar interval",
                    detail: "The distance between bars, the default is 1",
                },
                {
                    name: "Max",
                    detail:
                        "The maximum value of the cumulative bar, used to regulate the length of the bar, the default is to automatically calculate false, auto, null",
                },
                {
                    name: "Color palette",
                    detail:
                        "The color palette can individually set the bar color of each dimension, which can be set to the range of A1:A10, etc. The default is #2ec7c9, #fc5c5c, #5ab1ef, #ffb980...",
                },
            ],
        },
        BARSPLINES: {
            d: "Generate sparklines embedded in the cell, generally used to describe the size of discrete data",
            a: "Generate sparklines horizontal bar graph",
            p: [
                {
                    name: "Range",
                    detail: "Range，Values can be calculated effectively, such as A1:A20, {1,2,3,4,5}, etc.",
                },
                {
                    name: "Bar interval",
                    detail: "The distance between bars, the default is 1",
                },
                {
                    name: "Bar color",
                    detail:
                        "The line color of the line graph can be range A1, color table index value or specific color value. Set it to 0 or false to not display it. It supports regx, rgb, rgba, etc. Default #fc5c5c",
                },
                {
                    name: "Negative bar color",
                    detail:
                        "Negative bar color setting, representing the color of negative value, same as the bar color configuration, default #97b552",
                },
                {
                    name: "Max",
                    detail:
                        "The maximum value of the bar chart, used to standardize the length of the bar chart, the default is to automatically calculate false, auto, null",
                },
                {
                    name: "Color palette",
                    detail:
                        "The color palette can set the color of each bar individually, multiple settings can be set, and two formats are supported: 1 color such as #000, which means that the color of the first bar is black; 2 value range: color, such as -2:# 000 indicates that the bar with a value of -2 is black, 0:5:#000 indicates that the bar with a value of 0-5 is black, and the default is empty",
                },
            ],
        },
        STACKBARSPLINES: {
            d:
                "Generate sparklines, a cumulative horizontal bar graph embedded in a cell, which is generally used to describe the numerical size of multiple dimensions of discrete data",
            a: "Generate sparklines cumulative horizontal bar graph",
            p: [
                {
                    name: "Range",
                    detail: "Range，Values can be calculated effectively, such as A1:A20, {1,2,3,4,5}, etc.",
                },
                {
                    name: "Stack by column",
                    detail: "If you need to stack by row, set this item to false or 0, the default is 1",
                },
                {
                    name: "Bar interval",
                    detail: "The distance between bars, the default is 1",
                },
                {
                    name: "Max",
                    detail:
                        "The maximum value of the cumulative bar, used to regulate the length of the bar, the default is to automatically calculate false, auto, null",
                },
                {
                    name: "Color palette",
                    detail:
                        "The color palette can individually set the bar color of each dimension, which can be set to the range of A1:A10, etc. The default is #2ec7c9, #fc5c5c, #5ab1ef, #ffb980...",
                },
            ],
        },
        DISCRETESPLINES: {
            d: "Generate sparklines embedded in the cell, generally used to describe the trend of discrete data",
            a: "Generate sparklines discrete graph",
            p: [
                {
                    name: "Range",
                    detail: "Range，Values can be calculated effectively, such as A1:A20, {1,2,3,4,5}, etc.",
                },
                {
                    name: "Segmentation threshold",
                    detail:
                        "Discrete graph column color distinction, for example: if the value is 0, blue is greater than 0, red is less than 0, and the default is 0",
                },
                {
                    name: "Above threshold color",
                    detail:
                        "The line color of the line graph can be range A1, color table index value or specific color value. Set it to 0 or false to not display it. It supports regx, rgb, rgba, etc. Default #2ec7c9",
                },
                {
                    name: "Below threshold color",
                    detail:
                        "The color setting of the bar below the threshold, the same as the color above the threshold, default #fc5c5c",
                },
            ],
        },
        TRISTATESPLINES: {
            d:
                "Generate sparklines, a three-state graph embedded in the cell, which is generally used to describe the trend of three situations, such as winning, losing, or drawing.",
            a: "Generate sparklines three-state graph",
            p: [
                {
                    name: "Range",
                    detail: "Range，Values can be calculated effectively, such as A1:A20, {1,2,3,4,5}, etc.",
                },
                {
                    name: "Bar interval",
                    detail: "The distance between bars, the default is 1",
                },
                {
                    name: "Bar color",
                    detail:
                        "The line color of the line graph can be range A1, color table index value or specific color value. Set it to 0 or false to not display it. It supports regx, rgb, rgba, etc. Default #fc5c5c",
                },
                {
                    name: "Negative bar color",
                    detail:
                        "Negative bar color setting, representing the color of negative value, same as the bar color configuration, default #97b552",
                },
                {
                    name: "Zero value bar color",
                    detail:
                        "Zero value bar color setting, representing 0 value color, the same color configuration of the bar, default #999",
                },
                {
                    name: "Color palette",
                    detail:
                        "The color palette can set the color of each bar individually, multiple settings can be set, and two formats are supported: 1 color such as #000, which means that the color of the first bar is black; 2 value range: color, such as -2:# 000 indicates that the bar with a value of -2 is black, 0:5:#000 indicates that the bar with a value of 0-5 is black, and the default is empty",
                },
            ],
        },
        PIESPLINES: {
            d: "Generate sparklines pie chart embedded in the cell, generally used to describe the proportion of data",
            a: "Generate sparklines pie chart",
            p: [
                {
                    name: "Range",
                    detail: "Range，Values can be calculated effectively, such as A1:A20, {1,2,3,4,5}, etc.",
                },
                {
                    name: "Rotation angle",
                    detail: "The rotation angle of the pie chart, the default is 0",
                },
                {
                    name: "border",
                    detail: "Pie chart border size, default is none 0",
                },
                {
                    name: "Border color",
                    detail: "The border color of the pie chart, the default is #000",
                },
                {
                    name: "Color palette",
                    detail:
                        "The color of the slice can be set in the palette, which can be set to the range of A1:A10, etc. The default is #2ec7c9, #fc5c5c, #5ab1ef, #ffb980...",
                },
            ],
        },
        BOXSPLINES: {
            d:
                "Generate sparklines embedded in the cell box plot, generally used to describe the statistical distribution of the data set",
            a: "Generate sparklines box plot",
            p: [
                {
                    name: "Range",
                    detail: "Range，Values can be calculated effectively, such as A1:A20, {1,2,3,4,5}, etc.",
                },
                {
                    name: "Outlier ratio",
                    detail:
                        "The threshold range of outliers, if it is 0 or false, it will not be displayed, the default is 1.5 times",
                },
                {
                    name: "Target value",
                    detail: "The target value setting on the box plot, the default is false and does not display",
                },
                {
                    name: "Point size",
                    detail: "The radius of the target point and outlier is set, the default is 1.5",
                },
            ],
        },
        BULLETSPLINES: {
            d: "Generate sparklines embedded in the cell, generally used to describe the task achievement rate",
            a: "Generating sparklines bullets",
            p: [
                {
                    name: "Target",
                    detail:
                        "The numerical value can be calculated effectively for the achieved target value, such as A1, 100, etc.",
                },
                {
                    name: "achieved",
                    detail:
                        "Only when the value is completed can the value be calculated effectively, such as A1, 100, etc.",
                },
                {
                    name: "Contrast",
                    detail:
                        "Comparative values, such as excess, minimum, and bottom line for awards, can be effectively calculated, such as A1, 100, etc. You can set up to 9 comparison values",
                },
            ],
        },
        COMPOSESPLINES: {
            d: "Support multiple types of pictures in the same cell, each parameter represents a sparklines diagram",
            a: "Combine sparklines graphs into one cell",
            p: [
                {
                    name: "config",
                    detail:
                        "Sparklines chart settings, such as A1:A20, a completed pie chart, line chart settings, etc.",
                },
            ],
        },
        SORT: {
            d: "Sorts the rows of a given array or range by the values in one or more columns.",
            a: "Sorts rows of range by specified column.",
            p: [
                {
                    name: "range",
                    detail: "The data to be sorted.",
                },
                {
                    name: "sort_column",
                    detail:
                        "The index of the column in `range` or a range outside of `range` containing the values by which to sort.",
                },
                {
                    name: "is_ascending",
                    detail:
                        "`TRUE` or `FALSE` indicating whether to sort `sort_column` in ascending order. `FALSE` sorts in descending order.",
                },
                {
                    name: "sort_column2",
                    detail: "Additional columns.",
                },
            ],
        },
        FILTER: {
            d:
                "Returns a filtered version of the source range, returning only rows or columns which meet the specified conditions.",
            a: "Filters a range based off provided conditions.",
            p: [
                {
                    name: "range",
                    detail: "The data to be filtered.",
                },
                {
                    name: "condition1",
                    detail:
                        "A column or row containing true or false values corresponding to the first column or row of `range`, or an array formula evaluating to true or false.",
                },
                {
                    name: "condition2",
                    detail:
                        "Additional rows or columns containing boolean values `TRUE` or `FALSE` indicating whether the corresponding row or column in `range` should pass through `FILTER`. Can also contain array formula expressions which evaluate to such rows or columns. All conditions must be of the same type (row or column). Mixing row conditions and column conditions is not permitted.",
                },
            ],
        },
        UNIQUE: {
            d:
                "Returns unique rows in the provided source range, discarding duplicates. Rows are returned in the order in which they first appear in the source range.",
            a: "Unique rows in the provided source range.",
            p: [
                {
                    name: "range",
                    detail: "The data to filter by unique entries.",
                },
                {
                    name: "by_col",
                    detail:
                        "[Option] - Logical value, indicating how to compare; by row = FALSE() or omitted; by column = TRUE().",
                },
                {
                    name: "occurs_once",
                    detail:
                        "[Option] - Logical value, only one occurrence in the unique value is returned = TRUE(); including all unique values = FALSE() or omitted.",
                },
            ],
        },
        RANDARRAY: {
            d:
                "Returns a grid of random numbers between 0 inclusive and 1 exclusive. The grid size will match the provided rows and columns arguments. If neither rows nor columns are provided, then the grid will be size 1 x 1.",
            a: "Returns a grid of random numbers.",
            p: [
                {
                    name: "rows",
                    detail: "The number of rows to populate with a random number.",
                },
                {
                    name: "columns",
                    detail: "The number of columns to populate with a random number.",
                },
            ],
        },
        SEQUENCE: {
            d:
                "Returns a grid of sequential numbers starting at a specified start value and  increasing by a specified step size. By default, the sequence starts at and  increases by 1.",
            a: "Returns a grid of sequential numbers.",
            p: [
                {
                    name: "rows",
                    detail: "The number of rows in the function's resulting grid.",
                },
                {
                    name: "columns",
                    detail:
                        "The number of columns in the function's resulting grid. If omitted, the result grid will have 1 column.",
                },
                {
                    name: "start",
                    detail: "The number, at which to start the sequence. If omitted, the sequence will start at 1.",
                },
                {
                    name: "step",
                    detail:
                        "The amount each value in the sequence will differ by. If omitted, each value will differ by 1.",
                },
            ],
        },
        EVALUATE: {
            d: "Evaluate a formula or expression expressed in words and return the result",
            a: "Evaluate according to literal formula or expression.",
            p: [
                {
                    name: "expression",
                    detail: "Formula or expression",
                },
            ],
        },
        REMOTE: {
            d: "Calls a function on a remote server",
            a: "Calls a function on a remote back end server/API.",
            p: [
                {
                    name: "remote_expression",
                    detail: "Formula",
                },
            ],
        },
    },
    toolbar: {
        undo: "取り消し",
        redo: "やり直し",
        paintFormat: "書式コピー",
        currencyFormat: "通貨形式",
        percentageFormat: "パーセンテージ形式",
        numberDecrease: "小数点以下の桁数を減らす",
        numberIncrease: "小数点以下の桁数を増やす",
        moreFormats: "その他の形式",
        font: "フォント",
        fontSize: "フォントサイズ",
        bold: "太字 (Ctrl+B)",
        italic: "イタリック (Ctrl+I)",
        strikethrough: "取り消し線 (Alt+Shift+5)",
        underline: "下線",
        textColor: "テキスト色",
        chooseColor: "色の選択",
        resetColor: "色のリセット",
        customColor: "カスタム",
        alternatingColors: "交互色",
        confirmColor: "色を確定",
        cancelColor: "キャンセル",
        collapse: "折りたたむ",
        fillColor: "セルの色",
        border: "罫線",
        borderStyle: "罫線のスタイル",
        mergeCell: "セルの結合",
        chooseMergeType: "結合タイプを選択",
        horizontalAlign: "水平揃え",
        verticalAlign: "垂直揃え",
        alignment: "配置",
        textWrap: "テキストの折り返し",
        textWrapMode: "折り返し方",
        textRotate: "テキストの回転",
        textRotateMode: "回転方式",
        freezeTopRow: "上行を固定",
        sortAndFilter: "並べ替えとフィルタ",
        findAndReplace: "検索と置換",
        sum: "合計",
        autoSum: "自動合計",
        moreFunction: "その他の関数",
        conditionalFormat: "条件付き書式",
        postil: "コメント",
        pivotTable: "ピボットテーブル",
        chart: "チャート",
        screenshot: "スクリーンショット",
        splitColumn: "列の分割",
        insertImage: "画像の挿入",
        exportXlsx: "Xlsxにエクスポート",
        insertLink: "リンクの挿入",
        dataVerification: "データ検証",
        protection: "シートの保護",

        clearText: "色選択をクリア",
        noColorSelectedText: "色が選択されていません",

        toolMore: "もっと",
        toolLess: "少なく",
        toolClose: "閉じる",
        toolMoreTip: "もっと機能",
        moreOptions: "その他のオプション",

        cellFormat: "セル形式の設定",
        print: "印刷",
    },
    alternatingColors: {
        applyRange: "適用範囲",
        selectRange: "適用範囲を選択",
        header: "ヘッダー",
        footer: "フッター",

        errorInfo: "複数選択範囲にこの操作はできません、単一の範囲を選択してください",
        textTitle: "形式スタイル",
        custom: "カスタム",
        close: "閉じる",
        selectionTextColor: "テキスト色を選択",
        selectionCellColor: "セルの色を選択",
        removeColor: "交互色を除去",
        colorShow: "色",
        currentColor: "現在の色",

        tipSelectRange: "交互色を適用する範囲を選択してください",
        errorNoRange: "選択した適用範囲は選択範囲ではありません！",
        errorExistColors: "選択した適用範囲には既に交互色が存在し、編集しようとしている適用範囲ではありません！",
    },
    button: {
        confirm: "確定",
        cancel: "キャンセル",
        close: "閉じる",
        update: "更新",
        delete: "削除",
        insert: "新規作成",
        prevPage: "前のページ",
        nextPage: "次のページ",
        total: "合計：",
    },
    paint: {
        start: "書式コピーを開始",
        end: "ESCキーで終了",

        tipSelectRange: "コピーする書式の範囲を選択してください",
        tipNotMulti: "複数選択範囲にはこの操作を実行できません",
    },
    format: {
        moreCurrency: "その他の通貨形式",
        moreDateTime: "その他の日付と時間形式",
        moreNumber: "その他の数字形式",

        titleCurrency: "通貨形式",
        decimalPlaces: "小数点以下の桁数",
        titleDateTime: "日付と時間形式",
        titleNumber: "数字形式",
    },
    info: {
        detailUpdate: "新規オープン",
        detailSave: "ローカルキャッシュから復元されました",
        row: "行",
        column: "列",
        loading: "レンダリング中···",

        copy: "コピー",
        return: "戻る",
        rename: "名前の変更",
        tips: "スプレッドシートの名前変更",
        noName: "無題のスプレッドシート",
        wait: "更新待ち",

        add: "追加",
        addLast: "最後に追加",
        backTop: "トップに戻る",
        pageInfo: "合計${total}件、${totalPage}ページ、現在${currentPage}ページを表示",
        nextPage: "次のページ",

        tipInputNumber: "数字を入力してください",
        tipInputNumberLimit: "追加範囲は1-600に制限されます",

        tipRowHeightLimit: "行の高さは0 ~ 545の間でなければなりません",
        tipColumnWidthLimit: "列の幅は0 ~ 2038の間でなければなりません",
        pageInfoFull: "合計${total}件、${totalPage}ページ、すべてのデータを表示しました",
    },
    currencyDetail: {
        JPY: "円",
        USdollar: "ドル",
        EUR: "ユーロ",
        GBP: "ポンド",
        RMB: "人民元",
        HK: "香港ドル",
    },
    defaultFmt: [
        { text: "標準", value: "General", example: "" },
        { text: "文字列", value: "@", example: "" },
        { text: "", value: "split", example: "" },
        { text: "数値", value: "##0", example: "1000" },
        { text: "パーセンテージ", value: "#0.00%", example: "12.21%" },
        // { text: "科学的表記", value: "0.00E+00", example: "1.01E+5" },
        { text: "", value: "split", example: "" },
        { text: "会計", value: "¥(0)", example: "¥(1200)" },
        // { "text": "ファイナンス", "value": "(#.####)", "example": "(1200.09)" },
        // { text: "万円", value: "w", example: "1億2000万2500" },
        { text: "通貨", value: "¥#,##0", example: "¥1,200" },
        // { "text": "通貨整数", "value": "¥####", "example": "¥1200" },
        // { text: "万円2小数点", value: "w0.00", example: "2万2500.55" },
        { text: "", value: "split", example: "" },
        { text: "日付", value: "yyyy/MM/dd", example: "2017/11/29" },
        // { text: "時刻", value: "hh:mm AM/PM", example: "3:00 PM" },
        // "24時間制時刻"
        { text: "時刻", value: "hh:mm", example: "15:00" },
        // { text: "日付時刻", value: "yyyy/MM/dd hh:mm AM/PM", example: "2017/11/29 3:00 PM" },
        //"24時間制日付時刻"
        { text: "日付時刻", value: "yyyy/MM/dd hh:mm", example: "2017/11/29 15:00" },
        { text: "", value: "split", example: "" },
        // { text: "その他の表示形式", value: "fmtOtherSelf", example: "more" },
        { text: "その他の日付と時間形式", value: "moredatetime", example: "set" },
        { text: "その他の数字形式", value: "moredigit", example: "set" },
    ],
    dateFmtList: [
        {
            name: "1930-08-05",
            value: "yyyy-MM-dd",
        },
        {
            name: "1930/8/5",
            value: "yyyy/MM/dd",
        },
        {
            name: "1930/8",
            value: "yyyy/MM",
        },
        {
            name: "1930年8月5日",
            value: 'yyyy"年"M"月"d"日"',
        },
        {
            name: "1930年8月",
            value: 'yyyy"年"M"月"',
        },
        {
            name: "08/05",
            value: "MM/dd",
        },
        {
            name: "8-5",
            value: "M-d",
        },
        {
            name: "8月5日",
            value: 'M"月"d"日"',
        },
        {
            name: "13:30:30",
            value: "h:mm:ss",
        },
        {
            name: "13:30",
            value: "h:mm",
        },
        // {
        //     name: "午後01:30",
        //     value: "午前/午後 hh:mm",
        // },
        // {
        //     name: "午後1:30",
        //     value: "午前/午後 h:mm",
        // },
        // {
        //     name: "午後1:30:30",
        //     value: "午前/午後 h:mm:ss",
        // },
        // {
        //     name: "08/05 午後01:30",
        //     value: "MM/dd 午前/午後 hh:mm",
        // },
        // {
        //     "name": "1930年8月5日火曜日",
        //     "value": ''
        // },
        // {
        //     "name": "1930年8月5日火曜日 午後1:30:30",
        //     "value": ''
        // },
    ],
    fontFamily: {
        MicrosoftYaHei: "Microsoft YaHei",
    },
    fontarray: [
        "Times New Roman",
        "Arial",
        "Tahoma",
        "Verdana",
        "微软雅黑",
        "宋体",
        "黑体",
        "楷体",
        "仿宋",
        "新宋体",
        "华文新魏",
        "华文行楷",
        "华文隶书",
        "ＭＳ Ｐゴシック",
        "ＭＳ ゴシック",
        "ＭＳ Ｐ明朝",
        "ＭＳ 明朝",
        "メイリオ",
    ],
    fontjson: {
        "times new roman": 0,
        arial: 1,
        tahoma: 2,
        verdana: 3,
        微软雅黑: 4,
        "microsoft yahei": 4,
        宋体: 5,
        simsun: 5,
        黑体: 6,
        simhei: 6,
        楷体: 7,
        kaiti: 7,
        仿宋: 8,
        fangsong: 8,
        新宋体: 9,
        nsimsun: 9,
        华文新魏: 10,
        stxinwei: 10,
        华文行楷: 11,
        stxingkai: 11,
        华文隶书: 12,
        stliti: 12,
        "ＭＳ Ｐゴシック": 13,
        "ＭＳ ゴシック": 14,
        "ＭＳ Ｐ明朝": 15,
        "ＭＳ 明朝": 16,
        "メイリオ": 17,
    },
    border: {
        borderTop: "上の罫線",
        borderBottom: "下の罫線",
        borderLeft: "左の罫線",
        borderRight: "右の罫線",
        borderNone: "なし",
        borderAll: "全て",
        borderOutside: "外側",
        borderInside: "内側",
        borderHorizontal: "内側の横罫線",
        borderVertical: "内側の縦罫線",
        borderColor: "罫線の色",
        borderSize: "罫線の太さ",

        borderTipsNull: "空白",
        borderTipsText: "テキストボックス",
        borderTipsDate: "日付",
        borderTipsButton: "ボタン",
        borderTipsRequired: "必須項目",
    },
    merge: {
        mergeAll: "全て結合",
        mergeV: "垂直結合",
        mergeH: "水平結合",
        mergeCancel: "結合を解除",
        overlappingError: "重複する範囲は結合できません",
        partiallyError: "部分的に結合されたセルに対してはこの操作を実行できません",
    },
    align: {
        left: "左揃え",
        center: "中央揃え",
        right: "右揃え",
        auto: "自動",

        top: "上揃え",
        middle: "中央揃え",
        bottom: "下揃え",
    },
    textWrap: {
        overflow: "オーバーフロー",
        wrap: "自動改行",
        clip: "切り取り",
    },
    rotation: {
        none: "回転なし",
        angleup: "上に傾け",
        angledown: "下に傾け",
        vertical: "縦書き",
        rotationUp: "上に90°",
        rotationDown: "下に90°",
    },
    freezen: {
        default: "最初の行を固定",
        freezenRow: "最初の行を固定",
        freezenColumn: "A列を固定",
        freezenRC: "最初の行とA列を固定",
        freezenRowRange: "選択範囲まで行を固定",
        freezenColumnRange: "選択範囲まで列を固定",
        freezenRCRange: "選択範囲まで行列を固定",
        freezenCancel: "固定解除",

        noSeletionError: "選択範囲がありません",
        rangeRCOverErrorTitle: "固定通知",
        rangeRCOverError: "固定された窓が表示範囲を超えてしまい、正常に操作できなくなります。固定範囲を再設定してください。",
    },
    sort: {
        asc: "昇順",
        desc: "降順",
        custom: "カスタムソート",

        hasTitle: "データにはタイトル行があります",
        sortBy: "並び替え基準",
        addOthers: "他の並び替え列を追加",
        close: "閉じる",
        confirm: "並び替える",

        columnOperation: "列",
        secondaryTitle: "第二の基準",

        sortTitle: "並び替え範囲",

        sortRangeTitle: "並び替え範囲の開始",
        sortRangeTitleTo: "終了",

        noRangeError: "複数の選択範囲に対してこの操作を実行できません。単一の範囲を選択してから、もう一度お試しください",
        mergeError: "選択範囲に結合されたセルがあるため、この操作を実行できません！",
        columnSortMergeError: "列の並び替えは表全体の選択範囲に拡張されますが、選択範囲に結合されたセルがあるため、この操作を実行できません。機能バーの並び替え機能を選択してください！",
    },
    filter: {
        filter: "フィルター",
        clearFilter: "フィルターのクリア",

        sortByAsc: "A-Zで昇順に並べ替え",
        sortByDesc: "Z-Aで降順に並べ替え",
        filterByColor: "色でフィルター",
        filterByCondition: "条件でフィルター",
        filterByValues: "値でフィルター",

        filiterInputNone: "なし",

        filiterInputTip: "フィルター値を入力",
        filiterRangeStart: "から",
        filiterRangeStartTip: "範囲の開始",
        filiterRangeEnd: "まで",
        filiterRangeEndTip: "範囲の終了",

        filterValueByAllBtn: "全選択",
        filterValueByClearBtn: "クリア",
        filterValueByInverseBtn: "選択を反転",
        filterValueByTip: "値によるフィルター",
        filterConform: "確認",
        filterCancel: "キャンセル",

        conditionNone: "なし",
        conditionCellIsNull: "セルが空",
        conditionCellNotNull: "セルにデータがある",
        conditionCellTextContain: "テキストが含まれる",
        conditionCellTextNotContain: "テキストが含まれない",
        conditionCellTextStart: "テキストの開始",
        conditionCellTextEnd: "テキストの終了",
        conditionCellTextEqual: "テキストが等しい",
        conditionCellDateEqual: "日付が等しい",
        conditionCellDateBefore: "日付がそれより前",
        conditionCellDateAfter: "日付がそれより後",
        conditionCellGreater: "より大きい",
        conditionCellGreaterEqual: "以上",
        conditionCellLess: "より小さい",
        conditionCellLessEqual: "以下",
        conditionCellEqual: "等しい",
        conditionCellNotEqual: "等しくない",
        conditionCellBetween: "の間",
        conditionCellNotBetween: "の間にない",

        filiterMoreDataTip: "大量のデータがあります！しばらくお待ちください",
        filiterMonthText: "月",
        filiterYearText: "年",
        filiterByColorTip: "セルの色によるフィルター",
        filiterByTextColorTip: "セルのフォントの色によるフィルター",
        filterContainerOneColorTip: "この列には1つの色のみ含まれています",
        filterDateFormatTip: "日付形式",

        valueBlank: "(空白)",
        mergeError: "選択範囲に結合セルが存在するため、この操作を実行できません！",
    },
    rightclick: {
        copy: "コピー",
        copyAs: "としてコピー",
        paste: "貼り付け",
        pasteStyle: "書式貼り付け",
        pasteValue: "値貼り付け",
        insert: "挿入",
        delete: "削除",
        deleteCell: "セルを削除",
        deleteSelected: "選択範囲を削除",
        hide: "非表示",
        hideSelected: "選択範囲を非表示",
        showHide: "非表示のものを表示",
        to: "へ",
        left: "左",
        right: "右",
        top: "上",
        bottom: "下",
        moveLeft: "左に移動",
        moveUp: "上に移動",
        add: "追加",
        row: "行",
        column: "列",
        width: "幅",
        height: "高さ",
        number: "数字",
        confirm: "確認",
        orderAZ: "A-Z 順に並べ替え",
        orderZA: "Z-A 降順に並べ替え",
        clearContent: "内容をクリア",
        matrix: "行列操作範囲",
        sortSelection: "選択範囲を並べ替え",
        filterSelection: "選択範囲をフィルター",
        chartGeneration: "グラフ生成",
        firstLineTitle: "最初の行をタイトルとして",
        untitled: "無題",
        array1: "1次元配列",
        array2: "2次元配列",
        array3: "多次元配列",
        diagonal: "対角線",
        antiDiagonal: "反対角線",
        diagonalOffset: "対角線オフセット",
        offset: "オフセット",
        boolean: "ブール値",
        flip: "フリップ",
        upAndDown: "上下",
        leftAndRight: "左右",
        clockwise: "時計回り",
        counterclockwise: "反時計回り",
        transpose: "転置",
        matrixCalculation: "行列計算",
        plus: "加算",
        minus: "減算",
        multiply: "乗算",
        divided: "除算",
        power: "べき乗",
        root: "平方根",
        log: "対数",
        delete0: "両端の0を削除",
        removeDuplicate: "重複値を削除",
        byRow: "行ごと",
        byCol: "列ごと",
        generateNewMatrix: "新しい行列を生成",
    },
    comment: {
        insert: "新しいコメントを挿入",
        edit: "コメントを編集",
        delete: "削除",
        showOne: "コメントの表示/非表示",
        showAll: "すべてのコメントを表示/非表示",
    },
    screenshot: {
        screenshotTipNoSelection: "スクリーンショットの範囲を選択してください",
        screenshotTipTitle: "ヒント！",
        screenshotTipHasMerge: "結合セルに対しては操作を行えません",
        screenshotTipHasMulti: "複数の選択範囲に対しては操作を行えません",
        screenshotTipSuccess: "スクリーンショット成功",
        screenshotImageName: "スクリーンショット",

        downLoadClose: "閉じる",
        downLoadCopy: "クリップボードにコピー",
        downLoadBtn: "ダウンロード",
        browserNotTip: "ダウンロード機能はIEブラウザではサポートされていません！",
        rightclickTip: "画像上で右クリックして'コピー'を選択してください",
        successTip: "コピー成功（貼り付けがうまくいかない場合は、画像上で右クリックして'画像をコピー'を選択してください）",
    },
    splitText: {
        splitDelimiters: "区切り文字",
        splitOther: "その他",
        splitContinueSymbol: "連続する区切り文字は1つとして扱う",
        splitDataPreview: "データプレビュー",
        splitTextTitle: "テキストを分割",
        splitConfirmToExe: "既にデータが存在します、置き換えますか？",

        tipNoMulti: "複数の選択範囲に対しては操作を行えません、単一の範囲を選択してから操作してください",
        tipNoMultiColumn: "一度に変換できるのは1列のデータのみです。選択範囲は複数行にわたることができますが、複数列にはできません。選択範囲を1列に絞り込んだ後に再試行してください",
    },
    imageText: {
        imageSetting: "画像設定",
        close: "閉じる",
        conventional: "通常",
        moveCell1: "セルを移動してサイズを調整",
        moveCell2: "セルを移動してサイズを調整しない",
        moveCell3: "セルを移動せずにサイズを調整",
        fixedPos: "固定位置",
        border: "枠線",
        width: "幅",
        radius: "半径",
        style: "スタイル",
        solid: "実線",
        dashed: "点線",
        dotted: "破線",
        double: "二重線",
        color: "色",
    },
    punctuation: {
        tab: "Tab キー",
        semicolon: "セミコロン",
        comma: "カンマ",
        space: "スペース",
    },
    findAndReplace: {
        find: "検索",
        replace: "置換",
        goto: "移動",
        location: "位置条件",
        formula: "数式",
        date: "日付",
        number: "数字",
        string: "文字列",
        error: "エラー",
        condition: "条件付き書式",
        rowSpan: "行間隔",
        columnSpan: "列間隔",
        locationExample: "位置",
        lessTwoRowTip: "最低2行を選択してください",
        lessTwoColumnTip: "最低2列を選択してください",

        findTextbox: "検索内容",
        replaceTextbox: "置換内容",

        regexTextbox: "正規表現による一致",
        wholeTextbox: "完全一致",
        distinguishTextbox: "大文字と小文字を区別",

        allReplaceBtn: "すべて置換",
        replaceBtn: "置換",
        allFindBtn: "すべて検索",
        findBtn: "次を検索",

        noFindTip: "該当する内容は見つかりませんでした",
        modeTip: "このモードでは操作できません",

        searchTargetSheet: "シート",
        searchTargetCell: "セル",
        searchTargetValue: "値",

        searchInputTip: "検索内容を入力してください",

        noReplceTip: "置換できる内容がありません",
        noMatchTip: "一致する項目が見つかりません",

        successTip: "${xlength}件置換しました",

        locationConstant: "定数",
        locationFormula: "数式",
        locationDate: "日付",
        locationDigital: "数字",
        locationString: "文字列",
        locationBool: "論理値",
        locationError: "エラー",
        locationNull: "空白",
        locationCondition: "条件付き書式",
        locationRowSpan: "行間隔",
        locationColumnSpan: "列間隔",

        locationTiplessTwoRow: "最低でも2行を選択してください",
        locationTiplessTwoColumn: "最低でも2列を選択してください",
        locationTipNotFindCell: "セルが見つかりません",
    },
    sheetconfig: {
        delete: "削除",
        copy: "コピー",
        rename: "名前の変更",
        changeColor: "色の変更",
        hide: "非表示",
        unhide: "非表示解除",
        moveLeft: "左へ移動",
        moveRight: "右へ移動",
        resetColor: "色のリセット",
        cancelText: "キャンセル",
        chooseText: "色を決定",

        tipNameRepeat: "シート名が重複しています！別の名前に変更してください",
        noMoreSheet:
            "ワークブックには少なくとも1つの表示されるワークシートが必要です。選択したワークシートを削除するには、新しいワークシートを挿入するか、隠されたワークシートを表示してください。",
        confirmDelete: "本当に削除しますか？",
        redoDelete: "削除はCtrl+Zで元に戻せます",
        noHide: "非表示にできません、少なくとも1つのシートタブを表示する必要があります",
        chartEditNoOpt: "チャート編集モードではこの操作は許可されません！",
        sheetNameSpecCharError: "名前は31文字以内で、先頭と末尾に'を使用せず、\r\n[ ] : \\ ? * / を含めないでください",
        sheetNamecannotIsEmptyError: "名前は空にできません！",
    },
    conditionformat: {
        conditionformat_greaterThan: "条件フォーマット - 大きい",
        conditionformat_greaterThan_title: "以下の値より大きいセルにフォーマットを設定",
        conditionformat_lessThan: "条件フォーマット - 小さい",
        conditionformat_lessThan_title: "以下の値より小さいセルにフォーマットを設定",
        conditionformat_betweenness: "条件フォーマット - 範囲内",
        conditionformat_betweenness_title: "以下の2つの値の間にあるセルにフォーマットを設定",
        conditionformat_equal: "条件フォーマット - 等しい",
        conditionformat_equal_title: "以下の値と等しいセルにフォーマットを設定",
        conditionformat_textContains: "条件フォーマット - テキストが含まれる",
        conditionformat_textContains_title: "以下のテキストを含むセルにフォーマットを設定",
        conditionformat_occurrenceDate: "条件フォーマット - 日付",
        conditionformat_occurrenceDate_title: "以下の日付を含むセルにフォーマットを設定",
        conditionformat_duplicateValue: "条件フォーマット - 重複値",
        conditionformat_duplicateValue_title: "以下のタイプの値を含むセルにフォーマットを設定",
        conditionformat_top10: "条件フォーマット - 上位10",
        conditionformat_top10_percent: "条件フォーマット - 上位10%",
        conditionformat_top10_title: "値が最大のセルにフォーマットを設定",
        conditionformat_last10: "条件フォーマット - 下位10",
        conditionformat_last10_percent: "条件フォーマット - 下位10%",
        conditionformat_last10_title: "値が最小のセルにフォーマットを設定",
        conditionformat_AboveAverage: "条件フォーマット - 平均以上",
        conditionformat_AboveAverage_title: "平均値より高いセルにフォーマットを設定",
        conditionformat_SubAverage: "条件フォーマット - 平均以下",
        conditionformat_SubAverage_title: "平均値より低いセルにフォーマットを設定",
        rule: "ルール",
        newRule: "新規ルール",
        editRule: "ルールの編集",
        deleteRule: "ルールの削除",
        deleteCellRule: "選択したセルのルールをクリア",
        deleteSheetRule: "シート全体のルールをクリア",
        manageRules: "ルールの管理",
        showRules: "フォーマットルールを表示",
        highlightCellRules: "セルをハイライト表示するルール",
        itemSelectionRules: "アイテム選択ルール",
        conditionformatManageRules: "条件フォーマットルールマネージャー",
        format: "フォーマット",
        setFormat: "フォーマット設定",
        setAs: "設定方法",
        setAsByArea: "選択した範囲に対して設定",
        applyRange: "適用範囲",
        selectRange: "適用範囲を選択してください",
        selectRange_percent: "選択範囲のパーセンテージ",
        selectRange_average: "選択範囲の平均値",
        selectRange_value: "選択範囲内の数値",
        pleaseSelectRange: "適用範囲を選択してください",
        selectDataRange: "データ範囲を選択",
        selectCell: "セル選択",
        pleaseSelectCell: "セルを選択してください",
        pleaseSelectADate: "日付を選択してください",
        pleaseEnterInteger: "1 から 1000 までの整数を入力してください",
        onlySingleCell: "単一セルのみ参照可能",
        conditionValueCanOnly: "条件値は数字または単一セルのみ可能",
        ruleTypeItem1: "セルの値に基づいてすべてのセルを書式設定",
        ruleTypeItem2: "指定の値を合む乜ルだけを書式設定",
        ruleTypeItem2_title: "以下の条件を満たすセルのみ",
        ruleTypeItem3: "上位または下位に入る値だけを書式設定",
        ruleTypeItem3_title: "以下のランキング内の値",
        ruleTypeItem4: "平均より上または下の値だけを書式設定",
        ruleTypeItem4_title: "以下の条件を満たす値",
        ruleTypeItem5: "ー意の値または重複する値だけを書式設定",
        ruleTypeItem6: "数式を使用して、書式設定する乜ルを決定",
        formula: "数式",
        textColor: "テキスト色",
        cellColor: "セルの色",
        confirm: "確認",
        confirmColor: "色を確認",
        cancel: "キャンセル",
        close: "閉じる",
        clearColorSelect: "色の選択をクリア",
        sheet: "シート",
        currentSheet: "現在のシート",
        dataBar: "データバー",
        dataBarColor: "データバーの色",
        gradientDataBar_1: "青-白グラデーションデータバー",
        gradientDataBar_2: "緑-白グラデーションデータバー",
        gradientDataBar_3: "赤-白グラデーションデータバー",
        gradientDataBar_4: "オレンジ-白グラデーションデータバー",
        gradientDataBar_5: "ライトブルー-白グラデーションデータバー",
        gradientDataBar_6: "紫-白グラデーションデータバー",
        solidColorDataBar_1: "青色データバー",
        solidColorDataBar_2: "緑色データバー",
        solidColorDataBar_3: "赤色データバー",
        solidColorDataBar_4: "オレンジ色データバー",
        solidColorDataBar_5: "ライトブルー色データバー",
        solidColorDataBar_6: "紫色データバー",
        colorGradation: "カラーグラデーション",
        colorGradation_1: "緑-黄-赤カラーグラデーション",
        colorGradation_2: "赤-黄-緑カラーグラデーション",
        colorGradation_3: "緑-白-赤カラーグラデーション",
        colorGradation_4: "赤-白-緑カラーグラデーション",
        colorGradation_5: "青-白-赤カラーグラデーション",
        colorGradation_6: "赤-白-青カラーグラデーション",
        colorGradation_7: "白-赤カラーグラデーション",
        colorGradation_8: "赤-白カラーグラデーション",
        colorGradation_9: "緑-白カラーグラデーション",
        colorGradation_10: "白-緑カラーグラデーション",
        colorGradation_11: "緑-黄カラーグラデーション",
        colorGradation_12: "黄-緑カラーグラデーション",
        icons: "アイコンセット",
        pleaseSelectIcon: "アイコンセットを選択してください：",
        cellValue: "セル値",
        specificText: "特定のテキスト",
        occurrence: "発生日",
        greaterThan: "より大きい",
        lessThan: "より小さい",
        between: "の間",
        equal: "等しい",
        in: "と",
        to: "へ",
        between2: "の間",
        contain: "含む",
        textContains: "テキストが含まれる",
        duplicateValue: "重複値",
        uniqueValue: "一意の値",
        top: "上位",
        top10: "上位10項目",
        top10_percent: "上位10%",
        last: "下位",
        last10: "下位10項目",
        last10_percent: "下位10%",
        oneself: "自身",
        above: "より上",
        aboveAverage: "平均より上",
        below: "より下",
        belowAverage: "平均より下",
        all: "全て",
        yesterday: "昨日",
        today: "今日",
        tomorrow: "明日",
        lastWeek: "先週",
        thisWeek: "今週",
        lastMonth: "先月",
        thisMonth: "今月",
        lastYear: "去年",
        thisYear: "今年",
        last7days: "過去7日間",
        last30days: "過去30日間",
        next7days: "次の7日間",
        next30days: "次の30日間",
        next60days: "次の60日間",
        chooseRuleType: "ルールタイプの選択",
        editRuleDescription: "ルールの説明を編集",
        newFormatRule: "新しいフォーマットルール",
        editFormatRule: "フォーマットルールを編集",
        formatStyle: "フォーマットスタイル",
        fillType: "塗りつぶしタイプ",
        color: "色",
        twocolor: "二色",
        tricolor: "三色",
        multicolor: "多色",
        grayColor: "グレー",
        gradient: "グラデーション",
        solid: "単色",
        maxValue: "最大値",
        medianValue: "中央値",
        minValue: "最小値",
        direction: "方向",
        threeWayArrow: "三方向矢印",
        fourWayArrow: "四方向矢印",
        fiveWayArrow: "五方向矢印",
        threeTriangles: "3つの三角",
        shape: "形状",
        threeColorTrafficLight: "三色の交通信号",
        fourColorTrafficLight: "四色の交通信号",
        threeSigns: "三つのサイン",
        greenRedBlackGradient: "緑-赤-黒グラデーション",
        rimless: "枠なし",
        bordered: "枠付き",
        mark: "マーク",
        threeSymbols: "3つのシンボル",
        tricolorFlag: "三色旗",
        circled: "円で囲む",
        noCircle: "円なし",
        grade: "グレード",
        grade4: "4段階評価",
        grade5: "5段階評価",
        threeStars: "3つ星",
        fiveQuadrantDiagram: "五象限図",
        fiveBoxes: "5つのボックス",
    },
    insertLink: {
        linkText: "テキスト",
        linkType: "リンクタイプ",
        external: "外部リンク",
        internal: "内部リンク",
        linkAddress: "リンクアドレス",
        linkSheet: "シート",
        linkCell: "セル参照",
        linkTooltip: "情報",
        placeholder1: "ウェブリンクアドレスを入力してください",
        placeholder2: "参照するセルを入力してください、例A1",
        placeholder3: "情報を参照して内容を入力してください",
        tooltipInfo1: "有効なリンクを入力してください",
        tooltipInfo2: "正しいセル参照を入力してください",
    },
    dataVerification: {
        cellRange: "セル範囲",
        selectCellRange: "クリックしてセル範囲を選択",
        selectCellRange2: "セル範囲を選択してください",
        verificationCondition: "検証条件",
        allowMultiSelect: "複数選択を許可",
        dropdown: "ドロップダウンリスト",
        checkbox: "チェックボックス",
        number: "数字",
        number_integer: "数字-整数",
        number_decimal: "数字-小数",
        text_content: "テキスト-内容",
        text_length: "テキスト-長さ",
        date: "日付",
        validity: "有効性",
        placeholder1: "オプションを入力してください、英語のカンマで区切ります、例1,2,3,4,5",
        placeholder2: "内容を入力してください",
        placeholder3: "数値を入力してください、例10",
        placeholder4: "指定したテキストを入力してください",
        placeholder5: "セルを選択したときに表示されるヒントを入力してください",
        selected: "選択時",
        notSelected: "選択されていない",
        between: "の間",
        notBetween: "の間ではない",
        equal: "と等しい",
        notEqualTo: "と等しくない",
        moreThanThe: "より大きい",
        lessThan: "より小さい",
        greaterOrEqualTo: "以上",
        lessThanOrEqualTo: "以下",
        include: "を含む",
        exclude: "を含まない",
        earlierThan: "より早い",
        noEarlierThan: "以上",
        laterThan: "より遅い",
        noLaterThan: "以下",
        identificationNumber: "身分証明番号",
        phoneNumber: "携帯番号",
        remote: "自動リモートでオプションを取得",
        prohibitInput: "データ入力が無効な場合は入力を禁止",
        hintShow: "セル選択時にヒントを表示",
        deleteVerification: "検証を削除",
        tooltipInfo1: "ドロップダウンリストのオプションは空にできません",
        tooltipInfo2: "チェックボックスの内容は空にできません",
        tooltipInfo3: "入力した値は数値タイプではありません",
        tooltipInfo4: "数値2は数値1より小さくできません",
        tooltipInfo5: "テキストの内容は空にできません",
        tooltipInfo6: "入力した値は日付タイプではありません",
        tooltipInfo7: "日付2は日付1より小さくできません",
        textlengthInteger: "テキストの長さは0以上の整数でなければなりません",
    },
    formula: {
        sum: "合計",
        average: "平均",
        count: "カウント",
        max: "最大",
        min: "最小",
        ifGenerate: "if式ジェネレータ",
        find: "その他の関数",

        tipNotBelongToIf: "このセルの関数はif式に属していません！",
        tipSelectCell: "関数を挿入するセルを選択してください",

        ifGenCompareValueTitle: "比較値",
        ifGenSelectCellTitle: "セルを選択してください",
        ifGenRangeTitle: "範囲",
        ifGenRangeTo: "から",
        ifGenRangeEvaluate: "範囲評価",
        ifGenSelectRangeTitle: "範囲を選択してください",
        ifGenCutWay: "分割方法",
        ifGenCutSame: "分割値が同じ",
        ifGenCutNpiece: "N分割",
        ifGenCutCustom: "カスタム入力",
        ifGenCutConfirm: "生成",

        ifGenTipSelectCell: "セルを選択",
        ifGenTipSelectCellPlace: "セルを選択してください",

        ifGenTipSelectRange: "範囲を選択",
        ifGenTipSelectRangePlace: "範囲を選択してください",

        ifGenTipNotNullValue: "比較値は空にできません！",
        ifGenTipLableTitile: "ラベル",
        ifGenTipRangeNotforNull: "範囲は空にできません！",
        ifGenTipCutValueNotforNull: "分割値は空にできません！",
        ifGenTipNotGenCondition: "利用可能な条件が生成されていません！",
    },
    formulaMore: {
        valueTitle: "値",
        tipSelectDataRange: "データ範囲を選択",
        tipDataRangeTile: "データ範囲",
        findFunctionTitle: "関数を検索",
        tipInputFunctionName: "検索したい関数名または関数の簡単な説明を入力してください",

        Array: "配列",
        Database: "データベース",
        Date: "日付",
        Engineering: "エンジニアリング",
        Filter: "フィルタ",
        Financial: "財務",
        luckysheet: "Luckysheet組み込み",
        other: "その他",
        Logical: "論理",
        Lookup: "検索",
        Math: "数学",
        Operator: "演算子",
        Parser: "変換ツール",
        Statistical: "統計",
        Text: "テキスト",
        dataMining: "データマイニング",

        selectFunctionTitle: "関数を選択",
        calculationResult: "計算結果",

        tipSuccessText: "成功",
        tipParamErrorText: "パラメータの型が間違っています",

        helpClose: "閉じる",
        helpCollapse: "折りたたみ",
        helpExample: "例",
        helpAbstract: "概要",

        execfunctionError: '警告", "関数にエラーが存在します',
        execfunctionSelfError: "関数が自分自身のセルを参照することはできません",
        execfunctionSelfErrorResult: "関数が自分自身のセルを参照することは計算結果の不正確さにつながります",

        allowRepeatText: "繰り返し可能",
        allowOptionText: "オプション",

        selectCategory: "またはカテゴリを選択",
    },
    drag: {
        noMerge: "結合されたセルに対してこの操作を行うことはできません",
        affectPivot: "選択されたセルを変更すると、ピボットテーブルに影響するため、この操作はできません！",
        noMulti: "複数の選択範囲に対してこの操作を行うことはできません、単一の範囲を選択してください",
        noPaste: "ここにこの内容を貼り付けることはできません、貼り付け範囲の1つのセルを選択して、もう一度貼り付けを試してください",
        noPartMerge: "部分的に結合されたセルに対してこの操作を行うことはできません",

        inputCorrect: "正しい数値を入力してください",
        notLessOne: "行数または列数は1未満にできません",
        offsetColumnLessZero: "オフセット列は負の数にできません！",

        pasteMustKeybordAlert: "シートでのコピー＆ペースト: コピーにはCtrl + C、貼り付けにはCtrl + V、切り取りにはCtrl + X を使用します",
        pasteMustKeybordAlertHTMLTitle: "シートでのコピー＆ペースト",
        pasteMustKeybordAlertHTML:
            "<span style='line-height: 1.0;font-size:36px;font-weight: bold;color:#666;'>Ctrl + C</span>&nbsp;&nbsp;コピー<br/><span style='line-height: 1.0;font-size:36px;font-weight: bold;color:#666;'>Ctrl + V</span>&nbsp;&nbsp;貼り付け<br/><span style='line-height: 1.0;font-size:36px;font-weight: bold;color:#666;'>Ctrl + X</span>&nbsp;&nbsp;切り取り",
    },
    paste: {
        warning: "ヒント",
        errorNotAllowMulti: "複数選択範囲に対してこの操作を実行することはできません。単一の範囲を選択してから再試行してください",
        errorNotAllowMerged: "結合セルに対して部分的な変更はできません",
    },
    pivotTable: {
        title: "ピボットテーブル",
        closePannel: "閉じる",
        editRange: "範囲の編集",
        tipPivotFieldSelected: "データピボットテーブルに追加するフィールドを選択してください",
        tipClearSelectedField: "すべての選択されたフィールドをクリア",
        btnClearSelectedField: "クリア",
        btnFilter: "フィルター",
        titleRow: "行",
        titleColumn: "列",
        titleValue: "値",
        tipShowColumn: "統計フィールドを列として表示",
        tipShowRow: "統計フィールドを行として表示",

        titleSelectionDataRange: "データ範囲の選択",
        titleDataRange: "データ範囲",

        valueSum: "合計",

        valueStatisticsSUM: "合計",
        valueStatisticsCOUNT: "数値カウント",
        valueStatisticsCOUNTA: "カウント",
        valueStatisticsCOUNTUNIQUE: "重複除外カウント",
        valueStatisticsAVERAGE: "平均",
        valueStatisticsMAX: "最大値",
        valueStatisticsMIN: "最小値",
        valueStatisticsMEDIAN: "中央値",
        valueStatisticsPRODUCT: "積",
        valueStatisticsSTDEV: "標準偏差",

        valueStatisticsSTDEVP: "全体の標準偏差",
        valueStatisticslet: "分散",
        valueStatisticsVARP: "全体の分散",

        errorNotAllowEdit: "編集モードでないとこの操作は禁止されています！",
        errorNotAllowMulti: "複数選択範囲に対してこの操作を実行することはできません。単一の範囲を選択してから再試行してください",
        errorSelectRange: "ピボットテーブルの新しい範囲を選択してください",
        errorIsDamage: "このデータピボットテーブルのソースデータは破損しています！",
        errorNotAllowPivotData: "データピボットテーブルをソースデータとして選択することはできません！",
        errorSelectionRange: "選択に失敗しました、入力範囲が間違っています！",
        errorIncreaseRange: "選択したデータ範囲を拡大してください!",

        titleAddColumn: "データピボットテーブルに列を追加",
        titleMoveColumn: "この列を下の白いボックスに移動",
        titleClearColumnFilter: "この列のフィルター条件をクリア",
        titleFilterColumn: "この列をフィルター",
        titleSort: "並び替え",
        titleNoSort: "並び替えなし",
        titleSortAsc: "昇順",
        titleSortDesc: "降順",
        titleSortBy: "並び替えの基準",
        titleShowSum: "合計を表示",
        titleStasticTrue: "はい",
        titleStasticFalse: "いいえ",
    },
    dropCell: {
        copyCell: "セルをコピー",
        sequence: "連続データを入力",
        onlyFormat: "書式のみを入力",
        noFormat: "書式なしを入力",
        day: "日付を入力",
        workDay: "営業日を入力",
        month: "月を入力",
        year: "年を入力",
        chineseNumber: "漢字数字を入力",
    },
    imageCtrl: {
        borderTile: "画像の境界線色を選択",
        borderCur: "現在の色",
    },
    protection: {
        protectiontTitle: "ワークシートを保護",
        enterPassword: "パスワードを入力してください（オプション）",
        enterHint: "変更しようとしているセルやチャートは保護されたワークシートにあります。変更するには、ワークシートの保護を解除してください。パスワードの入力が必要になることがあります",
        swichProtectionTip: "ワークシートとロックされたセルの内容を保護",
        authorityTitle: "このワークシートのユーザーが実行可能:",
        selectLockedCells: "ロックされたセルを選択",
        selectunLockedCells: "ロックされていないセルを選択",
        formatCells: "セルの書式設定",
        formatColumns: "列の書式設定",
        formatRows: "行の書式設定",
        insertColumns: "列の挿入",
        insertRows: "行の挿入",
        insertHyperlinks: "ハイパーリンクの挿入",
        deleteColumns: "列の削除",
        deleteRows: "行の削除",
        sort: "並べ替え",
        filter: "自動フィルターを使用",
        usePivotTablereports: "ピボットテーブルやレポートの使用",
        editObjects: "オブジェクトの編集",
        editScenarios: "シナリオの編集",

        allowRangeTitle: "範囲の編集を許可",
        allowRangeAdd: "新規...",

        allowRangeAddTitle: "タイトル",
        allowRangeAddSqrf: "セルの参照",
        selectCellRange: "セル範囲をクリックで選択",
        selectCellRangeHolder: "セル範囲を入力してください",
        allowRangeAddTitlePassword: "パスワード",
        allowRangeAddTitleHint: "パスワードを設定した後、ユーザーがパスワードを入力するときのヒント（オプション）",
        allowRangeAddTitleHintTitle: "パスワードを入力するためのヒントを設定（オプション）",
        allowRangeAddtitleDefault: "範囲名を入力してください",

        rangeItemDblclick: "ダブルクリックで編集",
        rangeItemHasPassword: "パスワードが設定されています",

        rangeItemErrorTitleNull: "タイトルは空にできません",
        rangeItemErrorRangeNull: "セル範囲は空にできません",
        rangeItemErrorRange: "セル範囲の形式が間違っています",

        validationTitle: "検証のヒント",
        validationTips: "保護されたワークシートの保護を解除するにはパスワードの入力が必要です",
        validationInputHint: "パスワードを入力してください",

        checkPasswordNullalert: "パスワードは空にできません！",
        checkPasswordWrongalert: "パスワードが間違っています。もう一度お試しください！",

        checkPasswordSucceedalert: "ロック解除成功、このエリアを編集できます！",
        defaultRangeHintText: "このセルはパスワードで保護されています。",
        defaultSheetHintText:
            "このセルまたはチャートは保護されたワークシートにあります。変更するには、ワークシートの保護を解除してください。パスワードが必要な場合があります。",
    },
    cellFormat: {
        cellFormatTitle: "セルの形式設定",
        protection: "保護",
        locked: "セルのロック",
        hidden: "公式の非表示",
        protectionTips: "ワークシートの保護機能（メニューバーからワークシートの保護ボタンをクリックして設定）が有効になっている場合にのみ、セルのロックや公式の非表示が有効になります",
        tipsPart: "一部選択",
        tipsAll: "全て選択",
        selectionIsNullAlert: "範囲を選択してください！",
        sheetDataIsNullAlert: "データが空で設定できません！",
    },
    print: {
        normalBtn: "通常ビュー",
        layoutBtn: "ページレイアウト",
        pageBtn: "ページプレビュー",

        menuItemPrint: "印刷（Ctrl+P）",
        menuItemAreas: "印刷範囲",
        menuItemRows: "タイトル行を印刷",
        menuItemColumns: "タイトル列を印刷",
        suggest: "印刷機能についての提案はありますか？",
        range: "印刷範囲",
        size: "用紙サイズ",
        direction: "印刷方向",
        horizontal: "横",
        vertical: "縦",
        title: "印刷設定",
        current: "現在のワークシート",
        area: "選択範囲",
        letter: "封筒",
        paper: "タブロイド",
        law: "リーガル",
        admin: "エグゼクティブ",
        expire: "期限切れ",
        remain: "残り日数",
        error: "エラー",
        showLine: "グリッド線を表示",
        show: "表示",
        hide: "非表示",
        preview: "プレビュー",
    },
    edit: {
        typing: "入力中",
    },
    websocket: {
        success: "WebSocket接続成功",
        refresh: "WebSocket接続にエラーが発生しました。ページを更新してください！",
        wait: "WebSocket接続にエラーが発生しました。しばらくお待ちください！",
        close: "WebSocket接続が閉じました",
        contact: "サーバーとの通信にエラーが発生しました。ページを更新して再試行してください。それでも解決しない場合は、管理者に連絡してください！",
        support: "現在のブラウザはWebSocketをサポートしていません",
    },
    exportXlsx: {
        notice: "エクスポートプラグインを設定してください",
        serverError: "サーバーがメンテナンス中です",
        title: "XLSX形式でエクスポート",
        range: "範囲",
        currentSheet: "現在のワークシート",
        allSheets: "全てのワークシート",
    },
    dateformat: {
        weekdays: [
            ['日', '日曜日'],
            ['月', '月曜日'],
            ['火', '火曜日'],
            ['水', '水曜日'],
            ['木', '木曜日'],
            ['金', '金曜日'],
            ['土', '土曜日']
        ]
    },
    currencyFormat: {
        format: "¥#,##0"
    },
};
